/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
import { Handle, Position } from 'react-flow-renderer';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
import { useAuth } from '../../../auth';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, IconButton, Chip, Checkbox } from '@mui/material';
import { LockPerson as LockedIcon, LockOpen as UnlockedIcon, Input as AddChildIcon, SystemUpdateAlt as AddSiblingIcon, Edit as EditIcon, Delete as DeleteIcon, DragIndicator as MoveIcon, Close as CancelIcon, MoveDown as MoveToSiblingIcon, ExitToApp as MoveToChildIcon, PostAdd as AddQuestionIcon, ChevronRight, ChevronLeft } from '@mui/icons-material';
import { NodeTreeContext } from './treeHelpers';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
function Node(node) {
	const { isAdmin } = useAuth();
	const { multi, initialNodeId, singleChecked, checkedNodes, onCheckNode, onLockUnlockNode, onAddChildNode, onAddSiblingNode, onEditNode, onDeleteNode, moveNode, onStartMoveNode, onCancelMoveNode, onMoveNodeIntoSiblingPosition, onMoveNodeIntoChildPosition, onToggleNode, onAddContentToNode } = React.useContext(NodeTreeContext);

	const hasChildren = () => {
		if (!isEmpty(node.children) || !isEmpty(node.data.children)) {
			return true;
		}
		return false;
	};

	return (
		<div
			className={clsx('custom-node', {
				disabledNode: node.data.disabled,
				moveNode: node.id === moveNode.id,
				checkedNode: (initialNodeId === node.data.id && multi === false) || singleChecked.id === node.data.id || checkedNodes.findIndex((checked) => checked.id === node.data.id) >= 0,
			})}
		>
			<Handle type="target" position={Position.Left} style={{ opacity: node.data.depth > 0 ? 1 : 0 }} />
			<Box className="flex-wrapper">
				<Box className="node-title">
					{multi && (
						<Checkbox
							sx={{
								color: 'rgba(255,255,255,.5)',
								padding: '0px',
								'&.Mui-checked': { color: '#FFF' },
								'& .MuiSvgIcon-root': {
									width: '16px',
									height: '16px',
								},
							}}
							checked={checkedNodes.findIndex((checked) => checked.id === node.data.id) >= 0}
							onChange={(event) => {
								onCheckNode(node);
							}}
							onClick={(e) => e.stopPropagation()}
						/>
					)}
					<Box className="title-text">{node.data.label}</Box>
					{node.data.module === 'questions' && (
						<Chip
							label={`${node.data.questions_count} ${node.data.questions_count === 1 ? 'Question' : 'Questions'}`}
							sx={{
								backgroundColor: 'rgba(0,0,0,.3)',
								margin: '0px',
								marginRight: '5px',
								height: '18px',
								padding: '0px',
								fontSize: '8px',
								cursor: 'pointer',
								'& .MuiChip-label': { px: '8px' },
							}}
						/>
					)}
					{isAdmin && (
						<Box className="options">
							{isEmpty(moveNode) && (
								<React.Fragment>
									{node.data.parent_id !== '0' && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onLockUnlockNode(node.data);
											}}
										>
											{node.data.disabled === true ? <LockedIcon /> : <UnlockedIcon />}
										</IconButton>
									)}
									{node.data.parent_id !== '0' && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onAddContentToNode(node.data);
											}}
										>
											<AddQuestionIcon />
										</IconButton>
									)}
									{node.data.parent_id !== '0' && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onAddSiblingNode(node.data);
											}}
										>
											<AddSiblingIcon />
										</IconButton>
									)}
									{hasChildren() === false && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onAddChildNode(node.data);
											}}
										>
											<AddChildIcon />
										</IconButton>
									)}
									{node.data.parent_id !== '0' && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onEditNode(node.data);
											}}
										>
											<EditIcon />
										</IconButton>
									)}
									{hasChildren() === false && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onDeleteNode(node.data);
											}}
										>
											<DeleteIcon />
										</IconButton>
									)}
									{node.data.parent_id !== '0' && (
										<IconButton
											className="option"
											size="small"
											onClick={(e) => {
												e.stopPropagation();
												onStartMoveNode(node.data);
											}}
										>
											<MoveIcon />
										</IconButton>
									)}
								</React.Fragment>
							)}
							{!isEmpty(moveNode) && moveNode.id === node.data.id && (
								<IconButton
									className="option"
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										onCancelMoveNode();
									}}
								>
									<CancelIcon />
								</IconButton>
							)}
							{!isEmpty(moveNode) && moveNode.id !== node.data.id && node.data.parent_id !== '0' && (
								<IconButton
									className="option"
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										onMoveNodeIntoSiblingPosition(moveNode, node.data);
									}}
								>
									<MoveToSiblingIcon />
								</IconButton>
							)}
							{!isEmpty(moveNode) && moveNode.id !== node.data.id && hasChildren() === false && (
								<IconButton
									className="option"
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										onMoveNodeIntoChildPosition(moveNode, node.data);
									}}
								>
									<MoveToChildIcon />
								</IconButton>
							)}
						</Box>
					)}
					{!isEmpty(node.data.children) && (
						<IconButton
							className="expand-btn"
							onClick={(event) => {
								event.stopPropagation();
								onToggleNode(event, node.data);
							}}
						>
							{node.data.expanded === true && <ChevronLeft className="expand-icon" />}
							{node.data.expanded === false && <ChevronRight className="expand-icon" />}
						</IconButton>
					)}
				</Box>
			</Box>
			<Handle type="source" position={Position.Right} id="a" style={{ opacity: !isEmpty(node.data.children) ? 1 : 0 }} />
		</div>
	);
}

export default Node;
