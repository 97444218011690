/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { ErrorAlert } from '../@core/Alert';
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default class ErrorProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorAlert title='Caught an Error at the Error Boundary' message={this.state.errorInfo} />
        }

        return this.props.children;
    }
}