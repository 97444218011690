/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
/****************************************************************************************************
 * IMAGES
 ****************************************************************************************************/
import LogoIconWhite from './logo-white.svg';
import LogoIconColor from './logo-color.svg';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
const Sx = {
	logoContainer: (theme, size, align) => ({
		textAlign: align,
		'& .logo-icon': { width: size === 'large' ? { xs: '50px', sm: '60px', lg: '70px' } : { xs: '50px' }, margin: 'auto', cursor: 'pointer' },
		'& .logo-text': {
			fontFamily: "'Gochi Hand', sans-serif",
			fontWeight: 600,
			margin: '5px 0',
			'& .cursor-pointer': { cursor: 'pointer' },
		},
	}),
};
/****************************************************************************************************
 * PROPS
 ****************************************************************************************************/
LogoIconTextTitle.propTypes = {
	white: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'large']),
	align: PropTypes.string,
};
LogoIconTextTitle.defaultProps = {
	white: false,
	size: 'large',
	align: 'center',
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function LogoIconTextTitle({ white, size, align, subText }) {
	const history = useHistory();
	return (
		<Box sx={(theme, size) => Sx.logoContainer(theme, size, align)}>
			<img className="logo-icon" alt="" src={white ? LogoIconWhite : LogoIconColor} onClick={() => history.push('/')} />
			<Typography className="logo-text" sx={{ color: white ? '#FFF' : 'primary.main' }} variant={size === 'large' ? 'h2' : 'h4'}>
				<span className="cursor-pointer" onClick={() => history.push('/')}>
					Learnblock
				</span>
				{subText && <span style={{ marginLeft: '10px' }} className="cursor-pointer" onClick={() => history.push('/')}>{`> ${subText}`}</span>}
			</Typography>
		</Box>
	);
}
