/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, TextField, Autocomplete } from '@mui/material';
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * DATA
 ****************************************************************************************************/
const states = {
	AU: [
		{ label: 'New South Wales', value: 'NSW' },
		{ label: 'Victoria', value: 'VIC' },
		{ label: 'Queensland', value: 'QLD' },
		{ label: 'Australian Capital Territory', value: 'ACT' },
		{ label: 'South Australia', value: 'SA' },
		{ label: 'Western Australia', value: 'WA' },
		{ label: 'Northern Territory', value: 'NT' },
		{ label: 'Tasmania', value: 'TAS' },
	],
};
/****************************************************************************************************
 * MODULE
 * NOTE: state is an acronym
 ****************************************************************************************************/
export default function SelectState({ country, state, onChange }) {
	const [selectedState, setSelectedState] = React.useState(null);

	React.useEffect(() => {
		findInitialState(country, state);
	}, []);

	React.useEffect(() => {
		if (!isEmpty(selectedState) && onChange) {
			onChange(selectedState.value);
		}
	}, [selectedState]);

	const findInitialState = (country, stateCode) => {
		if (!isEmpty(country) && country in states && !isEmpty(stateCode)) {
			const findState = states[country].find((state) => state.value === stateCode);
			setSelectedState(findState);
		}
	};

	if (isEmpty(country) || !(country in states)) return null;

	return (
		<Autocomplete
			id="state-selector"
			fullWidth
			value={selectedState}
			isOptionEqualToValue={(option, value) => option.label === value.label}
			options={country in states ? states[country] : []}
			autoHighlight
			getOptionLabel={(option) => option.label}
			onChange={(event, newValue) => setSelectedState(newValue)}
			renderOption={(props, option) => (
				<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
					{option.label}
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					error={isEmpty(selectedState)}
					helperText={isEmpty(selectedState) && 'The state cannot be empty.'}
					label="Choose a state"
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password', // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
