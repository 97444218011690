import React from 'react';

function useKey(key, cb) {
    const callback = React.useRef(cb);

    React.useEffect(() => {
        callback.current = cb;
    });

    React.useEffect(() => {
        function handle(event) {
            if (event.code === key) {
                callback.current(event);
            } else if (key === 'ctrls' && event.key === 's' && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                callback.current(event);
            } else if (key === 'ctrle' && event.key === 'e' && (event.ctrlKey || event.metaKey)) {
                event.preventDefault();
                callback.current(event);
            }
        }
        document.addEventListener('keydown', handle);
        return () => document.removeEventListener("keydown", handle)
    }, [key]);
}

export default useKey;