import React from 'react';
import { styled } from '@mui/material/styles';

const SlidingSquaresWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'linearGradient' })(({ theme, height, linearGradient }) => ({
	height: '100vh',
	minHeight: '100vh',
	position: 'fixed',
	width: '100%',
	zIndex: -1,
	top: 0,
	left: 0,
	padding: 0,
	overflow: 'hidden',
	backgroundColor: theme.palette.primary.main,
	'& .squares': {
		animation: `slidingSquare 1s infinite`,
		background: linearGradient ? linearGradient : `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
		position: 'absolute',
		transition: '.5s ease-out',
		overflow: 'hidden',
		borderRadius: '20%',
		'&.square1': { animation: `slidingSquare 4s infinite`, height: '300px', width: '300px', opacity: '.5', left: '3%', top: '-21%' },
		'&.square2': { animation: `slidingSquare 6s infinite`, height: '400px', width: '400px', opacity: '.4', right: '-5%', top: '-12%' },
		'&.square3': { animation: `slidingSquare 5s infinite`, height: '200px', width: '200px', opacity: '.1', left: '-5%', bottom: '0' },
		'&.square4': { animation: `slidingSquare 10s infinite`, height: '100px', width: '100px', opacity: '.4', right: '27%', top: '70%' },
		'&.square5': { animation: `slidingSquare 6s infinite`, height: '250px', width: '250px', opacity: '.1', left: '32%', bottom: '29%' },
		'&.square6': { animation: `slidingSquare 9s infinite`, height: '80px', width: '80px', opacity: '.5', left: '10%', top: '35%' },
		'&.square7': { animation: `slidingSquare 3s infinite`, height: '300px', width: '300px', opacity: '.1', right: '-5%', bottom: '0' },
	},
	'@keyframes slidingSquare': {
		'0%': { transform: 'translateX(-10px)' },
		'50%': { transform: 'translateX(10px)' },
		'100%': { transform: 'translateX(-10px)' },
	},
}));

const AnimationSlidingSquares = ({ linearGradient, ...props }) => {
	return (
		<SlidingSquaresWrapper linearGradient={linearGradient} {...props}>
			<div className={'squares square1'}></div>
			<div className={'squares square2'}></div>
			<div className={'squares square3'}></div>
			<div className={'squares square4'}></div>
			<div className={'squares square5'}></div>
			<div className={'squares square6'}></div>
			<div className={'squares square7'}></div>
		</SlidingSquaresWrapper>
	);
};

export default AnimationSlidingSquares;
