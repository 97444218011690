import { supabase } from '../supabaseClient';
import { isEmpty } from '../utils';

const QuestionData = {
	getQuestions: async (nodeIds, blockId) => {
		try {
			const { data, error } = await supabase.rpc('get_questions_by_node_ids', { p_node_ids: nodeIds, p_block_id: blockId });
			if (error) throw error;
			return {
				success: true,
				data: data.map((el) => ({
					...el.question_data,
					question_options: el.question_options.length > 1 ? el.question_options.sort((a, b) => a.order - b.order) : el.question_options,
					question_performance: !isEmpty(el.question_performance) ? el.question_performance : {},
					question_performance_steps: !isEmpty(el.question_performance_steps) ? el.question_performance_steps : [],
				})),
			};
		} catch (err) {
			return {
				success: false,
				code: 'Get Questions',
				message: err.message,
			};
		}
	},
	getQuestionToTag: async (blockId) => {
		try {
			const { data, error } = await supabase.rpc('get_untagged_question', { p_block_id: blockId }).single();
			if (error) throw error;
			const untaggedQuestion = { ...data.question_data, question_options: data.question_options };
			return {
				success: true,
				data: untaggedQuestion,
			};
		} catch (err) {
			return {
				success: false,
				code: 'Get Untagged Question',
				message: err.message,
			};
		}
	},
	addQuestion: async (formData) => {
		try {
			const { question_options, question_performance, question_performance_steps, ...rest } = formData;
			const { data, error } = await supabase.from('questions').insert(rest).select();
			if (error) throw error;
			const question = data[0];
			if (question.question_type === 'mc' || question.question_type === 'parts') {
				// Add the multiple choice options
				const question_id = question.id;
				const options = question_options.map((el, elIndex) => ({ ...el, question_id, order: elIndex + 1 }));
				const { data: q_options, error: q_error } = await supabase.from('question_options').insert(options).select();
				if (q_error) throw q_error;
				question.question_options = q_options;
				return { success: true, data: question };
			}
			return { success: true, data: question };
		} catch (err) {
			return {
				success: false,
				code: 'Add Question',
				message: err.message,
			};
		}
	},
	editQuestion: async (formData) => {
		try {
			const { id, question_options, question_performance, question_performance_steps, ...rest } = formData;
			const { data, error } = await supabase.from('questions').update(rest).eq('id', id).select();
			if (error) throw error;
			if (isEmpty(data)) throw new Error('The data was not updated');
			const question = data[0];
			if (question.question_type === 'mc' || question.question_type === 'parts') {
				const question_id = question.id;
				const options = question_options.map((el, elIndex) => ({ ...el, question_id, order: elIndex + 1 }));
				const { data: q_options, error: q_error } = await supabase.from('question_options').upsert(options).select();
				if (q_error) throw q_error;
				question.question_options = q_options;
				return { success: true, data: question };
			}
			return { success: true, data: question };
		} catch (err) {
			return {
				success: false,
				code: 'Edit Question',
				message: err.message,
			};
		}
	},
	deleteQuestion: async (questionId) => {
		try {
			const { error } = await supabase.from('questions').delete().eq('id', questionId);
			if (error) throw error;
			return { success: true, data: questionId };
		} catch (err) {
			return {
				success: false,
				code: 'Delete Question',
				message: err.message,
			};
		}
	},
	saveQuestionPerformance: async (updateMethod, questionPerformance) => {
		try {
			if (updateMethod === 'insert') {
				const { data, error } = await supabase.from('question_answers').insert(questionPerformance).select();
				if (error) throw error;
				return { success: true, data: data[0] };
			} else {
				// The question has been attempted, so you just need to update it
				const { id, ...rest } = questionPerformance;
				const { data, error } = await supabase.from('question_answers').update(rest).eq('id', id).select();
				if (error) throw error;
				return { success: true, data: data[0] };
			}
		} catch (err) {
			return {
				success: false,
				code: 'Save Question Performance',
				message: err.message,
			};
		}
	},
	addQuestionPerformanceStep: async (questionStep) => {
		try {
			const { data, error } = await supabase.from('question_answers_steps').insert(questionStep).select().single();
			if (error) throw error;
			return { success: true, data };
		} catch (err) {
			return {
				success: false,
				code: 'Add Question Performance Step',
				message: err.message,
			};
		}
	},
	deleteQuestionPerformanceStep: async (uniqueStepId) => {
		try {
			const { data, error } = await supabase.from('question_answers_steps').delete().eq('id', uniqueStepId);
			if (error) throw error;
			return { success: true, data };
		} catch (err) {
			return {
				success: false,
				code: 'Delete Question Performance Step',
				message: err.message,
			};
		}
	},
};

export default QuestionData;
