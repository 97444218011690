/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
import { InitialData, QuestionData } from '../../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Button } from '@mui/material';
import { RestartAlt as ResetIcon } from '@mui/icons-material';
import { ApiErrorMessage, LoadingPageMessage, NoDataMessage } from '../../Messages';
import { QuestionsList, QuestionElement } from '../../Questions';
import { LogoIconTextTitle } from '../../../@core/Logo';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function BrowseQuestions({ block, nodes, returnPath, onDeleteQuestion, handleClose }) {
	const [questions, setQuestions] = React.useState({ ...InitialData.loading, activeQuestionIndex: 0, totalCount: 0 });
	const [filters, setFilters] = React.useState([]);

	React.useEffect(() => {
		getQuestions(nodes);
	}, []);

	React.useEffect(() => {
		const filteredQuestions = questions.data.map(applyFilter);
		const findFirstIndex = filteredQuestions.findIndex((el) => el.hidden === false);
		setQuestions((prev) => ({
			...prev,
			data: filteredQuestions,
			activeQuestionIndex: findFirstIndex,
		}));
	}, [filters]);

	const getQuestions = async (nodes) => {
		setQuestions(InitialData.loading);
		const totalCount = nodes.reduce((acc, curr) => (acc += curr.original_count), 0);
		const res = await QuestionData.getQuestions(
			nodes.map((node) => node.id),
			block.id
		);
		if (!res.success)
			return setQuestions({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		return setQuestions({ ...InitialData.completed, data: res.data, activeQuestionIndex: 0, totalCount });
	};

	const handleEditQuestion = (newQuestion) => {
		setQuestions((prev) => ({
			...prev,
			data: prev.data.map((el) => {
				if (el.id === newQuestion.id) {
					return newQuestion;
				} else {
					return el;
				}
			}),
		}));
	};

	const handleDeleteQuestion = (questionId, nodeId) => {
		setQuestions((prev) => ({
			...prev,
			data: prev.data.filter((el) => el.id !== questionId),
			activeQuestionIndex: prev.activeQuestionIndex === 0 ? 0 : prev.activeQuestionIndex - 1,
		}));
		if (onDeleteQuestion) onDeleteQuestion(nodeId);
	};

	const handlePrevQuestion = () => {
		if (questions.activeQuestionIndex === 0) return; // first question
		setQuestions((prev) => ({ ...prev, activeQuestionIndex: prev.activeQuestionIndex - 1 }));
	};

	const handleNextQuestion = () => {
		if (questions.activeQuestionIndex === questions.length - 1) return; // last question
		setQuestions((prev) => ({ ...prev, activeQuestionIndex: prev.activeQuestionIndex + 1 }));
	};

	const handleChangeFilter = (filterName) => {
		setFilters((prev) => (prev.includes(filterName) ? prev.filter((el) => el !== filterName) : [...prev, filterName]));
	};

	const applyFilter = (question) => {
		if (isEmpty(filters)) return { ...question, hidden: false };
		else if (filters.includes('easy') && (question.difficulty === 1 || question.difficulty === 2)) return { ...question, hidden: false };
		if (filters.includes('medium') && question.difficulty === 3) return { ...question, hidden: false };
		if (filters.includes('hard') && (question.difficulty === 4 || question.difficulty === 5)) return { ...question, hidden: false };
		if (filters.includes('unlikely') && question.likelihood === 1) return { ...question, hidden: false };
		if (filters.includes('fairly-likely') && question.likelihood === 2) return { ...question, hidden: false };
		if (filters.includes('very-likely') && question.likelihood === 3) return { ...question, hidden: false };
		if (filters.includes('correct') && !isEmpty(question.question_performance) && question.question_performance.correct === true) return { ...question, hidden: false };
		if (filters.includes('incorrect') && !isEmpty(question.question_performance) && question.question_performance.correct === false) return { ...question, hidden: false };
		if (filters.includes('flagged') && !isEmpty(question.question_performance) && question.question_performance.flagged === true) return { ...question, hidden: false };
		return { ...question, hidden: true };
	};

	return (
		<React.Fragment>
			{questions.loading && <LoadingPageMessage fullPage />}
			{!questions.loading && questions.error && <ApiErrorMessage sx={{ margin: '20px auto' }} errorCode={questions.errorCode} errorMessage={questions.errorMessage} />}
			{!questions.loading && !questions.error && isEmpty(questions.data) && <NoDataMessage customMessage={'Sorry there are no questions available for your selection.'} />}
			{!questions.loading && !questions.error && !isEmpty(questions.data) && (
				<React.Fragment>
					{questions.activeQuestionIndex < 0 && (
						<Box sx={{ flexGrow: 1, textAlign: 'center', marginTop: '100px' }}>
							<LogoIconTextTitle size={'small'} />
							<NoDataMessage sx={{ padding: '20px' }} customMessage={`Sorry, there are no questions available for this selection in ${block.name}`} />
							<Button startIcon={<ResetIcon />} color="primary" variant="contained" onClick={() => setFilters([])}>
								Reset Filters
							</Button>
						</Box>
					)}
					{questions.activeQuestionIndex >= 0 && <QuestionElement handleClose={handleClose} returnPath={returnPath} block={block} questionData={questions.data[questions.activeQuestionIndex]} onEditQuestion={handleEditQuestion} onDeleteQuestion={handleDeleteQuestion} handlePrevQuestion={handlePrevQuestion} handleNextQuestion={handleNextQuestion} displayUserControls={true} />}
					<QuestionsList blockId={block.id} totalQuestionCount={questions.totalCount} filters={filters} handleChangeFilter={handleChangeFilter} questions={questions.data} activeQuestionIndex={questions.activeQuestionIndex} setActiveQuestionIndex={(activeQuestionIndex) => setQuestions((prev) => ({ ...prev, activeQuestionIndex }))} handlePrevQuestion={handlePrevQuestion} handleNextQuestion={handleNextQuestion} />
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
