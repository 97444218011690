const styles = {
	topicsList: {
		overflow: 'auto',
		padding: '40px',
		position: 'relative',
		'& .generate-questions-btn': {
			position: 'fixed',
			zIndex: 999,
			marginBottom: 0,
			bottom: 20,
			right: 10,
		},
		'& .ReactVirtualized__Grid__innerScrollContainer': { marginTop: '0px', marginLeft: '10px' },
		'& .ReactVirtualized__Grid::-webkit-scrollbar': { background: 'none', width: '8px', height: '9px' },
		'& .ReactVirtualized__Grid::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main' },
		'& .rst__moveHandle': { borderRadius: '50%', width: '50px', height: '50px', backgroundColor: 'primary.main', border: 'none', position: 'relative', marginRight: '12px' },
		'& .rst__moveHandle::after': { position: 'absolute', content: "''", backgroundColor: 'primary.main', height: '4px', width: '12px', top: '50%', right: '-12px' },
		'& .rst__row': { cursor: 'pointer' },
		'& .rst__row .rst__rowContents': {
			borderRadius: '20px',
			paddingLeft: '0px',
			background: 'none',
			boxShadow: 'none',
			border: 'none',
			'& .node-title': {
				fontFamily: '"Poppins", sans-serif',
				color: 'primary.main',
				borderRadius: '40px',
				height: '56px',
				padding: '7px 20px',
				fontSize: '16px',
				letterSpacing: '1px',
				textTransform: 'uppercase',
				'& .text': { marginRight: '10px' },
				'& .MuiCheckbox-root, & .MuiIconButton-root': { color: 'inherit' },
				'& .control-btn': {
					margin: '5px',
					'&.view-content-btn': {
						'&.Mui-disabled': { opacity: '0.26' },
					},
				},
				'&:hover': {
					backgroundColor: 'primary.main',
					color: '#FFF',
				},
			},
		},
		'& .rst__row.notAdmin .rst__rowContents': {
			'& .node-title': { padding: '10px 15px' },
		},
		'& .rst__row.selected .rst__rowContents': {
			'& .node-title': {
				backgroundColor: 'primary.main',
				color: '#FFF',
				'& .empty-progress-circle': { color: '#FFF' },
				'& .progress-text': { color: '#FFF' },
				'& .view-content-btn': { color: '#FFF' },
			},
		},
		'& .rst__collapseButton, & .rst__expandButton': { width: '20px', height: '20px' },
		'& .rst__node:only-child .rst__lineHalfHorizontalRight::before': { background: 'none' },
		'& .rst__lineHalfHorizontalRight::before': { backgroundColor: 'primary.main', height: '4px' },
		'& .rst__lineFullVertical::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineHalfVerticalTop::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineHalfVerticalBottom::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineChildren::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__rowSearchFocus': { outline: 'none !important', backgroundColor: 'primary.main', borderRadius: 20 },
		'&.compact-tree': {
			height: 'calc(100vh - 101px)',
			'& .rst__moveHandle, .rst__loadingHandle': { width: 32, margin: 0, borderRadius: 0, boxShadow: 'none', border: 'none' },
			'& .rst__row .rst__rowContents::before': { position: 'absolute', content: "''", backgroundColor: 'primary.main', height: 1, left: 0, width: '12px', top: '50%' },
			'& .rst__rowWrapper': { padding: '4px 0' },
			'& .rst__row.active .rst__rowContents, & .rst__row .rst__rowContents:hover': { paddingLeft: 10, borderRadius: 4 },
			'& .rst__row.linked .rst__rowContents': { paddingLeft: 10, borderRadius: 4, background: 'orange' },
			'& .rst__row .rst__rowContents': {
				borderRadius: 0,
				paddingLeft: 10,
				boxShadow: 'none',
				border: 'none',
				background: 'none',
				color: 'primary.main',
				'& .MuiCheckbox-root': { color: 'rgba(255,255,255,.54)', padding: 0, paddingRight: 9 },
				'&:hover': { background: 'rgba(255,255,255,.3)' },
			},
			'& .rst__toolbarButton .MuiSvgIcon-root': { fontSize: 16, color: 'rgba(255,255,255,.7)' },
		},
		'&.map-layout': {
			width: '100%',
			'& .rd3t-link': { stroke: '#FFF', strokeWidth: 2 },
			'& .map-node-box': {
				background: '#FFF',
				border: `1px solid #FFF`,
				borderRadius: 20,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				padding: '10px 10px 15px',
				margin: 10,
				'& .title': { textAlign: 'center', margin: 5 },
				'& .expand-collapse-btn': { margin: 10 },
				'&.active': { backgroundColor: 'primary.main', color: '#FFF', borderColor: 'primary.main' },
			},
		},
	},
};

export default styles;
