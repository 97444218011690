/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box, Paper, Typography } from '@mui/material';
import styles from './styles';
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function Terms() {
    return (
        <Box>
            <Box sx={styles.helpBanner}>
                <Typography className='heading-font primary main-title' align="center" variant="h3">Terms {`&`} Conditions</Typography>
                <Typography className='heading-font sub-heading' align="center" variant="h5">Last Updated: 22nd August 2022</Typography>
                <Typography className='blurb-text main-blurb' align="center" variant="h6">
                    This page contains our full user agreement. You may wish to print this page for reference.
                    Please read the following terms and conditions carefully as they contain important information
                    regarding your legal rights, remedies and obligations and include various limitations and exclusions.
                </Typography>
            </Box>
            <Box sx={styles.helpContent}>
                <Typography className="emphasise primary heading-font" variant="h5">Agreement</Typography>
                <Paper className="section">
                    <p>Welcome to www.learnblock.com and its applications ({String.fromCharCode(34)}the Site{String.fromCharCode(34)}).</p>
                    <p>These terms and conditions form an agreement between LEARNBLOCK{String.fromCharCode(8482)} and you ({String.fromCharCode(34)}the Agreement{String.fromCharCode(34)}) regarding your use and access to the Site. Your use of the Site constitutes your agreement to the terms and conditions. If you do not accept the terms and conditions of the please do not use the Site.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Who you are</Typography>
                <Paper className="section">
                    <p>You are the person, association, collective, department, educational institution or entity including but not limited to a corporate entity (or agent thereof) that has entered the Site by a particular computer system.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Who owns this site?</Typography>
                <Paper className="section">
                    <p>The Site is owned and operated by LEARNBLOCK{String.fromCharCode(8482)}. You acknowledge that LEARNBLOCK{String.fromCharCode(8482)} is the owner of the Site.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">How should I act on the information provided at this site?</Typography>
                <Paper className="section">
                    <p>The purpose of the Site is to provide you with a platform for communication and information. If you think you may other services or some advice regarding education options, we suggest you approach a third party for advice for example a university, your teacher or State of Federal education department. Nothing in the Site should be taken by you as educational advice or other advice.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Reliance</Typography>
                <Paper className="section">
                    <p>The Site is an independent and totally impartial service that aims to bring closer to other students, educational providers and like minded people and companies.</p>
                    <p>You acknowledge that LEARNBLOCK{String.fromCharCode(8482)} operates the Site to provide information and a platform for interaction and knowledge sharing. You also acknowledge that LEARNBLOCK{String.fromCharCode(8482)} is a prescribed information provider within the meaning of section 19 of the Australian Consumer Law.</p>
                    <p>The information on or in the Site is provided as general indicative information only and although we use all reasonable endeavours to ensure that the data on the Site is accurate you must not rely on the information as if it is current as educational institutions and societies change rules, regulation, content, subjects, courses and social calendars all the time and without reference to us. The public information on or in the Site is meant as an aid only and we are not supplied information by third parties. The Site is not a substitute for your own personal research. We have no opportunity to research the accuracy of the information on the Site as they are most often sourced public domain sources or uploaded by your fellow users.  Please use caution in relying on information on or in the Site. You must assess the information provided based on your own experience, acumen and needs.  Any eventual contract for information will be between you and a third party and will be subject to the terms and conditions agreed between the parties.</p>
                    <p>The information contained within the Site is continually updated and is subject to change from time to time, at the discretion of LEARNBLOCK{String.fromCharCode(8482)}. Any prices or fees quoted may or may not include GST and are based on current information available as the relevant date. All prices or fees given are estimates only and you are advised to independently check and verify the figures quoted on the Site from alternative sources. LEARNBLOCK{String.fromCharCode(8482)} accepts no responsibility or liability for information, prices, fees or statements quoted or made on the Site. We merely provide a facility enabling you to meet like minded individuals and share information.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} has no influence on education policy or educational institutions. No advice given by LEARNBLOCK{String.fromCharCode(8482)} creates any warranty and LEARNBLOCK{String.fromCharCode(8482)} does not make any warranty or representation about the fitness or suitability of any information, product or service referred to on the Site. Nothing on the Site shall be deemed to constitute advice to induce you to purchase any products or services. The Site is an information guide and/or source only. If any information on the Site is unclear or you are unsure LEARNBLOCK{String.fromCharCode(8482)} recommends that you seek professional or independent advice, including contacting any educational institution, university, teacher and/or State and/or Federal education department.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} does not guarantee that the Site is free from errors or faults. We will however use our best efforts to ensure that the information available the Site is accurate and current. Further, LEARNBLOCK{String.fromCharCode(8482)} does not accept liability for any errors or omission, and reserves the right to change information published on the Site at any time. You should not rely on information on the Site and you must apply your own experience and acumen before making a decision.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} does not accept liability for any indirect or consequential loss arising out of the use of, or connected with this Site, or for any services selected through the Site. For example, we cannot guarantee that a university will be able to provide a particular subject at any particular time or fee structure or that a subject will be available from semester to semester.</p>
                    <p>In the event that any aspect of the Agreement is found to be invalid or unenforceable for any reason, it shall not affect the validity or enforceability of any other part of the Agreement, which shall remain in full force and effect and shall be construed as if the invalid or unenforceable aspect was not part of the Agreement. The failure of LEARNBLOCK{String.fromCharCode(8482)} to exercise or enforce any of its rights hereunder will not constitute a waiver of its rights. If any court finds that a term of these provisions is unenforceable or invalid then you will join with LEARNBLOCK{String.fromCharCode(8482)} in giving effect to the substance of the Agreement.</p>
                    <p>You may download material from the Site for your own use only. No right, title or interest in any downloaded materials is transferred to you by such downloading. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of the Site is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} has no liability in respect of any other website or page on the web that is accessible from the Site.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Your rights</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} grants you a revocable, non-exclusive, non-transferable, limited right to access, use and display the Site, provided that you comply fully with the terms and conditions of this Agreement ({String.fromCharCode(34)}the Licence{String.fromCharCode(34)}). At no time do you acquire any proprietary interest in the Site or any one or more of its components, integers and/or compilations. The Licence is revocable at any time without notice and without cause.</p>
                    <p>By entering the Site, you are contractually agreeing to accept these terms and conditions whether or not you complete or fill out any form, invitation or application. If you do not accept these terms and conditions in any respect then you should withdraw from the Site immediately and not accept or rely upon any of the services, statements, facts, information or data provided.</p>
                    <p>All content on the Site including third party uploaded files but not limited to designs, text, graphics, pictures, video, information, applications, software, music, sound and other files, and their selection and arrangement, are the proprietary property of LEARNBLOCK{String.fromCharCode(8482)}, and/or its licensors with all rights reserved.</p>
                    <p>Except for your own content, you may not upload or republish the Site on any Internet, web, intranet or extranet site or incorporate the information in any other database or compilation. Any other use of the Site is strictly prohibited.</p>
                    <p>FOR AVOIDANCE OF DOUBT, any use of the Site or its content, other than as specifically authorised in the Agreement, is strictly prohibited and will terminate the Licence.</p>
                    <p>Unless explicitly stated herein, nothing in the Agreement shall be construed as conferring any licence to any of LEARNBLOCK{String.fromCharCode(39)}s intellectual property rights, whether by estoppel, implication or otherwise.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Privacy</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} values your privacy and has a policy to maintain your privacy when you visit our site. Please read our Privacy Policy for more information.</p>
                    <p><b>Your actions</b></p>
                    <p>When using the Site you agree that you will not take any of the following actions:</p>
                    <ul>
                        <li>use any device, software or routine that interferes with or attempts to interfere with the  proper functionality of the Site</li>
                        <li>change, suspend, or discontinue all or any part of the Site</li>
                        <li>reject, move, or remove any content available on the Site</li>
                        <li>violate and/or infringe the intellectual property rights of LEARNBLOCK{String.fromCharCode(8482)} or a third party</li>
                        <li>decompile, reverse engineer, scrape or disassemble the Site</li>
                        <li>affect the way the Site displays its pages, such as by framing the Site or placing pop-up windows over its page</li>
                        <li>remove or obscure any intellectual property notice or any other notices appearing on the Site or anything retrieved or downloaded from the Site</li>
                        <li>use any automated means to access the Site</li>
                        <li>customise the Site in a way that adversely affects the display of any content on the Site</li>
                    </ul>
                    <p>By posting any content on the Site You agree that:</p>
                    <ul>
                        <li>We can contact you via email and other commercial electronic message programs in relation to information that we consider is relevant and of interest to you. You have the option to opt out at any time by contacting us at support@learnblock.com</li>
                        <li>You grant LEARNBLOCK{String.fromCharCode(8482)} an exclusive, non-revocable, royalty free, perpetual and worldwide licence to reproduce and use any content you submit for all purposes, by all means and in all media.  You waive and consent not to exercise your moral rights in any content regardless of the use made of the content by LEARNBLOCK{String.fromCharCode(8482)}. You also warrant that the owner of any intellectual property rights in any content, including any moral rights in any content, has been completely and effectively waived or consent given to use without reference to the owner of any content.</li>
                    </ul>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Entire Agreement</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} may at any time vary the terms and conditions by publishing any varied terms and conditions on the Site. You accept that by making this written statement, LEARNBLOCK{String.fromCharCode(8482)} has provided you with sufficient notice of any variation.</p>
                    <p>The only time that the terms and conditions of the Agreement will be amended is if LEARNBLOCK{String.fromCharCode(8482)} modifies or amends this document. These terms and conditions and any doubt or dispute as to their interpretation will be decided under the laws of New South Wales and you and LEARNBLOCK{String.fromCharCode(8482)} submit to the non-exclusive jurisdiction of the courts of New South Wales and any appellant jurisdictions thereof including but not limited to federal jurisdictions.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} may modify the terms and conditions of the Agreement at any time without providing further notice to you. Your continued use of the Site after such a modification constitutes your acceptance of any modification(s). For this reason we provide a link to the terms and conditions at the bottom of every page of the Site. You agree to check the terms and conditions of the Agreement each time you visit and that you are bound by terms and conditions of the Agreement if you use the Site and/or continue to use the Site after any amendments are made by LEARNBLOCK{String.fromCharCode(8482)}. LEARNBLOCK{String.fromCharCode(8482)} has the right to correct any errors or omissions, and change any portion of the Site without notice. If you find an error, please email us at support@learnblock.com. LEARNBLOCK{String.fromCharCode(8482)} regularly updates the Site and these terms and conditions and we recommend that you regularly review the Agreement and its terms and conditions on a regular basis.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} may remove any material from the Site at any time without cause and without notice to any person including you. Any removal of material is not an admission that the material is inappropriate nor is it an admission of any liability by LEARNBLOCK{String.fromCharCode(8482)}. </p>
                    <p>As a guide, where LEARNBLOCK{String.fromCharCode(8482)} has received a complaint about material or considers for its own reasons, and in its own discretion, that material must be removed, it will ordinarily remove that material if it (without limitation):</p>
                    <ul>
                        <li>is for a commercial purpose</li>
                        <li>is indecent or abusive</li>
                        <li>is false or misleading</li>
                        <li>is slanderous, libelous or defamatory</li>
                        <li>is likely to cause injury of any kind</li>
                        <li>violates any rights of any person</li>
                        <li>violates any applicable laws, rules, or regulations.</li>
                        <li>contains software viruses or malicious code.</li>
                        <li>is in LEARNBLOCK{String.fromCharCode(39)}s opinion of a vexatious nature.</li>
                    </ul>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Limitation of Liability</Typography>
                <Paper className="section">
                    <p>The Site is provided on an {String.fromCharCode(34)}as is{String.fromCharCode(34)} basis without warranties of any kind, either express or implied, including (but not limited to) warranties of merchantability or fitness for a particular purpose, other than those warranties which are implied by and incapable of exclusion, restriction or modification under the laws applicable to transactions conducted on the Site.</p>
                    <p>You expressly agree that use of the Site at your sole risk. LEARNBLOCK{String.fromCharCode(8482)}, its affiliates, related bodies corporate, nor any of their respective employees, officers, contractors, agents, third-party content providers or licensors warrant that the Site will be uninterrupted or error-free. Nor do they make any warranty as to the results that may be obtained from use of the Site, or as to the accuracy, reliability or content of any information or service provided through the Site.</p>
                    <p>This disclaimer of liability applies to any damages or injury from any cause including, without limitation, failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorised access to, alteration of, or use of record, whether for breach of contract, negligence or under any other cause of action. You specifically acknowledge that LEARNBLOCK{String.fromCharCode(8482)} is not liable for any defamatory, offensive or illegal conduct by you, other users and/or their agents and that the risk of injury from the foregoing rests entirely with you.</p>
                    <p>In no event will LEARNBLOCK{String.fromCharCode(8482)}, its affiliates, related bodies corporate, nor any of their respective employees, officers, contractors, agents, third-party content providers or licensors be liable for any damages, including (without limitation) direct, indirect, consequential, special, incidental or punitive damages arising out of the use or otherwise of the Site.</p>
                    <p>FOR AVOIDANCE OF DOUBT, to the full extent permitted by Australian law, LEARNBLOCK{String.fromCharCode(8482)}, its affiliates, related bodies corporate, their respective employees, officers, contractors, agents, third-party content providers or licensors will not be liable for any loss, claims, damages or costs (including legal costs and expenses on a full indemnity basis) arising out of or in connection with Your use or inability to use the Site including but not limited to all indirect, incidental, special and consequential damages or loss of profits or opportunity to any person even if such loss or damages were reasonably foreseeable.</p>
                    <p>Without limitation to the foregoing and to the full extent permitted by law, LEARNBLOCK{String.fromCharCode(39)}s liability, if any, for any equivalent breach of a condition or warranty implied by the Australian Consumer Law or state and territory legislation in Australia which cannot be excluded is limited, as determined by LEARNBLOCK{String.fromCharCode(8482)} in its absolute discretion.</p>
                    <p>No waiver of any obligation or right by LEARNBLOCK{String.fromCharCode(8482)} shall be effective unless in writing and executed by LEARNBLOCK{String.fromCharCode(8482)}. No waiver of any breach of the Agreement shall be deemed to be a waiver of any preceding or succeeding breach of the same or any other term.</p>
                    <p>You are solely responsible for your interactions with other users. LEARNBLOCK{String.fromCharCode(8482)} reserves the right, but has no obligation, to monitor disputes between you and other users.</p>
                    <p>You release and indemnify each of LEARNBLOCK{String.fromCharCode(8482)} and its affiliates, related bodies corporate, their respective employees, officers, contractors, agents, third-party content providers and licensors against all actions, claims, demands (including the cost of defending or settling any action, claim or demand) which may be instituted against any of LEARNBLOCK{String.fromCharCode(8482)} or its affiliates, related bodies corporate, their respective employees, officers, contractors, agents, third-party content providers or licensors arising out of any act or omission on your part, including but not limited to a failure by you to comply with your obligations under the terms and conditions.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Termination of Obligations</Typography>
                <Paper className="section">
                    <p>The Agreement and your access to the Site may be terminated at any time by LEARNBLOCK{String.fromCharCode(8482)} without notice. All restrictions, licences granted by you and all disclaimers and limitations of liability by LEARNBLOCK{String.fromCharCode(8482)} will survive termination, however, you will no longer be authorised to access the Site.</p>
                    <p>If LEARNBLOCK{String.fromCharCode(8482)} has reason to believe that any aspect of the Site is being used for an improper purpose by you and/or on your behalf then LEARNBLOCK{String.fromCharCode(8482)} may terminate any and all of its responsibilities to you without prior notice. Such termination will not prejudice LEARNBLOCK{String.fromCharCode(39)}s rights to recover fees or damages or its general rights against you and or jointly with others.</p>
                    <p>A reference to an indemnity in this Agreement is a reference to an unconditional, irrevocable and continuing indemnity.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">'No commercial use' policy</Typography>
                <Paper className="section">
                    <p>The contents of the Site are only for your personal, non-commercial use. You agree not to interrupt, or attempt to interrupt, the operation of the Site in any way as a result of your use or actions. Our reports, information, comparisons and ratings, if any, may not be used in advertising or for any other commercial purpose and any use for commercial purposes is a breach of this Agreement. LEARNBLOCK{String.fromCharCode(8482)} reserves all its rights to take any and all steps open to it to prevent commercial use of the Site and its contents. Please report any apparent violation to support@learnblock.com</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Intellectual Property</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} owns the trade marks LEARNBLOCK{String.fromCharCode(8482)}, LEARNBLOCK logo, as well as other trade marks . LEARNBLOCK{String.fromCharCode(8482)} also owns domains learnblock.com and learnblock.com.au also owns all right, title and interest (including present and future copyright) in the works appearing on the Site including graphics, logos, names, text, graphics, images, photographs, illustrations, diagrams, logos, buttons, icons, software, data, audio-visuals, literary works and all products, services, processes and technologies described on the Site including compilations thereof. LEARNBLOCK{String.fromCharCode(8482)} also owns all knowhow, confidential information and compilations of data appearing on the Site ({String.fromCharCode(34)}Intellectual Property{String.fromCharCode(34)}).</p>
                    <p>Except to the extent necessary for you to download or print any material on the Site for your own personal use, reproduction (including by electronic means) of publications and material, in whole or in part, is forbidden without prior written permission (and is never permitted for commercial purposes). Address any and all requests to support@learnblock.com.</p>
                    <p>All literary and artistic works and compilations of information are the subject of Australian and International copyright {String.fromCharCode(169)} LEARNBLOCK{String.fromCharCode(8482)} {(new Date().getFullYear())}. All rights reserved.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} reserves all its rights including by injunction to take any and all steps to prevent infringement of its intellectual property or breach of the terms and conditions of the Agreement.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Links to other sites</Typography>
                <Paper className="section">
                    <p>Links within the Site are provided for your information. LEARNBLOCK{String.fromCharCode(8482)} does not endorse and has not verified any of the information available through the use of any link found within the Site.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Special note for Webmasters and ISPs</Typography>
                <Paper className="section">
                    <p>You may not copy or use any of the graphics (or other elements) or any aspect or integer of the Intellectual Property from the Site in another site. Any link to the Site must be a text link only with the attribute LEARNBLOCK{String.fromCharCode(8482)}. </p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} reserves all its rights including by injunction or other steps as appropriate to prevent links to the Site which fall outside the terms and conditions of the Site.</p>
                    <p>If you are an ISP, you may supply the contents of the Site to your subscriber. However, any other use of the Site is prohibited without the prior written permission of LEARNBLOCK{String.fromCharCode(8482)}. </p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Terms of use relating to your comments</Typography>
                <Paper className="section">
                    <p>(a) Please consider others when making contributions to the Site;</p>
                    <p>(b) You are solely responsible for the content of any contributions you make on the Site. All contributions are public, unless otherwise indicated. You must not harvest, scrape or collect any email addresses from this Site for the purpose of sending unsolicited emails or spam;</p>
                    <p>(c) We encourage you to actively participate in any discussion groups and forums on the Site. However, you must not (unless we consent in writing) include content which is:</p>
                    <ul>
                        <li>for commercial purposes and/or contains advertisements or paid links, promotion, marketing, any form of link or spam, or attempts to generate donations of any kind (see {String.fromCharCode(39)}No commercial use{String.fromCharCode(39)} policy).</li>
                        <li>indecent, abusive, unlawful, profane, harmful, threatening, harassing, sexually explicit, vulgar, obscene, hateful, racially, ethnically or otherwise objectionable or invasive of another{String.fromCharCode(39)}s privacy</li>
                        <li>false or misleading, including but not limited to the impersonation of other people or identities.</li>
                        <li>slanderous, libelous or defamatory of any person or entity.</li>
                        <li>likely or does cause injury of any kind to any party.</li>
                        <li>an infringement or violation of any aspect or integer of the Intellectual Property, any aspect or integer of the terms and conditions of the Agreement, or any rights of LEARNBLOCK{String.fromCharCode(8482)} or any third party.</li>
                        <li>a violation of any applicable laws, rules, or regulations.</li>
                        <li>content that contains software viruses or any malicious code.</li>
                        <li>personal information about another person without their permission</li>
                        <li>professional advice</li>
                        <li>information that You know or ought to know is false, misleading or deceptive.</li>
                    </ul>
                    <p>In addition, you agree not to use the Site to:</p>
                    <ul>
                        <li>harvest or collect email addresses or other contact information of other users of the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;</li>
                        <li>use the Site in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Site;</li>
                        <li>use automated scripts to interact with the Site;</li>
                        <li>upload, post, transmit, share, store or otherwise make available any content that LEARNBLOCK{String.fromCharCode(8482)} deems to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;</li>
                        <li>upload, post, transmit, share, store or otherwise make available any videos</li>
                        <li>impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with any person or entity;</li>
                        <li>upload, post, transmit, share or otherwise make available any unsolicited or unauthorised advertising, solicitations, promotional materials, {String.fromCharCode(34)}junk mail,{String.fromCharCode(34)} {String.fromCharCode(34)}spam,{String.fromCharCode(34)} {String.fromCharCode(34)}chain letters,{String.fromCharCode(34)} {String.fromCharCode(34)}pyramid schemes,{String.fromCharCode(34)} or any other form of solicitation;</li>
                        <li>upload, post, transmit, share, store or otherwise make publicly available on the Site any private information of any third party, including, addresses, phone numbers, email addresses and/or credit card numbers;</li>
                        <li>solicit personal information from anyone under 18 or solicit passwords or personally identifying information for commercial or unlawful purposes;</li>
                        <li>upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
                        <li>intimidate or harass another;</li>
                        <li>upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law;</li>
                        <li>upload, post, transmit, share, store or otherwise make available content that, in the sole judgment of LEARNBLOCK{String.fromCharCode(8482)}, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose LEARNBLOCK{String.fromCharCode(8482)} or its users to any harm or liability of any type;</li>
                    </ul>
                    <p>(d) You are solely responsible for the photos, profiles (including your name, image, and likeness), messages, notes, text, information, music, video, advertisements, listings, and other content that you upload, publish or display on the Site, or transmit to or share with other users;</p>
                    <p>(e) You may not post, transmit, or share content from the Site that you did not create or that You do not have permission to post. You understand and agree that the LEARNBLOCK{String.fromCharCode(8482)} may, but is not obligated to, review the Site and may delete or remove (without notice) any Site content or user content in its sole discretion, for any reason or no reason, including Your content that in the sole judgment of LEARNBLOCK{String.fromCharCode(8482)} violates this Agreement or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users or others;</p>
                    <p>(f) When you post content to the Site, you authorise and direct us to make such copies thereof as we deem necessary in order to facilitate the posting and storage of the content on the Site;</p>
                    <p>(g) LEARNBLOCK{String.fromCharCode(8482)} may, at our sole discretion, immediately terminate your content and/or access if you fail to comply with the terms and conditions of the Agreement;</p>
                    <p>(h) LEARNBLOCK{String.fromCharCode(8482)} in its absolute discretion reserves the right to not publish contributions that include any one or more of the content as listed above, do not adhere to the terms and conditions of the Agreement or for any other reason is in LEARNBLOCK{String.fromCharCode(39)}s absolute discretion deemed editorially inappropriate;</p>
                    <p>(i) You acknowledge that you have no expectation of privacy in any public communication you make to the Site. You further acknowledge and agree that, unless otherwise indicated, any communications made to any portion of the site are public and you aware of the impact and damage that a breach of the terms and conditions of this Agreement will have upon LEARNBLOCK{String.fromCharCode(8482)} and/or a third party;</p>
                    <p>(j) You indemnify LEARNBLOCK{String.fromCharCode(8482)} and its joint venturers, agents, officers, successors, assigns, licences and/or partners if any (Parties) for all loss suffered as a result of any contribution you make to the Site including any loss LEARNBLOCK{String.fromCharCode(8482)} and/or the Parties suffer or are likely to suffer as a result of making the content of your contribution available to third parties.</p>
                    <p>(k) LEARNBLOCK{String.fromCharCode(8482)} may, in its absolute discretion and without notification, remove any material from the Site at any time including material you have transmitted to the Site. LEARNBLOCK{String.fromCharCode(8482)} is under no obligation to make any material transmitted to the Site available to any person. LEARNBLOCK{String.fromCharCode(8482)} has no liability for any consequences of its reliance on its rights under this clause.</p>
                    <p>(l) To the extent permissible by the laws of Australia, LEARNBLOCK{String.fromCharCode(8482)} disclaims all liability in relation to your use of the site.</p>

                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">Complaints about Your comments</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} does not endorse, or necessarily agree with, the contributions of members of the public including you. If you believe blog comments are inappropriate or unsuitable, please support@learnblock.com with the following details:</p>
                    <ol>
                        <li>where the material is located on the Site;</li>
                        <li>why you consider the material to be inappropriate; and</li>
                        <li>Your name and contact details.</li>
                    </ol>
                    <p>If you do, this will make it easier to identify the material to which your complaint relates. Where you consider that the material impacts upon you directly, you may choose to post a defence to the statement in the discussion or at the time you make your complaint.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} takes all complaints it receives seriously. On receipt of any complaint we will review the relevant material and remove it, if appropriate. While we aim to act on any complaint you choose to send us, we cannot guarantee that we will read your complaint (or act on it) within any specific time period.</p>
                    <p>We will not act on any complaint which, in LEARNBLOCK{String.fromCharCode(39)}s opinion, is vexatious, or which appears to be so. If you do not include your name and contact details in the complaint we may treat the complaint as vexatious.</p>
                </Paper>
                <Typography className="emphasise primary heading-font" variant="h5">LEARNBLOCK{String.fromCharCode(8482)} contribution</Typography>
                <Paper className="section">
                    <p>From time to time, LEARNBLOCK{String.fromCharCode(8482)} officers, employees and/or agents may respond to queries or comments. Such participation is not approval, agreement or endorsement of the views expressed in user comments. LEARNBLOCK{String.fromCharCode(8482)} reserves the right not to respond to individual queries or requests for advice.</p>
                    <p>Reference on the Site to any products, services or other information does not constitute or imply endorsement, sponsorship or a recommendation thereof.</p>
                </Paper>
            </Box>
        </Box>
    )
}