/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../../auth';
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, TextField } from '@mui/material';
import CodeEditorBasic from '../../CodeEditorBasic';
import MarkdownRender from '../../MarkdownRender';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function QuestionSTEM({ question, setQuestion, editMode, rerender }) {
	const { isAdmin } = useAuth();
	const { question_stem, question_type, total_marks } = question;

	return (
		<Box id="question__stem">
			{editMode === false && !isEmpty(question_stem) && <MarkdownRender children={question_stem} />}
			{editMode === true && isAdmin && (
				<Box className="edit-content">
					<Box className="edit-content__box">
						{question_type === 'single' && (
							<TextField
								label="Marks"
								color="primary"
								variant="outlined"
								value={total_marks}
								autoFocus
								onChange={(e) =>
									setQuestion((prev) => ({
										...prev,
										total_marks: isEmpty(e.target.value) ? '' : parseInt(e.target.value),
									}))
								}
								sx={{
									width: '100px',
									marginBottom: '20px',
									'& .MuiInputLabel-root': {
										top: '-8px',
										'&.MuiInputLabel-shrink': {
											top: '0px',
										},
									},
									'& .MuiOutlinedInput-root': {
										borderRadius: '40px',
									},
									'& .MuiOutlinedInput-input': {
										padding: '10px 20px',
									},
									'& .MuiOutlinedInput-notchedOutline': {
										borderWidth: '2px',
									},
								}}
							/>
						)}
						<Box sx={styles.miniTitle} className="section-title text-upper-spaced">
							Question STEM
						</Box>
						{!isEmpty(question_stem) && <MarkdownRender children={question_stem} />}
						<CodeEditorBasic
							rerender={rerender}
							bucket="questions"
							language="markdown"
							initialCode={question_stem}
							onChange={(value) =>
								setQuestion((prev) => ({
									...prev,
									question_stem: value,
								}))
							}
							style={{ marginTop: '20px' }}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
}
