/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Button, Rating, Chip } from '@mui/material';
import { PersonSearch as TutorIcon, Close as CloseIcon, Lightbulb as LightbulbIcon, Gesture as ScrapPaperIcon, EmojiFlags as FlagIcon, Flag as UnflagIcon, FactCheck as CheckAnswerIcon, Done as TickIcon, Close as CrossIcon, Cancel as SelectedCrossIcon, SentimentVeryDissatisfied, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAltOutlined, SentimentVerySatisfied } from '@mui/icons-material';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/

const SENTIMENT_ICONS = {
	1: { icon: <SentimentVeryDissatisfied />, label: 'Very Dissatisfied' },
	2: { icon: <SentimentDissatisfied />, label: 'Dissatisfied' },
	3: { icon: <SentimentSatisfied />, label: 'Neutral' },
	4: { icon: <SentimentSatisfiedAltOutlined />, label: 'Satisfied' },
	5: { icon: <SentimentVerySatisfied />, label: 'Very Satisfied' },
};

function SentimentIconContainer(props) {
	const { value, ...other } = props;
	return <span {...other}>{SENTIMENT_ICONS[value].icon}</span>;
}

{
	/* {!isEmpty(question_performance.correct) && <Chip className="text-upper-spaced" color={question_performance.correct ? 'success' : 'error'} variant="filled" icon={question_performance.correct ? <TickIcon /> : <CrossIcon />} label={question_performance.correct ? 'Correct' : 'Incorrect'} />} */
}
{
	/* {isEmpty(question_performance.correct) && (
    <React.Fragment>
        <Chip className="text-upper-spaced" color="primary" variant={question_performance.correct === true ? 'filled' : 'outlined'} icon={<TickIcon />} label="Correct" onClick={() => handleUserAction('correct', true)} />
        <Chip className="text-upper-spaced" color="primary" variant={question_performance.correct === false ? 'filled' : 'outlined'} icon={<CrossIcon />} label="Incorrect" onClick={() => handleUserAction('correct', false)} />
    </React.Fragment>
)} */
}

export default function ControlsUser({ question, controls, setControls, handleUserAction }) {
	const { scribbleMode, displayHint, displayTutors } = controls;
	const { question_type, question_performance, question_performance_steps } = question;

	return (
		<React.Fragment>
			{!isEmpty(question_performance) && question_performance.checked === true && (
				<React.Fragment>
					<Box sx={{ fontWeight: 600, color: 'primary.main', marginTop: '25px' }} className="text-upper-spaced">
						* Your answers below helps the Learnblock AI recommend better questions
					</Box>
					<Box sx={styles.userPerformanceMetrics}>
						{question_type === 'single' && !isEmpty(question_performance) && question_performance.checked === true && (
							<Box className="metric-summary box-shadow2" sx={styles.emphasiseBubble}>
								<Box className="metric-name text-upper-spaced">* Did you get this question correct?</Box>
								<Box className="main-data">
									<Chip className="text-upper-spaced" color={question_performance.correct === true ? 'success' : 'primary'} variant={question_performance.correct === true ? 'filled' : 'outlined'} icon={<TickIcon />} label="Correct" onClick={() => handleUserAction('correct', true)} />
									<Chip className="text-upper-spaced" color={question_performance.correct === false ? 'error' : 'primary'} variant={question_performance.correct === false ? 'filled' : 'outlined'} icon={question_performance.correct === false ? <SelectedCrossIcon /> : <CrossIcon />} label="Incorrect" onClick={() => handleUserAction('correct', false)} />
								</Box>
								{question_performance.correct === false && (
									<Box sx={{ marginTop: '15px' }} className="wrong-positions-summary">
										<Box className="metric-name text-upper-spaced">* Tell us where you went wrong. Click the incorrect steps</Box>
										<Box className="main-data">
											{isEmpty(question_performance_steps) && <Box sx={{ color: 'text.grey', fontStyle: 'italic' }}>You have not selected any steps.</Box>}
											{!isEmpty(question_performance_steps) &&
												question_performance_steps.map((el) => {
													return <Chip className="text-upper-spaced" icon={<SelectedCrossIcon />} variant="filled" color="error" key={`step-${el.step}`} label={`Step ${el.step}`} />;
												})}
										</Box>
									</Box>
								)}
							</Box>
						)}
						{!isEmpty(question_performance) && question_performance.checked === true && (
							<Box className="metric-summary box-shadow2" sx={styles.emphasiseBubble}>
								<Box className="metric-name text-upper-spaced">* How'd you feel doing this question?</Box>
								<Rating sx={{ '& .MuiRating-icon': { fontSize: '1.5rem', '& .MuiSvgIcon-root': { fontSize: '1.5em' } } }} name="sentiment-selection" IconContainerComponent={SentimentIconContainer} value={!isEmpty(question_performance.sentiment) ? question_performance.sentiment : -1} onChange={(event, newValue) => handleUserAction('sentiment', newValue)} />
							</Box>
						)}
					</Box>
				</React.Fragment>
			)}
			<Box sx={styles.controls} className="user-controls">
				{(!('checked' in question_performance) || isEmpty(question_performance.checked) || question_performance.checked === false) && (
					<Button className="control-btn" startIcon={<CheckAnswerIcon />} disabled={question.question_type === 'mc' && !('mc_option_id' in question_performance)} color="primary" variant="contained" onClick={() => handleUserAction('checkedAnswer', true)}>
						{question_type === 'mc' ? 'Check Answer' : 'Show Solution'}
					</Button>
				)}
				<Button className={clsx('control-btn', 'flag', { active: !isEmpty(question_performance) && question_performance.flagged === true })} startIcon={question_performance.flagged === true ? <UnflagIcon /> : <FlagIcon />} color="primary" variant="contained" onClick={() => handleUserAction('flagged', !isEmpty(question_performance) && question_performance.flagged === true ? false : true)}>
					{question_performance.flagged === true ? 'Unflag' : 'Flag'}
				</Button>
				{!isEmpty(question.hint) && (
					<Button className={clsx('control-btn', { active: displayHint === true })} startIcon={displayHint === true ? <CloseIcon /> : <LightbulbIcon />} variant="contained" color="primary" onClick={() => setControls((prev) => ({ ...prev, displayHint: !prev.displayHint }))}>
						{displayHint === true ? 'Close Hint' : 'Open Hint'}
					</Button>
				)}
				<Button className={clsx('control-btn', { active: scribbleMode === true })} startIcon={<ScrapPaperIcon />} variant="contained" color="primary" onClick={() => setControls((prev) => ({ ...prev, scribbleMode: !prev.scribbleMode }))}>
					Scribble
				</Button>
				<Button className={clsx('control-btn', { active: displayTutors === true })} startIcon={<TutorIcon />} variant="contained" color="primary" onClick={() => setControls((prev) => ({ ...prev, displayTutors: !prev.displayTutors }))}>
					Need a Tutor?
				</Button>
			</Box>
			{/* <Box sx={styles.controls} className="user-controls">
				<Box sx={{ ...styles.miniTitle, marginBottom: '20px', color: 'secondary.main' }}>Still struggling? Get extra help {`(* AI Chat Coming Soon )`}</Box>
				
				<Button disabled={true} className={clsx('control-btn', { active: scribbleMode === true })} startIcon={<AIIcon />} variant="contained" color="primary">
					* Chat with AI
				</Button>
			</Box> */}
		</React.Fragment>
	);
}
