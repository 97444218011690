/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES / CONTEXTS
 ****************************************************************************************************/
import { InitialData } from '../data';
import { getUserProfile } from './AuthData';
import { isEmpty } from '../utils';
import { supabase } from '../supabaseClient';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { SnackbarAlert } from '../@core/Alert';
import { LoaderBackdrop } from '../@core/Loader';
/****************************************************************************************************
 * HELPERS
 ****************************************************************************************************/
// Initialise context variable
export const AuthContext = React.createContext();
// Create a utility useAuth to import the values
export const useAuth = () => React.useContext(AuthContext);
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export function AuthProvider({ children }) {
	const [appUser, setAppUser] = React.useState(InitialData.completed);
	const [snackbar, setSnackbar] = React.useState({ open: false, severity: '', message: '' });
	const { hash } = useLocation();

	React.useEffect(() => {
		const checkSession = async () => {
			console.log('SESSION BEING CHECKED');
			const { data, error } = await supabase.auth.getSession();
			if (isEmpty(data.session) && !isEmpty(appUser.data)) {
				setSnackbar({ open: true, severity: 'error', message: 'Sorry, your session has expired, you need to login again.' });
				setAppUser(InitialData.completed);
			}
		};

		const handleAuthStateChange = async (event, session) => {
			if ((event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') && appUser.loading === false && isEmpty(appUser.data)) {
				await getUser(session?.user?.id ?? null);
			} else if (event === 'SIGNED_OUT') {
				setAppUser(InitialData.completed);
			}
		};

		const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => handleAuthStateChange(_event, session));

		// Periodically check the session every 5 minutes
		const checkSessionInterval = setInterval(checkSession, 5 * 60 * 1000);

		if (hash && hash.startsWith('#message=')) {
			let message = hash.split('#message=')[1];
			message = message.replace(/\+/g, ' ');
			setSnackbar({ open: true, severity: 'success', message: message });
			window.history.replaceState(null, '', window.location.pathname);
		}

		return () => {
			authListener.subscription?.unsubscribe();
			clearInterval(checkSessionInterval);
		};
	}, [appUser]);

	const getUser = async (userId) => {
		if (!userId) return;
		setAppUser(InitialData.loading);
		const res = await getUserProfile(userId);
		if (!res.success) return setAppUser({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setAppUser({ ...InitialData.completed, data: res.data });
	};

	const logoutUser = async () => {
		setAppUser(InitialData.loading);
		await supabase.auth.signOut();
		setAppUser(InitialData.completed);
	};

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated: !isEmpty(appUser.data),
				appUser: !isEmpty(appUser.data) ? appUser.data : {},
				isAdmin: !isEmpty(appUser.data) && appUser.data.admin,
				userLoading: appUser.loading,
				userError: appUser.error,
				setAppUser: (userProfile) => setAppUser((prev) => ({ ...prev, data: userProfile })),
				logoutUser: () => logoutUser(),
			}}
		>
			{snackbar.open && <SnackbarAlert severity={snackbar.severity} open={snackbar.open} message={snackbar.message} handleClose={() => setSnackbar({ open: false, severity: '', message: '' })} />}
			{appUser.loading === true && <LoaderBackdrop />}
			{appUser.loading === false && (
				<main>
					{appUser.error && <SnackbarAlert open={appUser.error} message={appUser.errorMessage} handleClose={() => setAppUser(InitialData.completed)} />}
					{children}
				</main>
			)}
		</AuthContext.Provider>
	);
}
