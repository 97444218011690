/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Chip, IconButton } from '@mui/material';
import { Done as TickIcon, Close as CrossIcon, Cancel as SelectedCrossIcon } from '@mui/icons-material';
import MarkdownRender from '../../MarkdownRender';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
const PART_NUMS = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xi', 'xii', 'xiii', 'xiv', 'xv'];
export default function QuestionParts({ controls, question, handleUserAction, handleStepSelection }) {
	const { displayUserControls } = controls;
	const { question_options, question_performance, question_performance_steps } = question;

	const returnColor = (partId) => {
		if (isEmpty(question_performance)) {
			return 'primary';
		} else if (!isEmpty(question_performance) && !isEmpty(question_performance.correct_parts) && question_performance.correct_parts.includes(partId)) {
			return 'success';
		} else if (!isEmpty(question_performance) && !isEmpty(question_performance.incorrect_parts) && question_performance.incorrect_parts.includes(partId)) {
			return 'error';
		} else {
			return 'primary';
		}
	};

	const isCorrect = (partId) => {
		if (!isEmpty(question_performance) && !isEmpty(question_performance.correct_parts) && question_performance.correct_parts.includes(partId)) {
			return true;
		}
		return false;
	};

	const isIncorrect = (partId) => {
		if (!isEmpty(question_performance) && !isEmpty(question_performance.incorrect_parts) && question_performance.incorrect_parts.includes(partId)) {
			return true;
		}
		return false;
	};

	return (
		<Box id="question-parts" sx={styles.questionParts}>
			{question_options.map((part, partIndex) => {
				return (
					<Box className="part" key={`part-${partIndex}`}>
						<Box className="part-content">
							<Box className="part-main-content">
								<Box className="part-content__content">
									<Box className="part-content__question-text">
										<Box className="part-content__num">{PART_NUMS[partIndex]}</Box>
										<MarkdownRender children={part.question_text} />
										<Chip icon={isCorrect(part.id) ? <TickIcon /> : isIncorrect(part.id) ? <SelectedCrossIcon /> : null} className="part-content__marks text-upper-spaced" color={returnColor(part.id)} label={`${part.marks} ${part.marks === 1 ? 'Mark' : 'Marks'}`} />
									</Box>
									{!isEmpty(question_performance) && question_performance.checked === true && (
										<Box sx={{ ...styles.emphasiseBubble, padding: '0px' }}>
											<Box sx={{ padding: '20px' }}>
												<Box sx={styles.miniTitle}>Part {PART_NUMS[partIndex]} Solution</Box>
												<MarkdownRender correct={question_performance.checked === true && isIncorrect(part.id) === true ? false : question_performance.checked === true && isCorrect(part.id) ? true : null} questionPerformanceSteps={question_performance_steps.filter((el) => el.option_id === part.id)} handleStepSelection={(data) => handleStepSelection(data, part.id)} children={part.solution_text} />
											</Box>
											<Box sx={{ borderTop: '2px solid rgba(0,0,0,.04)', backgroundColor: 'rgba(0,0,0,.04)', borderRadius: '0 0 10px 10px', padding: '20px' }}>
												{displayUserControls === true && !isEmpty(question_performance) && question_performance.checked === true && (
													<Box>
														<Box sx={styles.miniTitle}>How did you go on this part?</Box>
														<Chip className="text-upper-spaced" color={isCorrect(part.id) ? 'success' : 'primary'} variant={isCorrect(part.id) ? 'filled' : 'outlined'} icon={<TickIcon />} label="Correct" onClick={() => handleUserAction('correct-part', part.id)} />
														<Chip className="text-upper-spaced" color={isIncorrect(part.id) ? 'error' : 'primary'} variant={isIncorrect(part.id) ? 'filled' : 'outlined'} icon={isIncorrect(part.id) ? <SelectedCrossIcon /> : <CrossIcon />} label="Incorrect" onClick={() => handleUserAction('incorrect-part', part.id)} />
													</Box>
												)}
												{displayUserControls === true && !isEmpty(question_performance) && question_performance.checked === true && isIncorrect(part.id) && (
													<Box sx={{ marginTop: '15px' }}>
														<Box sx={styles.miniTitle}>Tell us where you went wrong. Click the incorrect steps</Box>
														{isEmpty(question_performance_steps) && <Box sx={{ color: 'text.grey', fontStyle: 'italic' }}>You have not selected any steps.</Box>}
														{!isEmpty(question_performance_steps) &&
															question_performance_steps.map((el) => {
																return <Chip className="text-upper-spaced" icon={<SelectedCrossIcon />} variant="filled" color="error" key={`step-${el.step}`} label={`Step ${el.step}`} />;
															})}
													</Box>
												)}
											</Box>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
}
