/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * UTILITIES
****************************************************************************************************/
import { isEmpty } from '../../utils';
/**************************************************************************************************** 
 * COMPONENT
****************************************************************************************************/
import { Typography, Box } from '@mui/material';
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
NoDataMessage.propTypes = {
    itemName: PropTypes.string,
    multi: PropTypes.bool,
    customMessage: PropTypes.string,
    sx: PropTypes.object,
}
NoDataMessage.defaultProps = {
    multi: true,
    sx: {}
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function NoDataMessage({ multi, itemName, customMessage, sx }) {
    return (
        <Box sx={{ color: 'text.grey', maxWidth: '800px', margin: 'auto', padding: { xs: '20px', sm: '40px', md: '60px' }, ...sx }}>
            {isEmpty(customMessage) ?
                <Typography align="center" variant="h5">Sorry, there {multi ? 'are' : 'is'} no {itemName} currently available. Please try again later.</Typography> :
                <Typography align="center" variant="h5">{customMessage}</Typography>
            }
        </Box>

    )
}