import { AppUtils, isEmpty, round, convertMinutesToTimeString } from '../../utils';

const DAY_OF_WEEK = {
	1: 'Mon',
	2: 'Tue',
	3: 'Wed',
	4: 'Thu',
	5: 'Fri',
	6: 'Sat',
	7: 'Sun',
};

export const renderDurationStringFromMins = (mins) => {
	if (mins < 60) {
		return `${mins} mins`;
	} else if (mins === 60) {
		return `hour`;
	} else if (mins > 60) {
		return `${round(mins / 60, 1)} hours`;
	}
};

export const renderTimestampStringFromMins = (day_of_week, start_time, end_time, timezone) => {
	const from_time = convertMinutesToTimeString(day_of_week, start_time, timezone, 'HH:mm');
	const to_time = convertMinutesToTimeString(day_of_week, end_time, timezone, 'HH:mm');
	return `${DAY_OF_WEEK[day_of_week]} ${from_time}-${to_time}`;
};

export const calcIndividualTimesBasedOnCourse = (course_duration, new_availabilities) => {
	const course_availabilities = [];
	// console.log(`For the course: `, courseId);
	if (!isEmpty(course_duration) && !isEmpty(new_availabilities)) {
		new_availabilities.forEach((avail) => {
			// Create the maximum number of objects that can fit into the avail session based on the course_duration
			// console.log('the availability: ', avail);
			let num_sessions = Math.floor((avail.end_time - avail.start_time) / course_duration);
			// console.log(`fits ${num_sessions} @ duration of ${course_duration}`);
			for (let i = 0; i < num_sessions; i++) {
				const available_zone = {
					course_type: 'individual',
					day_of_week: avail.day_of_week,
					start_time: avail.start_time + i * course_duration,
					end_time: avail.start_time + (i + 1) * course_duration,
				};
				// console.log('i=' + i + ' | duration=' + i * course_duration, available_zone);
				course_availabilities.push(available_zone);
			}
		});
	}
	return course_availabilities;
};

export const calcGroupTimesBasedOnCourse = (courseId, bookings, tempBookings) => {
	// console.log(courseId, bookings);
	const groupBookings = bookings.filter((booking) => booking.tutor_course_id === courseId && booking.course_type === 'group');
	const groupTempBookings = tempBookings.filter((booking) => booking.tutor_course_id === courseId && booking.course_type === 'group');
	const availableGroupTimes = groupBookings.filter((booking) => (groupTempBookings.findIndex((tempBooking) => tempBooking.tutor_course_id === booking.tutor_course_id && tempBooking.start_time === booking.start_time && tempBooking.end_time === booking.end_time) >= 0 ? false : true));
	// We want to filter down the groupBookings that are not already inside the temp bookings
	return availableGroupTimes;
};

export const returnAvailabilitiesByCourse = (courses, availabilities, bookings, tempBookings) => {
	const new_availabilities = [];
	// HACK: To calculate availabilities we need to get a list of all the bookings, which include the bookings already on the server
	// And the bookings the user has selected. However, there is an edge case where group bookings on the server are already inside
	// the bookings array, and then when selected are pushing into tempbookings, creating a duplicate entry. What we need to do is
	// filter out any tempBookings that are of type group, since they will already exist on the bookings by default when calculating
	// availabilities
	const allBookings = [...bookings, ...tempBookings.filter((booking) => booking.course_type === 'individual')];
	// Sort the bookings by start time
	if (allBookings.length > 1) allBookings.sort((a, b) => a.start_time - b.start_time);

	// Create a list of availabilities by removing any timeslots where there is an existing booking
	availabilities.forEach((avail) => {
		let tempStart = avail.start_time;
		allBookings.forEach((book) => {
			if (avail.day_of_week === book.day_of_week) {
				if (book.start_time > tempStart && book.start_time < avail.end_time) {
					new_availabilities.push({
						day_of_week: avail.day_of_week,
						start_time: tempStart,
						end_time: book.start_time,
					});
					tempStart = book.end_time;
				} else if (book.start_time === tempStart) {
					tempStart = book.end_time;
				}
			}
		});
		if (tempStart < avail.end_time) {
			new_availabilities.push({
				day_of_week: avail.day_of_week,
				start_time: tempStart,
				end_time: avail.end_time,
			});
		}
	});

	// Now create a courses data structure where each key is the course.id and it contains an object with arrays for 1-1 times and group times
	// For each course, we want the availabilities to represent the duration of the course to maximise the number of bookings that can be made in any one time slot
	const final_classes = courses
		.filter((course) => course.tutor_course.event_type === 'class')
		.map((course) => {
			return {
				tagDomain: course.tag.domain,
				tagGroup: course.tag.group,
				data: course.tutor_course,
				individual_times: course.tutor_course.offered_as.includes('individual') ? [...calcIndividualTimesBasedOnCourse(course.tutor_course.duration_individual, new_availabilities)] : [],
				group_times: course.tutor_course.offered_as.includes('group') ? [...calcGroupTimesBasedOnCourse(course.tutor_course.id, bookings, tempBookings)] : [],
			};
		});

	return final_classes;
};

export const calcPriceBasedOnDuration = (bookings) => {
	const prices = { individual: 0, group: 0 };
	const duration = { individual: 0, group: 0 };
	const totalDuration = { individual: 0, group: 0 };
	bookings.forEach((booking) => {
		const { course_type, courseData, start_time, end_time } = booking;
		const { duration_individual, price_individual, duration_group, price_group } = courseData;
		if (course_type === 'individual') {
			totalDuration.individual = totalDuration.individual + (end_time - start_time);
			duration.individual = duration_individual;
			prices.individual = price_individual;
		} else if (course_type === 'group') {
			totalDuration.group = totalDuration.group + (end_time - start_time);
			duration.group = duration_group;
			prices.group = price_group;
		}
	});

	const totals = {
		individual: duration.individual > 0 ? (totalDuration.individual / duration.individual) * prices.individual : 0,
		group: duration.group > 0 ? (totalDuration.group / duration.group) * prices.group : 0,
	};

	return `${totals.individual + totals.group} / ${renderDurationStringFromMins(totalDuration.individual + totalDuration.group)}`;
};

export const groupSelectedTimesByCourse = (courseId, tempBookings) => {
	const filterBookings = tempBookings.filter((booking) => booking.courseData.id === courseId);
	return filterBookings;
};

export const sortBookingTimesByDayAndTime = (times) => {
	if (times.length < 2) return times;
	return times.sort((a, b) => a.day_of_week * a.start_time - b.day_of_week * b.start_time);
};
