import React from 'react';
import { DateTime } from 'luxon';
import { LooksOne as Looks1, LooksTwo as Looks2, Looks3, Looks4, Looks5, Looks6, Star as StarIcon, Filter1, Filter2, Filter3, Filter4, Filter5, Filter6, Filter7, Filter8, Filter9 } from '@mui/icons-material';
export { default as AppUtils } from './appUtils';
export { default as TreeUtils } from './treeUtils';
export { default as useStatus } from './useStatus';
export { default as useQuery } from './useQuery';
export { default as useEffectAfterMount } from './useEffectAfterMount';
export { default as ScrollToTop } from './ScrollToTop';
export { default as useWindowSize } from './useWindowSize';
export { default as useKey } from './useKey';
export { default as useInterval } from './useInterval';
export { default as usePrevious } from './usePrevious';
export { default as useStateCallback } from './useStateCallback';
export { default as useDebounce } from './useDebounce';

/****************************************************************************************************
 * CLIPBOARD
 ****************************************************************************************************/
export async function copyTextToClipboard(text) {
	if ('clipboard' in navigator) {
		return await navigator.clipboard.writeText(text);
	} else {
		return document.execCommand('copy', true, text);
	}
}

/****************************************************************************************************
 * VALIDATION
 ****************************************************************************************************/
export const isEmpty = (value) => value === undefined || value === null || (typeof value === 'number' && Number.isNaN(value) === true) || (typeof value === 'boolean' && value === false) || (typeof value === 'string' && value.trim().length === 0) || (typeof value === 'object' && Object.keys(value).length === 0 && !(value instanceof Date)) || (value instanceof Date && isNaN(new Date(value)));

/****************************************************************************************************
 * STRING PARSING
 ****************************************************************************************************/
export const capitalizeFirst = (s) => {
	if (typeof s !== 'string') return '';
	// First lowercase everything in the string
	s = s.toLowerCase();
	// Then capitalise the first letter
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAllFirst = (s, separator = 0) => {
	if (typeof s !== 'string') return '';
	const split_string = s.split(separator ? separator : ' ');
	const newStringArray = [];
	split_string.forEach((word) => {
		const capitalizedWord = capitalizeFirst(word);
		newStringArray.push(capitalizedWord);
	});
	const finalString = newStringArray.join(' ');
	return finalString;
};

export const replaceWhiteSpaces = (string, character) => {
	const newString = string.trim().replace(/\s/g, character);
	return newString;
};

export const sanitizeYouTubeURL = (url) => {
	let videoID = '';
	var pattern = /^(http(s)??:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+$/gm;
	var res = pattern.test(url);
	if (res) {
		if (url.includes('www.youtube.com/watch')) {
			const splitURL = url.split('=');
			videoID = splitURL[splitURL.length - 1];
		} else if (url.includes('youtu.be') || url.includes('www.youtube.com/embed/')) {
			const splitURL = url.split('/');
			videoID = splitURL[splitURL.length - 1];
		}
	}
	return videoID;
};

export const extractLinkWithoutHash = (url) => {
	let link = url;
	if (!isEmpty(url) && url.includes('#')) {
		const splitString = url.split('#');
		if (splitString.length === 2) {
			link = splitString[0];
		}
	}
	return link;
};

export const extractHashFromLink = (url) => {
	let hash = '';
	if (!isEmpty(url) && url.includes('#')) {
		const splitString = url.split('#');
		if (splitString.length === 2) {
			hash = splitString[1];
		}
	}
	return hash;
};

export const parseQueryString = (queryString) => {
	const query = {};
	const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&').filter((el) => !isEmpty(el));
	if (!isEmpty(pairs)) {
		for (let i = 0; i < pairs.length; i++) {
			const pair = pairs[i].split('=');
			query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
		}
	}
	return query;
};

/****************************************************************************************************
 * MATH
 ****************************************************************************************************/
export const round = (value, decimals) => {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const calculateAverage = (arr) => {
	const sum = arr.reduce((a, b) => a + b, 0);
	const avg = sum / arr.length || 0;
	return avg;
};

export const renderNumber = (num) => {
	switch (num) {
		case 1:
			return <Looks1 className="section-number" />;
		case 2:
			return <Looks2 className="section-number" />;
		case 3:
			return <Looks3 className="section-number" />;
		case 4:
			return <Looks4 className="section-number" />;
		case 5:
			return <Looks5 className="section-number" />;
		case 6:
			return <Looks6 className="section-number" />;
		case 7:
			return <StarIcon className="section-number" />;
		default:
			return <div className="section-number">{num}</div>;
	}
};

export const renderNumberFilter = (num) => {
	switch (num) {
		case 1:
			return <Filter1 className="section-number" />;
		case 2:
			return <Filter2 className="section-number" />;
		case 3:
			return <Filter3 className="section-number" />;
		case 4:
			return <Filter4 className="section-number" />;
		case 5:
			return <Filter5 className="section-number" />;
		case 6:
			return <Filter6 className="section-number" />;
		case 7:
			return <Filter7 className="section-number" />;
		case 8:
			return <Filter8 className="section-number" />;
		case 9:
			return <Filter9 className="section-number" />;
		default:
			return <div className="section-number">{num}</div>;
	}
};

/****************************************************************************************************
 * TIME
 ****************************************************************************************************/
export const convertFromMilliseconds = (milliseconds, to) => {
	let duration = milliseconds;
	if (to === 'hours') duration = milliseconds / (60 * 60 * 1000);
	if (to === 'minutes') duration = milliseconds / (60 * 1000);
	if (to === 'seconds') duration = milliseconds / 1000;
	return round(duration, 2);
};

export const convertToMilliseconds = (duration, from) => {
	if (from === 'hours') duration = duration * 60 * 60 * 1000;
	if (from === 'minutes') duration = duration * 60 * 1000;
	if (from === 'seconds') duration = duration * 1000;
	return duration;
};

export const getFullTime = (date) => {
	const currTime = new Date(date);
	let AM_PM;
	let currHour = currTime.getHours();
	let currMinutes = currTime.getMinutes();

	if (currHour === 0) {
		currHour = '12';
		AM_PM = 'AM';
	} else if (currHour === 12) {
		currHour = '12';
		AM_PM = 'PM';
	} else if (currHour >= 13 && currHour <= 23) {
		currHour = (currHour - 12).toString();
		AM_PM = 'PM';
	} else {
		currHour = currHour.toString();
		AM_PM = 'AM';
	}

	if (currMinutes >= 0 && currMinutes <= 9) {
		currMinutes = '0' + currMinutes.toString();
	} else {
		currMinutes = currMinutes.toString();
	}

	return `${currHour}:${currMinutes} ${AM_PM}`;
};

export const convertTimeFieldToMinutes = (timeField) => {
	return parseInt(timeField.substring(0, 2)) * 60 + parseInt(timeField.substring(3, 5));
};

export const convertMinutesToTimeString = (dayOfWeek, minutesFromMidnight, timezone, format) => {
	// day-am-pm: EEEE hh:mm a || am-pm: hh:mm a || 24h: HH:mm
	let dt = DateTime.local({ zone: timezone })
		.startOf('week') // Monday
		.plus({ days: dayOfWeek - 1, minutes: minutesFromMidnight });
	dt = dt.toLocal().toFormat(format || 'HH:mm');

	return dt;
};

/****************************************************************************************************
 * DATES
 ****************************************************************************************************/
export const getFullDate = (date) => {
	const currDate = new Date(date);
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const dayOfMonth = currDate.getDate();
	let day;

	if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
		day = dayOfMonth + 'st';
	} else if (dayOfMonth === 2 || dayOfMonth === 22) {
		day = dayOfMonth + 'nd';
	} else if (dayOfMonth === 3 || dayOfMonth === 23) {
		day = dayOfMonth + 'rd';
	} else {
		day = dayOfMonth + 'th';
	}

	return `${days[currDate.getDay()]}, ${day} of ${months[currDate.getMonth()]} ${currDate.getFullYear()}`;
};

export const getShortDate = (date) => {
	const currDate = new Date(date);
	const currYear = currDate.getFullYear().toString();
	const splitYear = currYear.split('');

	return `${currDate.getDate()}/${currDate.getMonth() + 1}/${splitYear[splitYear[splitYear.length - 2]]}${splitYear[splitYear.length - 1]}`;
};

export function daysBetweenDates(startDate, endDate) {
	// Step 1: Convert the strings into date objects
	const codeUnderstandingStartDate = new Date(startDate);
	const codeUnderstandingEndDate = new Date(endDate);
	// Step 2: Create a variable for the start / end days (1 - 31) and months (0 - 11)
	let startMonth = codeUnderstandingStartDate.getMonth();
	let startDay = codeUnderstandingStartDate.getDate();
	let startYear = codeUnderstandingStartDate.getYear();
	let endMonth = codeUnderstandingEndDate.getMonth();
	let endDay = codeUnderstandingEndDate.getDate();
	let endYear = codeUnderstandingEndDate.getYear();

	// Flip the start and end month ONLY when it is the SAME year
	if (startMonth > endMonth) {
		startMonth = codeUnderstandingEndDate.getMonth();
		endMonth = codeUnderstandingStartDate.getMonth();
	}
	// Step 3: Initialised the number of days across the months of the year
	const numberOfDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	const daysInOneYear = 365;
	// Step 4: Initialised the count for the total number of days
	let totalNumberOfYears = endYear - startYear;
	let totalNumberOfDays = totalNumberOfYears * daysInOneYear;
	// Step 5: Loop Between the months starting from startingMonth and ending the month before the endingMonth
	for (let i = startMonth; i < endMonth; i++) {
		let currentNumberOfDays = numberOfDays[i];
		if (startYear < endYear) {
			totalNumberOfDays = totalNumberOfDays - currentNumberOfDays;
		} else {
			totalNumberOfDays = totalNumberOfDays + currentNumberOfDays;
		}
	}
	// Step 4: Subtract the days in the startDate from the total and add end days to the total
	totalNumberOfDays = totalNumberOfDays - startDay + endDay;
	// Step 6: Return the total
	return totalNumberOfDays;
}

export const isToday = (date) => {
	const today = new Date();
	const dateToCheck = new Date(date);
	return dateToCheck.getDate() === today.getDate() && dateToCheck.getMonth() === today.getMonth() && dateToCheck.getFullYear() === today.getFullYear();
};

/****************************************************************************************************
 * SORTING
 ****************************************************************************************************/
export const naturalSort = (arr, key) => {
	if (arr.length < 2) return arr;
	const sorted = arr.sort((a, b) => {
		const aValue = isEmpty(a[key]) ? '' : a[key].toString();
		const bValue = isEmpty(b[key]) ? '' : b[key].toString();
		return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
	});
	return sorted;
};

export const sortByAlphabet = (arr, key) => {
	if (arr.length < 2) return arr;
	const sorted = arr.sort((a, b) => {
		const aValue = isEmpty(a[key]) ? '' : a[key].toString().toLowerCase();
		const bValue = isEmpty(b[key]) ? '' : b[key].toString().toLowerCase();
		if (aValue < bValue) return -1;
		if (aValue > bValue) return 1;
		return 0;
	});
	return sorted;
};

/****************************************************************************************************
 * IMAGE / FILE PROCESSING
 ****************************************************************************************************/
export const mimeTypes = {
	image: 'image/*',
	pdf: 'application/pdf',
	doc: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
};

export const dataURIToBlob = (dataURI) => {
	const splitDataURI = dataURI.split(',');
	const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
	const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
	return new Blob([ia], { type: mimeString });
};

/****************************************************************************************************
 * COLOUR PROCESSING
 ****************************************************************************************************/
export const hexOpacities = {
	100: 'FF',
	99: 'FC',
	98: 'FA',
	97: 'F7',
	96: 'F5',
	95: 'F2',
	94: 'F0',
	93: 'ED',
	92: 'EB',
	91: 'E8',
	90: 'E6',
	89: 'E3',
	88: 'E0',
	87: 'DE',
	86: 'DB',
	85: 'D9',
	84: 'D6',
	83: 'D4',
	82: 'D1',
	81: 'CF',
	80: 'CC',
	79: 'C9',
	78: 'C7',
	77: 'C4',
	76: 'C2',
	75: 'BF',
	74: 'BD',
	73: 'BA',
	72: 'B8',
	71: 'B5',
	70: 'B3',
	69: 'B0',
	68: 'AD',
	67: 'AB',
	66: 'A8',
	65: 'A6',
	64: 'A3',
	63: 'A1',
	62: '9E',
	61: '9C',
	60: '99',
	59: '96',
	58: '94',
	57: '91',
	56: '8F',
	55: '8C',
	54: '8A',
	53: '87',
	52: '85',
	51: '82',
	50: '80',
	49: '7D',
	48: '7A',
	47: '78',
	46: '75',
	45: '73',
	44: '70',
	43: '6E',
	42: '6B',
	41: '69',
	40: '66',
	39: '63',
	38: '61',
	37: '5E',
	36: '5C',
	35: '59',
	34: '57',
	33: '54',
	32: '52',
	31: '4F',
	30: '4D',
	29: '4A',
	28: '47',
	27: '45',
	26: '42',
	25: '40',
	24: '3D',
	23: '3B',
	22: '38',
	21: '36',
	20: '33',
	19: '30',
	18: '2E',
	17: '2B',
	16: '29',
	15: '26',
	14: '24',
	13: '21',
	12: '1F',
	11: '1C',
	10: '1A',
	9: '17',
	8: '14',
	7: '12',
	6: '0F',
	5: '0D',
	4: '0A',
	3: '08',
	2: '05',
	1: '03',
	0: '00',
};

// https://math.stackexchange.com/questions/1965497/how-can-i-find-a-random-position-between-two-points
// https://stackoverflow.com/questions/52890389/is-it-possible-to-choose-a-random-color-from-a-gradient-or-a-range-between-two-c
export const interpolate = (c1, c2) => {
	const u = Math.random(); // A value between 0 - 1
	const newValues = c1.map((a, i) => Math.floor((1 - u) * a + u * c2[i]));
	return `rgb(${newValues.join(',')})`;
};
