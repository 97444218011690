/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box, Typography } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
/**************************************************************************************************** 
 * SUPPORTING FUNCTIONS
****************************************************************************************************/
const calcColor = (percent) => {
    if (percent < 50) {
        // Fail
        return '#f94144';
    } else if (percent >= 50 && percent < 65) {
        // Pass
        return '#f8961e';
    } else if (percent >= 65 && percent < 75) {
        // Credit
        return '#f9c74f';
    } else if (percent >= 75 && percent < 85) {
        // Distinction
        return '#90be6d';
    } else {
        // High Distinction
        return '#43aa8b';
    }
}
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
LoaderProgress.propTypes = {
    value: PropTypes.number.isRequired,
    white: PropTypes.bool
}
LoaderProgress.defaultProps = {
    white: true,
    sx: {},
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
function LoaderProgress({ white, value, sx }) {
    return (
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', ...sx }}>
            <CircularProgress
                className="empty-progress-circle"
                variant="determinate"
                sx={{ color: white ? 'rgba(255,255,255,.7)' : 'text.grey', }}
                size={40}
                thickness={4}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                sx={{
                    color: calcColor(value),
                    animationDuration: '550ms',
                    position: 'absolute',
                    // left: 0,
                    [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round', },
                }}
                value={value}
                size={40}
                thickness={4}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography className="progress-text" variant="caption" component="div" color="text.secondary" sx={{ color: white ? '#FFF' : 'text.grey', fontWeight: 600, fontFamily: '"Poppins", sans-serif' }}>
                    {`${value}%`}
                </Typography>
            </Box>
        </Box>
    );
}

export default LoaderProgress;