/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, IconButton } from '@mui/material';
import { Facebook, Instagram, Twitter, LinkedIn, YouTube } from '@mui/icons-material';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
const Sx = {
	socialContainer: {
		'& .social-icon': {
			margin: '5px',
			width: '60px',
			height: '60px',
			'& .MuiSvgIcon-root': { width: '1.2em', height: '1.2em' },
		},
	},
};
/****************************************************************************************************
 * DATA
 ****************************************************************************************************/
const Links = {
	facebook: 'https://www.facebook.com/Learnblock/',
	instagram: 'https://www.instagram.com/learnblock/',
	twitter: 'https://twitter.com/learnblock',
	linkedIn: '',
	youtube: 'https://www.youtube.com/@learnblock',
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function SocialMediaLinks() {
	return (
		<Box sx={Sx.socialContainer}>
			{!isEmpty(Links.facebook) && (
				<IconButton className="social-icon" href={Links.facebook} target="_blank">
					<Facebook />
				</IconButton>
			)}
			{!isEmpty(Links.instagram) && (
				<IconButton className="social-icon" href={Links.instagram} target="_blank">
					<Instagram />
				</IconButton>
			)}
			{!isEmpty(Links.twitter) && (
				<IconButton className="social-icon" href={Links.twitter} target="_blank">
					<Twitter />
				</IconButton>
			)}
			{!isEmpty(Links.linkedIn) && (
				<IconButton className="social-icon" href={Links.linkedIn} target="_blank">
					<LinkedIn />
				</IconButton>
			)}
			{!isEmpty(Links.youtube) && (
				<IconButton className="social-icon" href={Links.youtube} target="_blank">
					<YouTube />
				</IconButton>
			)}
		</Box>
	);
}
