/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
import { ErrorAlert } from '../../@core/Alert';
import styles from './styles';
import { LoginForm } from '../../auth/forms';
/****************************************************************************************************
 * PROPS
 ****************************************************************************************************/
ErrorMessage.propTypes = {
	errorCode: PropTypes.string,
	errorMessage: PropTypes.string,
	sx: PropTypes.object,
};
ErrorMessage.defaultProps = {
	sx: {},
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function ErrorMessage({ errorCode, errorMessage, sx, className, children }) {
	/* 
        ERROR CODES TO POTENTIALLY HANDLE:
        1. auth/loginUser
    */

	return (
		<Box className={className} sx={{ ...styles.errorMessage, ...sx }}>
			<ErrorAlert title={`Error - ${errorCode}`} message={errorMessage} />
			{children}
			{(errorCode === 'auth/no-token' || errorCode === 'auth/token-expired' || errorCode === 'auth/invalid' || errorCode === 'auth/error') && (
				<Box sx={{ '& #login-form': { borderRadius: '10px' } }}>
					<LoginForm withBanner={true} title="Login to access this page." forceRefresh={true} />
				</Box>
			)}
		</Box>
	);
}
