/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../../auth';
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
import CodeEditorBasic from '../../CodeEditorBasic';
import MarkdownRender from '../../MarkdownRender';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function QuestionHint({ question, setQuestion, editMode, rerender, displayHint }) {
	const { isAdmin } = useAuth();
	const { hint } = question;

	return (
		<Box id="question__stem">
			{editMode === false && !isEmpty(hint) && displayHint === true && (
				<Box sx={styles.emphasiseBubble}>
					<Box sx={styles.miniTitle}>Hint</Box>
					<MarkdownRender children={hint} />
				</Box>
			)}
			{editMode === true && isAdmin && (
				<Box className="edit-content">
					<Box className="edit-content__box">
						<Box sx={styles.miniTitle}>Question Hint</Box>
						{!isEmpty(hint) && <MarkdownRender children={hint} />}
						<CodeEditorBasic
							rerender={rerender}
							bucket="questions"
							language="markdown"
							initialCode={hint}
							onChange={(value) =>
								setQuestion((prev) => ({
									...prev,
									hint: value,
								}))
							}
							style={{ marginTop: '20px' }}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
}
