/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../auth';
import { isEmpty } from '../../utils';
import { LoginRegisterBtns } from '../../auth/elements';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { LogoIconTextTitle } from '../../@core/Logo';
import { HomeFooter } from '../../components/Footers';
import { BlockSelector } from '../../components/Blocks';
import { AnimationParticles } from '../../@core/Animation';
import { NoDataMessage } from '../../components/Messages';
import styles from './styles';
import { TutorsList } from '../../components/Tutors';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
// const ButtonWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'white' })(({ theme, white }) => ({
// 	textAlign: 'center',
// 	margin: '40px auto 0px',
// 	flexShrink: 0,
// 	'& .MuiButton-root': { margin: '10px 0' },
// 	'& .or-text': { display: 'inline', letterSpacing: '1px', textTransform: 'uppercase', margin: '20px', color: white ? '#FFF' : theme.palette.primary.main, fontWeight: 500, [theme.breakpoints.down('md')]: { display: 'block' } },
// 	'& .bounce': { color: white ? '#FFF' : theme.palette.primary.main, animation: 'bounce 2s infinite ease-in-out', fontSize: 60, display: 'block', margin: '10px auto 20px' },
// 	'@keyframes bounce': {
// 		'0%': { transform: 'translateY(-5px)' },
// 		'50%': { transform: 'translateY(10px)' },
// 		'100%': { transform: 'translateY(-5px)' },
// 	},
// }));
// <ButtonWrapper white={true}>
//     <div className="or-text">Or, See Our Student Success Stories</div>
//     <ExpandMoreIcon className="bounce" />
//     <Box>
//         <Button variant="contained" sx={{ border: 'none', backgroundColor: 'rgba(0,0,0,.3)', color: '#FFF' }}>
//             Student Showcase
//         </Button>
//     </Box>
// </ButtonWrapper>
export default function Home() {
	const { appUser } = useAuth();
	const [option, setOption] = React.useState('questions'); // questions, tutors, workshops

	return (
		<Box sx={styles.homeContainer}>
			<Box>
				<AnimationParticles />
				<Box className="home-banner">
					<Box className="login-register-container">
						<LoginRegisterBtns
							sx={{
								boxShadow: 'none',
								'& .MuiButtonGroup-grouped:not(:last-of-type)': {
									borderWidth: '2px',
									borderColor: '#FFF',
									borderRightStyle: 'dashed',
									border: 'none',
								},
								'& .MuiButton-root': {
									paddingX: '15px',
									backgroundColor: 'transparent',
									borderRadius: '0px',
								},
							}}
						/>
					</Box>
					<LogoIconTextTitle white={true} size="small" />
					<Typography className="main-title heading-font" variant="h3">
						{isEmpty(appUser) ? 'Study Smarter With' : `Welcome Back ${appUser.name.split(' ')[0]}`}
					</Typography>
					<ButtonGroup
						size="small"
						sx={{
							'& .MuiButton-root': {
								height: '50px',
								paddingX: '15px',
							},
							'& .MuiButtonGroup-grouped:not(:last-of-type):hover': { borderRightColor: 'transparent' },
						}}
					>
						<Button
							sx={{
								paddingLeft: '20px !important',
								width: '120px',
							}}
							color="white"
							variant={option === 'questions' ? 'contained' : 'outlined'}
							onClick={() => setOption('questions')}
						>
							Questions
						</Button>
						<Button
							sx={{
								paddingRight: '20px !important',
								width: '120px',
							}}
							color="white"
							variant={option === 'tutors' ? 'contained' : 'outlined'}
							onClick={() => setOption('tutors')}
						>
							Tutors
						</Button>
					</ButtonGroup>
					<Typography color="white.main" className="heading-font banner-blurb" variant="h5">
						{option === 'questions' && 'Using the most advanced question banks ever created - designed to maximise results in days.'}
						{option === 'tutors' && 'Select from the best tutors in the world - hand picked to deliver the best education.'}
					</Typography>
					{option === 'questions' && <BlockSelector sx={{ width: '100%' }} />}
					{option === 'tutors' && <TutorsList />}
				</Box>
			</Box>
			<HomeFooter white={true} sx={{ backgroundColor: 'rgba(0,0,0,.3)' }} />
		</Box>
	);
}
