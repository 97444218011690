const styles = {
	questionPathMetaData: {
		backgroundColor: 'primary.main',
		padding: '10px',
		paddingTop: '0px',
		paddingRight: '5px',
		color: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexShrink: 0,
		flexWrap: 'wrap',
		'& .close-icon': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '32px',
			width: '32px',
			color: 'rgba(255,255,255,.7)',
			cursor: 'pointer',
			flexShrink: 0,
			backgroundColor: 'secondary.main',
			marginRight: '10px',
			'&:hover': {
				filter: 'brightness(130%)',
			},
		},
		'& .path-name': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			padding: '10px',
		},
		'& .tags': {
			flexGrow: 1,
			textAlign: 'right',
			'& .tag': {
				margin: '5px',
				marginLeft: '0px',
			},
			// '& .tag': {
			// 	margin: '0px',
			// 	borderRadius: '0px',
			// },
		},
	},
	componentContainer: {
		width: '100%',
		margin: 'auto',
		'& .question-performance-status': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
	},
	questionScrapPaper: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(255,255,255,.5)',
		zIndex: 99,
	},
	gridItemElement: {
		boxShadow: '0 0 35px rgb(0 0 0 / 10%)',
		position: 'relative',
		borderRadius: '10px',
		backgroundColor: '#FFF',
		marginBottom: '40px',
		'& .grid-item-element-banner': {
			backgroundColor: 'primary.main',
			color: '#FFF',
			// borderBottom: '2px solid rgba(0,0,0,.08)',
			padding: '10px 15px',
			borderRadius: '10px 10px 0 0',
			'& .edit-btn': {
				color: '#FFF',
				marginRight: '5px',
				'& .MuiSvgIcon-root': { fontSize: '1em' },
			},
			'& .title-text': { marginRight: '5px', fontWeight: 600 },
		},
		'& .grid-item-element-section': {
			// padding: { xs: '20px', sm: '40px' },
			'& .section-title': {
				marginBottom: '15px',
				fontWeight: 500,
				color: 'primary.main',
			},
			'& .section-message': {
				color: 'text.grey',
			},
		},
		'& .empty-solution-message': {
			padding: '30px 40px 40px',
			textAlign: 'center',
			'& .section-message': {
				color: 'text.grey',
			},
		},
	},
	loginRegisterNow: {},
	miniTitle: {
		marginBottom: '10px',
		color: 'primary.main',
		fontWeight: 600,
		textTransform: 'uppercase',
		letterSpacing: '1px',
		fontSize: '12px',
	},
	emphasiseBubble: {
		padding: '20px',
		border: '2px solid rgba(0,0,0,.08)',
		marginTop: '20px',
		borderRadius: '10px',
		boxShadow: '0 0 35px rgb(0 0 0 / 10%)',
	},
	overallSolutionText: {
		padding: '20px',
		backgroundColor: 'rgba(0,0,0,.04)',
		borderRadius: '10px',
		margin: '20px 0 10px',
	},
	mcOptionsUser: {
		marginTop: '25px',
		marginBottom: '20px',
		borderRadius: '10px',
		'& .mc-option': {
			boxShadow: 'none',
			border: '3px solid rgba(0,0,0,.08)',
			borderRadius: '10px',
			margin: '7px',
			'&:first-of-type': { borderRadius: '10px' },
			'&:last-of-type': { borderRadius: '10px' },
			'&:before': { display: 'none' },
			'&:hover': {
				backgroundColor: 'rgba(0,0,0,.08)',
				'& .option-content': { borderRadius: '8px' },
			},
			'& .option-content': {
				display: 'flex',
				justifyContent: 'space-between',
				'& .MuiAccordionSummary-content': { margin: '0px' },
				'& .option-content__main': {
					display: 'flex',
					alignItems: 'center',
					flexGrow: 1,
					'& .option-content__num': {
						width: '32px',
						height: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'primary.main',
						color: '#FFF',
						fontWeight: 500,
						borderRadius: '50%',
						marginRight: '10px',
						flexShrink: 0,
					},
				},
				'& .option-content__action-btn': { marginLeft: '5px' },
			},
			'& .option-solution': {
				color: '#000',
				padding: '20px',
				borderTop: '2px solid rgba(0,0,0,.08)',
				borderRadius: '0px 0px 8px 8px',
				'& .option-solution__title': { marginBottom: '10px' },
			},
			'&.hiddenOption': {
				'&:hover': { backgroundColor: '#FFF' },
				'& .option-content__main': { opacity: '0.15' },
			},
			'&.selectedOption': {
				backgroundColor: 'primary.main',
				color: '#FFF',
				'&:hover': { backgroundColor: 'primary.main' },
				'& .option-content': {
					'& .option-content__main': {
						'& .option-content__num': { backgroundColor: '#FFF', color: '#000' },
						'& .option-content__text': { color: '#FFF' },
					},
					'& .option-content__action-btn': { color: '#FFF' },
				},
				'& .option-solution': {
					borderTop: 'none',
				},
			},
			'&.correctOption': {
				backgroundColor: 'success.main',
				color: '#FFF',
				'&:hover': { backgroundColor: 'success.main' },
				'& .option-content': {
					'& .option-content__main': {
						'& .option-content__num': {
							border: '2px solid #FFF',
							backgroundColor: 'rgba(255,255,255,.2)',
							color: '#FFF',
							'&.selected': { backgroundColor: '#FFF', color: 'primary.main' },
						},
						'& .option-content__text': { color: '#FFF !important' },
					},
					'& .option-content__action-btn': { color: '#FFF' },
				},
				'& .option-solution': {
					backgroundColor: 'success.light',
					borderTop: 'none',
					'& .option-solution__title': { color: '#FFF' },
					'& .markdown-body': { color: '#FFF !important' },
				},
			},
			'&.incorrectOption': {
				backgroundColor: 'error.main',
				color: '#FFF',
				'&:hover': { backgroundColor: 'error.main' },
				'& .option-content': {
					'& .option-content__main': {
						'& .option-content__num': {
							border: '2px solid #FFF',
							backgroundColor: 'rgba(255,255,255,.2)',
							color: '#FFF',
							'&.selected': { backgroundColor: '#FFF', color: 'primary.main' },
						},
					},
					'& .option-content__expand-btn': { color: '#FFF' },
				},
				'& .option-solution': {
					backgroundColor: 'error.light',
					borderTop: 'none',
					'& .option-solution__title': { color: '#FFF' },
					'& .markdown-body': { color: '#FFF !important' },
				},
			},
			'&.checkedAnswer': {
				'&:not(.incorrectOption, .correctOption, .selectedOption):hover': { backgroundColor: '#FFF' },
			},
			'&.adminViewMode': {
				'&:hover': {
					backgroundColor: '#FFF',
				},
				'&.correctOption:hover': { backgroundColor: 'success.main' },
				'& .option-content': { cursor: 'default' },
			},
		},
	},
	mcOptionsAdmin: {
		'& .mc-option-admin': {
			boxShadow: '0 0 35px rgb(0 0 0 / 10%)',
			borderRadius: '10px',
			margin: '20px 0',
			border: '2px solid rgba(0,0,0,.08)',
			'& .mc-option-admin__option-marks': {
				marginLeft: '5px',
				width: '100px',
				'& .MuiInputLabel-root': {
					color: '#FFF',
					top: '-8px',
					'&.MuiInputLabel-shrink': { top: '0px' },
				},
				'& .MuiOutlinedInput-root': {
					color: '#FFF',
					borderRadius: '40px',
					'&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#FFF' },
				},
				'& .MuiOutlinedInput-input': { padding: '10px 20px' },
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#FFF',
					borderWidth: '2px',
				},
			},
			'& .mc-option-admin__controls': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				padding: '10px 20px',
				borderBottom: '2px solid rgba(0,0,0,.08)',
				backgroundColor: 'primary.main',
				borderRadius: '10px 10px 0 0',
				'& .mc-option-admin__option-num': {
					display: 'flex',
					alignItems: 'center',
					'& .mc-option-admin__option-num__number': {
						width: '32px',
						height: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'primary.main',
						color: '#FFF',
						fontWeight: 500,
						borderRadius: '50%',
						marginRight: '5px',
						border: '2px solid #FFF',
					},
					'& .mc-option-admin__option-num__checkbox': {
						color: '#FFF',
					},
				},
				'& .mc-option-admin__option-controls': {
					'& .MuiIconButton-root': { color: '#FFF', '&.Mui-disabled': { color: 'rgba(255,255,255,.3)' } },
				},
			},
			'& .mc-option-admin__question': {
				padding: '20px',
				borderBottom: '2px solid rgba(0,0,0,.08)',
			},
			' & .mc-option-admin__solution': {
				padding: '20px',
				backgroundColor: '#e7e7e7',
				borderRadius: '0px 0px 8px 8px',
			},
			'&.correctAnswer': {
				'& .mc-option-admin__controls': {
					backgroundColor: 'success.main',
					color: '#FFF',
					borderRadius: '10px 10px 0 0',
					'& .mc-option-admin__option-num__number': {
						backgroundColor: '#FFF',
						color: 'primary.main',
					},
					'& .MuiCheckbox-root': { color: '#FFF' },
				},
				'& .mc-option-admin__solution': {
					backgroundColor: 'success.main',
					'& .section-title': { color: '#FFF' },
					'& .markdown-body': { color: '#FFF' },
					'& .MuiIconButton-root': { color: '#FFF' },
				},
			},
		},
	},
	questionParts: {
		'& .part': {
			margin: '20px 0px',
			paddingBottom: '20px',
			borderBottom: '2px solid rgba(0,0,0,.08)',
			'&:last-of-type': { borderBottom: 'none', marginBottom: '0px', paddingBottom: '0px' },
			'& .part-content': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				'& .part-main-content': {
					display: 'flex',
					alignItems: 'center',
					flexGrow: 1,
					'& .part-content__content': {
						flexGrow: 1,
						marginRight: '15px',
						'& .part-content__question-text': {
							display: 'flex',
							alignItems: 'center',
							'& .part-content__num': {
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								flexShrink: 0,
								width: '24px',
								height: '24px',
								marginRight: '10px',
								fontSize: '12px',
								borderRadius: '50%',
								backgroundColor: 'primary.main',
								color: '#FFF',
								fontWeight: 600,
							},
							'& .markdown-body': {
								flexGrow: 1,
							},
						},
						'& .part-content__show-solution-btn': { margin: '15px 0 10px', fontSize: '12px' },
						'& .part-content__solution-text': { padding: '20px', backgroundColor: 'rgba(0,0,0,.04)', borderRadius: '10px', margin: '20px 0 10px' },
					},
				},
				'& .part-content__marks': {
					fontSize: '10px',
					margin: '10px 0px',
					'& .MuiChip-icon': {
						fontSize: '14px',
					},
				},
			},
		},
	},
	questionOptions: {
		'& .question-option': {
			display: 'flex',
			overflow: 'auto',
			borderTop: '2px solid rgba(0,0,0,.08)',
			'& .question-option__type': {
				padding: '15px',
				flexShrink: 0,
				width: '120px',
				fontWeight: 600,
				color: 'primary.main',
				'&.with-error': {
					backgroundColor: 'error.main',
					color: '#FFF',
				},
				'& .tag-name': {
					display: 'flex',
					alignItems: 'center',
					height: '100%',
				},
			},
			'& .question-option__selection': {
				borderLeft: '2px solid rgba(0,0,0,.08)',
				padding: '10px',
				minHeight: '66px',
				display: 'flex',
				alignItems: 'center',
				flexWrap: 'wrap',
				'&.full-width': { width: '100%' },
				'& .question-option__selection__instruction-text': { fontWeight: 600, color: 'secondary.main', flex: '0 0 100%' },
				'& .text-input': {
					'& .MuiOutlinedInput-root': { borderRadius: '56px' },
					'& .MuiOutlinedInput-notchedOutline': { borderWidth: '2px' },
					'& .MuiFormHelperText-root': { marginLeft: '15px' },
				},
			},
			'&:last-of-type': { borderBottom: 'none' },
		},
	},
	userPerformanceMetrics: {
		margin: '20px 0px',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		'& .metric-summary': {
			margin: 0,
			'&:first-of-type': { xs: { marginBottom: '20px' }, sm: { marginRight: '10px' } },
			'&:last-of-type': { xs: { marginBottom: '20px' }, sm: { marginLeft: '10px' } },
			'&:only-of-type': { margin: '0px', marginBottom: '20px' },
			width: { xs: '100%', sm: 'calc(50% - 10px)' },
			padding: '20px',
			borderRadius: '10px',
			'& .metric-name': {
				marginBottom: '10px',
				fontWeight: 600,
				color: 'primary.main',
			},
		},
	},
	controls: {
		// '& .control-btn': {
		// 	boxShadow: 'none',
		// 	height: '40px',
		// 	px: '20px',
		// 	borderRadius: '40px',
		// 	margin: '5px',
		// 	borderColor: 'primary.main',
		// },
		'&.user-controls': {
			margin: '20px 0px',
			'& .control-btn': {
				height: '40px',
				px: '20px',
				borderRadius: '40px',
				// color: '#FFF',
				// backgroundColor: 'rgba(255,255,255,.3)',
				'&:hover, &.active': {
					backgroundColor: 'secondary.main',
					color: '#FFF',
					borderColor: 'secondary.main',
				},
				'&.flag': {
					'&.active': {
						backgroundColor: 'warning.main',
						color: '#FFF',
						borderColor: 'warning.main',
					},
					'&:hover': {
						borderColor: 'warning.main',
						backgroundColor: 'warning.main',
						color: '#FFF',
					},
				},
				'&.Mui-disabled': {
					// backgroundColor: 'rgba(255,255,255,.15)',
					// color: 'rgba(255,255,255,.3)',
				},
			},
		},
		'& .answer-controls': {
			borderTop: '2px solid rgba(0,0,0,.08)',
			padding: '10px 15px',
		},
	},
	errorWidget: {
		backgroundColor: 'error.main',
		padding: '20px',
		margin: '30px 0px',
		borderRadius: '10px',
		'& .errors-title': { color: '#FFF', fontWeight: 600, marginBottom: '10px' },
		'& .error-item': {
			borderBottom: '2px dashed rgba(255,255,255,.7)',
			display: 'flex',
			alignItems: 'center',
			paddingBottom: '10px',
			marginBottom: '10px',
			'& .error-item__number': { width: '24px', height: '24px', marginRight: '10px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px', backgroundColor: '#FFF', color: 'primary.main' },
			'& .error-item__element-type': { color: '#FFF', fontWeight: 600, marginRight: '5px' },
			'& .error-item__text': { color: '#FFF', fontWeight: 600 },
			'&:last-of-type': { borderBottom: 'none', paddingBottom: '0px', marginBottom: '0px' },
		},
	},
	topicsList: {
		overflow: 'auto',
		padding: '40px',
		position: 'relative',
		'& .generate-questions-btn': {
			position: 'fixed',
			zIndex: 999,
			marginBottom: 0,
			bottom: 20,
			right: 10,
		},
		'& .ReactVirtualized__Grid__innerScrollContainer': { marginTop: '0px', marginLeft: '10px' },
		'& .ReactVirtualized__Grid::-webkit-scrollbar': { background: 'none', width: '8px', height: '9px' },
		'& .ReactVirtualized__Grid::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main' },
		'& .rst__moveHandle': { borderRadius: '50%', width: '50px', height: '50px', backgroundColor: 'primary.main', border: 'none', position: 'relative', marginRight: '12px' },
		'& .rst__moveHandle::after': { position: 'absolute', content: "''", backgroundColor: 'primary.main', height: '4px', width: '12px', top: '50%', right: '-12px' },
		'& .rst__row': { cursor: 'pointer' },
		'& .rst__row .rst__rowContents': {
			borderRadius: '20px',
			paddingLeft: '0px',
			background: 'none',
			boxShadow: 'none',
			border: 'none',
			'& .node-title': {
				fontFamily: '"Poppins", sans-serif',
				color: 'primary.main',
				borderRadius: '40px',
				height: '56px',
				padding: '7px 20px',
				fontSize: '16px',
				letterSpacing: '1px',
				textTransform: 'uppercase',
				'& .text': { marginRight: '10px' },
				'& .MuiCheckbox-root, & .MuiIconButton-root': { color: 'inherit' },
				'& .control-btn': {
					margin: '5px',
					'&.view-content-btn': {
						'&.Mui-disabled': { opacity: '0.26' },
					},
				},
				'&:hover': {
					backgroundColor: 'primary.main',
					color: '#FFF',
				},
			},
		},
		'& .rst__row.notAdmin .rst__rowContents': {
			'& .node-title': { padding: '10px 15px' },
		},
		'& .rst__row.selected .rst__rowContents': {
			'& .node-title': {
				backgroundColor: 'primary.main',
				color: '#FFF',
				'& .empty-progress-circle': { color: '#FFF' },
				'& .progress-text': { color: '#FFF' },
				'& .view-content-btn': { color: '#FFF' },
			},
		},
		'& .rst__collapseButton, & .rst__expandButton': { width: '20px', height: '20px' },
		'& .rst__node:only-child .rst__lineHalfHorizontalRight::before': { background: 'none' },
		'& .rst__lineHalfHorizontalRight::before': { backgroundColor: 'primary.main', height: '4px' },
		'& .rst__lineFullVertical::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineHalfVerticalTop::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineHalfVerticalBottom::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__lineChildren::after': { backgroundColor: 'primary.main', width: '4px' },
		'& .rst__rowSearchFocus': { outline: 'none !important', backgroundColor: 'primary.main', borderRadius: 20 },
		'&.compact-tree': {
			height: 'calc(100vh - 101px)',
			'& .rst__moveHandle, .rst__loadingHandle': { width: 32, margin: 0, borderRadius: 0, boxShadow: 'none', border: 'none' },
			'& .rst__row .rst__rowContents::before': { position: 'absolute', content: "''", backgroundColor: 'primary.main', height: 1, left: 0, width: '12px', top: '50%' },
			'& .rst__rowWrapper': { padding: '4px 0' },
			'& .rst__row.active .rst__rowContents, & .rst__row .rst__rowContents:hover': { paddingLeft: 10, borderRadius: 4 },
			'& .rst__row.linked .rst__rowContents': { paddingLeft: 10, borderRadius: 4, background: 'orange' },
			'& .rst__row .rst__rowContents': {
				borderRadius: 0,
				paddingLeft: 10,
				boxShadow: 'none',
				border: 'none',
				background: 'none',
				color: 'primary.main',
				'& .MuiCheckbox-root': { color: 'rgba(255,255,255,.54)', padding: 0, paddingRight: 9 },
				'&:hover': { background: 'rgba(255,255,255,.3)' },
			},
			'& .rst__toolbarButton .MuiSvgIcon-root': { fontSize: 16, color: 'rgba(255,255,255,.7)' },
		},
		'&.map-layout': {
			width: '100%',
			'& .rd3t-link': { stroke: '#FFF', strokeWidth: 2 },
			'& .map-node-box': {
				background: '#FFF',
				border: `1px solid #FFF`,
				borderRadius: 20,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				padding: '10px 10px 15px',
				margin: 10,
				'& .title': { textAlign: 'center', margin: 5 },
				'& .expand-collapse-btn': { margin: 10 },
				'&.active': { backgroundColor: 'primary.main', color: '#FFF', borderColor: 'primary.main' },
			},
		},
	},
	transcriber: {},
};

export default styles;
