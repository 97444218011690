import { supabase } from '../supabaseClient';

const TutorData = {
	getTutors: async (blockId = 0) => {
		try {
			const { data, error } = await supabase.rpc('get_active_tutors_with_courses').select();
			if (error) throw error;
			if (blockId) {
				// Show only tutors that belong to the block
				const tutors = data.filter((tutor) => {
					if (tutor.courses.findIndex((course) => course.block_id === blockId) >= 0) return true;
					return false;
				});
				return { success: true, data: tutors };
			} else {
				return { success: true, data };
			}
		} catch (err) {
			return {
				success: false,
				code: 'Get Tutors',
				message: err.message,
			};
		}
	},
	getCurrentAvailabilities: async (tutorId) => {
		try {
			const { data, error } = await supabase.from('tutor_availabilities').select();
			if (error) throw error;
			return { success: true, data };
		} catch (err) {
			return {
				success: false,
				code: 'Get Availabilities',
				message: err.message,
			};
		}
	},
	becomeATutor: async (formData) => {
		try {
			const { data, error } = await supabase.from('tutor_applicants').insert(formData);
			if (error) throw error;
			return { success: true, data };
		} catch (err) {
			return {
				success: false,
				code: 'Submit Tutor Application',
				message: err.message.includes('unique constraint') ? 'You have already submitted an application before. You cannot submit an application again. If you think this is a mistake, please contact support@learnblock.com' : err.message,
			};
		}
	},
};

export default TutorData;
