/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useTheme } from '@mui/material';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Button, Typography, Chip, TextField } from '@mui/material';
import { Send as SubmitIcon } from '@mui/icons-material';
import { PathTimeline } from '../../@core/Timeline';
import styles from './styles';
import { LogoIconText } from '../../@core/Logo';
import { isEmpty } from '../../utils';
import { LoadingButton } from '@mui/lab';
import { InitialData, TutorData } from '../../data';
import { SelectCountry } from '../../@core/Select';
import { useAuth } from '../../auth';
import { ApiErrorMessage, NoDataMessage } from '../Messages';
import { AnimationParticles } from '../../@core/Animation';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function BecomeATutor({ onSubmitApplication }) {
	const { appUser } = useAuth();
	const theme = useTheme();
	const [state, setState] = React.useState({
		profile_id: null,
		name: '',
		email: '',
		mobile: '',
		country_code: 'AU',
		postcode: '',
		bio: '',
		experience: '',
		rate: '',
	});
	const [formState, setFormState] = React.useState({ ...InitialData.completed, submitted: false });

	React.useEffect(() => {
		const initialValues = {
			profile_id: appUser.id || '',
			name: appUser.name || '',
			email: appUser.email || '',
		};
		const tutorApplication = localStorage.getItem('tutor-application');
		if (!isEmpty(tutorApplication)) {
			const parsedState = JSON.parse(tutorApplication);
			Object.assign(initialValues, parsedState);
		}
		setState((prev) => ({ ...prev, ...initialValues }));
	}, []);

	const handleChange = (field, value) => {
		const cpState = { ...state };
		cpState[field] = value;
		const stringifiedState = JSON.stringify(cpState);
		console.log(stringifiedState);
		localStorage.setItem('tutor-application', stringifiedState);
		setState(cpState);
	};

	const isDisabled = () => {
		if (isEmpty(state.name) || isEmpty(state.email) || isEmpty(state.country_code) || isEmpty(state.postcode) || isEmpty(state.mobile) || isEmpty(state.rate) || isEmpty(state.experience) || isEmpty(state.bio) || !validator.isEmail(state.email)) return true;
		return false;
	};

	const handleSubmit = async () => {
		setFormState({ ...InitialData.loading, submitted: false });
		const res = await TutorData.becomeATutor(state);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message, submitted: false });
		localStorage.removeItem('tutor-application');
		setFormState({ ...InitialData.completed, submitted: true });
	};

	return (
		<Box sx={styles.bookATutor}>
			<AnimationParticles />
			<Box className="banner">
				<LogoIconText sx={{ marginBottom: '20px' }} white={true} />
				<Typography className="heading-font" variant="h3" sx={{ marginBottom: '20px' }}>
					Become a Tutor
				</Typography>
				<Typography className="heading-font" variant="h5" sx={{ marginBottom: '20px' }}>
					Complete the application below and we'll email you back, letting you know if you've been short listed for an interview
				</Typography>
				<Typography className="heading-font" color="secondary" variant="h6" sx={{ marginBottom: '20px' }}>
					Note: Our selection process is extremely competitive. We hire exclusively on merit and your ability to explain concepts well.
				</Typography>
			</Box>
			<Box sx={{ maxWidth: '1080px', margin: 'auto' }}>
				{formState.submitted === false && (
					<PathTimeline
						color={theme.palette.secondary.main}
						sections={[
							<Box className="section-step" sx={{ textAlign: 'left' }}>
								<Chip color="secondary" className="text-upper-spaced" variant="filled" label="Step 1 - Bio" />
								<Typography className="section-title heading-font" variant="h5">
									Write your bio
								</Typography>
								<Typography className="blurb-text" variant="h6">
									If someone asked you to give them the elevator pitch about your life mentioning the achievements you're proudest of, what would the pitch read like?
								</Typography>
								<TextField className="text-field" color="secondary" error={isEmpty(state.bio)} helperText={isEmpty(state.bio) && 'This field cannot be empty.'} value={state.bio} minRows={4} fullWidth multiline variant="filled" label="Write bio in here" onChange={(e) => handleChange('bio', e.target.value)} />
							</Box>,
							<Box className="section-step" sx={{ textAlign: 'right' }}>
								<Chip color="secondary" className="text-upper-spaced" variant="filled" label="Step 2 - Experience" />
								<Typography className="section-title heading-font" variant="h5">
									Tell us about the subjects you can teach
								</Typography>
								<Typography className="blurb-text" variant="h6">
									List any subjects you can teach. For each subject, tell us what makes you special and why you should be hired for that subject. For e.g. you can mention your noteworthy grades and awards; or you can mention success stories associated with students you've taught before.
								</Typography>
								<TextField className="text-field" error={isEmpty(state.experience)} helperText={isEmpty(state.experience) && 'This field cannot be empty.'} color="secondary" minRows={4} fullWidth multiline variant="filled" label="List subjects here" value={state.experience} onChange={(e) => handleChange('experience', e.target.value)} />
							</Box>,
							<Box className="section-step" sx={{ textAlign: 'left' }}>
								<Chip color="secondary" className="text-upper-spaced" variant="filled" label="Step 3 - Rate" />
								<Typography className="section-title heading-font" variant="h5">
									Your hourly rate
								</Typography>
								<Typography className="blurb-text" variant="h6">
									What do you consider to be a fair hourly rate for yourself? If you're unsure, you can also provide a range.
								</Typography>
								<TextField className="text-field" error={isEmpty(state.rate)} helperText={isEmpty(state.rate) && 'This field cannot be empty.'} color="secondary" fullWidth variant="filled" label="Specify rate or range in here" value={state.rate} onChange={(e) => handleChange('rate', e.target.value)} />
							</Box>,
							<Box className="section-step" sx={{ textAlign: 'right' }}>
								<Chip color="secondary" className="text-upper-spaced" variant="filled" label="Step 4 - Submit" />
								<Typography className="section-title heading-font" variant="h5">
									Your Contact Details
								</Typography>
								<Typography className="blurb-text" variant="h6">
									Leave your contact details below to submit your application and we'll let you know by email whether you've been short listed for an interview.
								</Typography>
								<TextField error={isEmpty(state.name)} helperText={isEmpty(state.name) && 'Please enter your name'} sx={{ marginTop: '20px !important' }} className="text-field multi-text-fields" color="secondary" fullWidth variant="filled" label="Name" value={state.name} onChange={(e) => handleChange('name', e.target.value)} />
								<TextField error={!validator.isEmail(state.email)} helperText={!validator.isEmail(state.email) && 'Please enter a valid email'} className="text-field multi-text-fields" color="secondary" fullWidth variant="filled" label="Email" value={state.email} onChange={(e) => handleChange('email', e.target.value)} />
								<SelectCountry className="text-field multi-text-fields" variant="filled" country={state.country_code} onChange={(countryCode) => handleChange('country_code', countryCode)} />
								<TextField error={isEmpty(state.postcode)} helperText={isEmpty(state.postcode) && 'Your postcode is needed so that we can accurately match tutors with students based on location.'} className="text-field multi-text-fields" color="secondary" fullWidth variant="filled" label="Postcode" value={state.postcode} onChange={(e) => handleChange('postcode', e.target.value)} />
								<TextField error={isEmpty(state.mobile)} helperText={isEmpty(state.mobile) && 'Please enter your mobile number'} className="text-field multi-text-fields" color="secondary" fullWidth variant="filled" label="Mobile" value={state.mobile} onChange={(e) => handleChange('mobile', e.target.value)} />
								{!formState.loading && formState.error && <ApiErrorMessage sx={{ marginTop: '20px' }} errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
								<LoadingButton disabled={isDisabled()} loading={formState.loading} startIcon={<SubmitIcon />} variant="contained" fullWidth color="secondary" sx={{ marginTop: '20px', '&.Mui-disabled': { backgroundColor: 'rgba(255,255,255,.1)', color: 'rgba(255,255,255,.3)' } }} onClick={handleSubmit}>
									Submit Application
								</LoadingButton>
							</Box>,
						]}
					/>
				)}
				{formState.submitted === true && (
					<Box className="submitted-application-msg">
						<NoDataMessage sx={{ color: '#FFF', padding: '0px !important', marginBottom: '20px' }} customMessage="Thanks for submitting an application! We look forward to reviewing it. We'll let you know by email whether you have been short-listed for an interview 😊" />
						{onSubmitApplication && (
							<Button color="white" variant="outlined" onClick={onSubmitApplication}>
								Close Application
							</Button>
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
}
