const styles = {
	blockPage: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		'& .block-header': {
			'& .block-toolbar': {
				minHeight: '56px !important',
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
			},
			'& .block-icon-container': {
				flexShrink: 0,
				height: '56px',
				width: '56px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: 'secondary.dark',
				'&:hover': {
					filter: 'brightness(130%)',
				},
			},
			'& .plan-btn': {
				margin: 0,
				paddingLeft: '15px',
				borderRadius: '0px 56px 56px 0px',
				flexShrink: 0,
				boxShadow: 'none',
				'&:hover': { backgroundColor: 'secondary.main', filter: 'brightness(130%)' },
				'&.Mui-disabled': {
					backgroundColor: 'rgba(255,255,255,.15)',
					color: 'rgba(255,255,255,.3)',
				},
			},
			'& .block-selector': {
				marginRight: '5px',
				width: '100%',
				'& .MuiOutlinedInput-root': { borderRadius: '0px', backgroundColor: 'primary.main', color: '#FFF', fontWeight: 500 },
				'& .MuiSelect-icon': { color: '#FFF !important' },
				'& .MuiOutlinedInput-input': { textTransform: 'uppercase', letterSpacing: '1px', fontSize: '14px', paddingLeft: '10px' },
			},
		},
		'& .block-tabs-modules': {
			backgoundColor: 'primary.main',
			'& .MuiTabs-indicator': { height: '4px' },
			'& .MuiTab-root': { color: '#FFF', '&.Mui-selected': { color: 'secondary.main', fontWeight: 500 } },
			backgroundColor: 'primary.main',
			color: '#FFF',
		},
	},
	blockComingSoonPage: {
		maxWidth: '600px',
		margin: 'auto',
		textAlign: 'center',
		'& .blurb-text': {
			color: 'text.grey',
			marginTop: { xs: '40px', sm: '100px' },
			padding: '20px',
		},
	},
	blockMainPage: {
		position: 'relative',
	},
	blockHeader: {
		justifyContent: 'center',
		height: '56px',
		zIndex: 99,
		overflow: 'auto',
		'& .toolbar-wrapper': {
			paddingLeft: '0px !important',
			paddingRight: '10px !important',
		},
		'& .main-title': {
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontSize: '14px',
			marginLeft: '10px',
			marginRight: '10px',
			display: 'flex',
			alignItems: 'center',
			flexShrink: 0,
			cursor: 'pointer',
			'&:hover': { color: 'secondary.main' },
		},
		'& .dashboard-icon': {
			color: '#FFF',
			marginLeft: '10px',
			height: '32px',
			width: '32px',
			backgroundColor: 'rgba(0,0,0,.3)',
			'& .MuiSvgIcon-root': { width: '0.8em', height: '0.8em' },
			'&:hover': { backgroundColor: 'rgba(0,0,0,.15)!important', color: '#FFF !important' },
			'&.active': { backgroundColor: '#FFF', color: '#000' },
		},
		'& .page-chip': {
			backgroundColor: 'rgba(0,0,0,.3) !important',
			color: '#FFF !important',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontSize: '12px',
			border: 'none !important',
			'&.active, &.active:hover': {
				background: '#FFF !important',
				color: '#000 !important',
			},
			'&:hover': { background: 'rgba(0,0,0,.15) !important' },
		},
	},
	banner: {
		textAlign: 'center',
		maxWidth: '600px',
		margin: '40px auto 0',
		'& .main-title': {
			margin: '70px auto 30px',
		},
		'& .sub-heading': { marginBottom: '40px' },
		'& .main-blurb': { padding: '20px', marginTop: 0, paddingTop: 0 },
	},
	contentDrawer: {
		'&.MuiModal-root.MuiDrawer-root': { zIndex: 99 },
		'& .MuiPaper-root': { width: { xs: '100%', sm: '80%', md: '50%' } },
	},
	actionBtn: { paddingX: '15px' },
	priceBtn: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		fontSize: '16px',
		padding: '20px 15px',
		borderRadius: '20px',
	},
	curriculumBtns: {
		textAlign: 'center',
		marginBottom: '30px',
	},
	editBtn: { position: 'absolute', bottom: '10px', right: '10px', color: 'rgba(0,0,0,.5)' },
	optionsList: {
		'& .MuiTypography-root': { margin: 0 },
	},
	mainBtn: {
		margin: '10px',
		marginLeft: 0,
		'&.active': {
			color: '#FFF',
			'&.admin': { backgroundColor: 'primary.main' },
			'&.digitiser': { backgroundColor: 'other.brightgreen' },
			'&.math': { backgroundColor: 'discipline.math' },
			'&.compsci': { backgroundColor: 'discipline.compsci' },
			'&.medicine': { backgroundColor: 'discipline.medicine' },
		},
	},
	rootNodes: (theme, discipline) => ({
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		padding: '20px',
		paddingTop: '40px',
		'& .category-box': {
			padding: '40px',
			width: '100%',
			maxWidth: '400px',
			borderRadius: '20px',
			margin: '20px',
			'& .category-title': {
				marginBottom: '15px',
			},
			'& .category-blocks': {
				'& .MuiChip-root': { textTransform: 'uppercase', letterSpacing: '1px', fontSize: '12px' },
			},
		},
		'& .root-node': {
			width: '300px',
			height: '200px',
			flexShrink: 0,
			borderRadius: '10px',
			margin: '20px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '20px',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.palette.discipline[discipline],
				'& .heading-font': { color: '#FFF !important' },
			},
		},
	}),
	topicsList: {
		borderTop: '1px solid rgba(0,0,0,.26)',
		borderBottom: '1px solid rgba(0,0,0,.26)',
		padding: '5px 20px',
	},
};

export default styles;
