/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../../auth';
import { isEmpty, getFullTime, getShortDate, useKey } from '../../../utils';
import { InitialData, QuestionData } from '../../../data';
import { useDialog } from '../../../contexts';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, CircularProgress, Container, Chip, IconButton, AppBar, Toolbar, Typography } from '@mui/material';
import ElementBox from './ElementBox';
import GridItemErrors from './GridItemErrors';
import Whiteboard from '../../Whiteboard';
import QuestionSTEM from './QuestionSTEM';
import QuestionOptionsMc from './QuestionOptionsMc';
import QuestionOptionsParts from './QuestionOptionsParts';
import QuestionOptionsAdmin from './QuestionOptionsAdmin';
import ControlsUser from './ControlsUser';
import QuestionSolutionText from './QuestionSolutionText';
import MetaDataTags from './MetaDataTags';
import LoginRegisterNow from './LoginRegisterNow';
import styles from './styles';
import { Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon, Save as SaveAddIcon, SaveAs as SaveEditIcon } from '@mui/icons-material';
import QuestionHint from './QuestionHint';
import { TutorsList } from '../../Tutors';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../Messages';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
const INITIAL_QUESTION = {
	block_id: null,
	node_id: null,
	question_type: 'mc',
	total_marks: 1,
	question_style: null,
	hint: null,
	difficulty: null,
	likelihood: null,
	question_stem: '',
	overall_solution_text: '',
	question_options: [],
	reference_source: 'original',
	reference_copy_updated: false,
	reference_type: '',
	reference_institution: '',
	reference_question_number: '',
	reference_year: '',
	reference_name: '',
	reference_link: '',
	reference_exam: '',
	reference_comment: '',
	reference_chapter: '',
	reference_edition: '',
	question_performance: {},
	question_performance_steps: [],
};

const DIFFICULTY = [
	{ name: 'Very Easy', color: 'success' },
	{ name: 'Easy', color: 'success' },
	{ name: 'Medium', color: 'warning' },
	{ name: 'Hard', color: 'error' },
	{ name: 'Very Hard', color: 'error' },
];
const LIKELIHOOD = [
	{ name: 'Unlikely', color: 'error' },
	{ name: 'Fairly Likely', color: 'warning' },
	{ name: 'Very Likely', color: 'success' },
];

QuestionElement.defaultProps = {
	sx: {},
	title: '',
	quizId: '',
	node_id: '',
	questionData: {},
	displayUserControls: false,
};

export default function QuestionElement({ sx, title, returnPath, block, node_id, questionData, displayUserControls, onAddQuestion, onEditQuestion, onDeleteQuestion, onSaveTaggedQuestion, handleClose, handlePrevQuestion, handleNextQuestion }) {
	const { isAdmin, appUser } = useAuth();
	const [question, setQuestion] = React.useState(INITIAL_QUESTION);
	const [controls, setControls] = React.useState({
		rerender: '',
		editQuestionMode: false,
		scribbleMode: false,
		displayHint: false,
		displayTutors: false,
		displayUserControls: displayUserControls,
		adminViewMode: displayUserControls === false ? true : false,
	});
	const questionContainerRef = React.useRef(null);
	const [savingQuestionPerformance, setSavingQuestionPerformance] = React.useState(InitialData.completed);
	const [savingQuestionState, setSavingQuestionState] = React.useState({ action: '', loading: false, error: false, errorCode: '', errorMessage: '' });
	const { openDialog } = useDialog();
	useKey('ArrowLeft', () => shiftToPrevQuestion());
	useKey('ArrowRight', () => shiftToNextQuestion());

	React.useEffect(() => {
		if (onAddQuestion) {
			const clonedData = cloneDeep(INITIAL_QUESTION);
			clonedData.block_id = block.id;
			clonedData.node_id = !isEmpty(node_id) ? node_id : '';
			clonedData.difficulty = parseInt(localStorage.getItem('difficulty')) || 3;
			clonedData.likelihood = parseInt(localStorage.getItem('likelihood')) || 2;
			clonedData.question_style = localStorage.getItem('question_style') || '';
			setQuestion(clonedData);
			setControls((prev) => ({ ...prev, editQuestionMode: true }));
		} else {
			const clonedInitialQuestion = cloneDeep(INITIAL_QUESTION);
			const clonedData = cloneDeep(questionData);
			setQuestion({ ...clonedInitialQuestion, ...clonedData });
			setControls((prev) => ({ ...prev, rerender: uuidv4(), editQuestionMode: onSaveTaggedQuestion ? true : false }));
			if (questionContainerRef && !isEmpty(questionContainerRef.current) && controls.editQuestionMode === true) questionContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionData.id]);

	React.useEffect(() => {
		// console.log('This causes bugs on Tagging view');
		if (onEditQuestion && !onSaveTaggedQuestion) onEditQuestion(question);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [question]);

	const shiftToPrevQuestion = () => {
		if (!handlePrevQuestion || controls.editQuestionMode === true) return;
		handlePrevQuestion();
	};

	const shiftToNextQuestion = () => {
		if (!handleNextQuestion || controls.editQuestionMode === true) return;
		handleNextQuestion();
	};

	const handlePath = (node_id) => {
		if (isEmpty(node_id) || isEmpty(returnPath)) {
			if (isAdmin && !isEmpty(question.id)) return `Question ${question.id}`;
			if (isAdmin && isEmpty(question.id) && !isEmpty(onAddQuestion)) return `Fill in the details for the new question below.`;
			return 'Question';
		} else {
			let title = '';
			const path = returnPath(node_id);
			if (!isEmpty(path)) {
				title += path;
			} else {
				title += 'Question';
			}
			if (isAdmin) title += ` > QID ${question.id}`;
			return title;
		}
	};

	/*-----------------------------------------------------------------------------------------------------
     * USER ACTIONS
    -----------------------------------------------------------------------------------------------------*/

	const handleUserAction = (actionType, actionValue) => {
		if (isEmpty(appUser)) {
			openDialog(<LoginRegisterNow />);
		} else {
			const originalQuestionPerformance = { ...question.question_performance };
			if (isEmpty(originalQuestionPerformance)) {
				const newQuestionPerformance = updateQuestionPerformanceValues(originalQuestionPerformance, actionType, actionValue);
				setQuestion((prev) => ({ ...prev, question_performance: newQuestionPerformance }));
				handleUpdateDbQuestionPerformance('insert', newQuestionPerformance);
			} else {
				const newQuestionPerformance = updateQuestionPerformanceValues(originalQuestionPerformance, actionType, actionValue);
				setQuestion((prev) => ({ ...prev, question_performance: newQuestionPerformance }));
				handleUpdateDbQuestionPerformance('update', newQuestionPerformance);
			}
		}
	};

	const handleStepSelection = async (data, option_id = null) => {
		const { question_performance_steps } = question;
		const newStep = { profile_id: appUser.id, question_id: question.id, option_id, step: data.dataStep, step_desc: data.dataDesc, latex: data.dataRaw, tags: data.dataTags };
		const stepExistsIndex = question_performance_steps.findIndex((el) => el.step === newStep.step && el.option_id === newStep.option_id);
		if (stepExistsIndex >= 0) {
			setSavingQuestionPerformance(InitialData.loading);
			const stepId = question_performance_steps[stepExistsIndex].id;
			const res = await QuestionData.deleteQuestionPerformanceStep(stepId);
			if (!res.success)
				return setSavingQuestionPerformance({
					...InitialData.error,
					errorCode: res.code,
					errorMessage: res.message,
				});
			// Then remove it from the question object
			setQuestion((prev) => ({ ...prev, question_performance_steps: prev.question_performance_steps.filter((el) => !(el.step === newStep.step && el.option_id === newStep.option_id)) }));
			setSavingQuestionPerformance(InitialData.completed);
		} else {
			setSavingQuestionPerformance(InitialData.loading);
			const res = await QuestionData.addQuestionPerformanceStep(newStep);
			if (!res.success)
				return setSavingQuestionPerformance({
					...InitialData.error,
					errorCode: res.code,
					errorMessage: res.message,
				});
			setQuestion((prev) => ({ ...prev, question_performance_steps: [...prev.question_performance_steps, res.data] }));
			setSavingQuestionPerformance(InitialData.completed);
		}
	};

	const updateQuestionPerformanceValues = (question_performance, actionType, actionValue) => {
		question_performance.user_id = appUser.id;
		question_performance.question_id = question.id;
		question_performance.updated_at = new Date();
		if (actionType === 'flagged') {
			question_performance.flagged = actionValue;
		} else if (actionType === 'correct') {
			question_performance.correct = actionValue;
		} else if (actionType === 'sentiment') {
			question_performance.sentiment = actionValue;
		} else if (actionType === 'selectedMcOption') {
			question_performance.mc_option_id = actionValue;
		} else if (actionType === 'checkedAnswer') {
			question_performance.checked = actionValue;
			if (question.question_type === 'mc' && 'mc_option_id' in question_performance) {
				const option = question.question_options.find((el) => el.id === question_performance.mc_option_id);
				question_performance.correct = option.is_correct === true ? true : false;
			}
		} else if (actionType === 'correct-part') {
			if (isEmpty(question_performance.correct_parts)) {
				question_performance.correct_parts = [actionValue];
				question_performance.incorrect_parts = [];
			} else {
				question_performance.correct_parts = question_performance.correct_parts.filter((el) => el !== actionValue);
				question_performance.incorrect_parts = question_performance.incorrect_parts.filter((el) => el !== actionValue);
				question_performance.correct_parts.push(actionValue);
			}
			if (!isEmpty(question_performance.correct_parts) && question_performance.correct_parts.length === question.question_options.length) {
				question_performance.correct = true;
			}
		} else if (actionType === 'incorrect-part') {
			if (isEmpty(question_performance.correct_parts)) {
				question_performance.incorrect_parts = [actionValue];
				question_performance.correct_parts = [];
			} else {
				question_performance.incorrect_parts = question_performance.incorrect_parts.filter((el) => el !== actionValue);
				question_performance.correct_parts = question_performance.correct_parts.filter((el) => el !== actionValue);
				question_performance.incorrect_parts.push(actionValue);
			}
			if (!isEmpty(question_performance.incorrect_parts)) {
				question_performance.correct = false;
			}
		}
		return question_performance;
	};

	const handleUpdateDbQuestionPerformance = async (updateMethod, question_performance) => {
		setSavingQuestionPerformance(InitialData.loading);
		const res = await QuestionData.saveQuestionPerformance(updateMethod, question_performance);
		if (!res.success)
			return setSavingQuestionPerformance({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		setSavingQuestionPerformance(InitialData.completed);
		setQuestion((prev) => ({ ...prev, question_performance: res.data }));
	};

	/*-----------------------------------------------------------------------------------------------------
     * ADMIN ACTIONS
    -----------------------------------------------------------------------------------------------------*/

	const onStartEdit = () => {
		setControls((prev) => ({ ...prev, editQuestionMode: true }));
	};

	const onCancelEdit = () => {
		// Go back to the original questionData
		const clonedData = cloneDeep(questionData);
		setQuestion({ ...INITIAL_QUESTION, ...clonedData });
		setControls((prev) => ({ ...prev, editQuestionMode: false }));
	};

	const handleCancelSavingQuestionState = () => {
		setControls((prev) => ({ ...prev, editQuestionMode: false }));
		setSavingQuestionState({ loading: false, error: false, errorCode: '', errorMessage: '', action: '' });
	};

	const formatFinalQuestion = () => {
		// Since reference_institution is a uuid, it must be NULL or a valid uuid
		const formattedQuestion = {
			...question,
			reference_institution: isEmpty(question.reference_institution) ? null : question.reference_institution,
		};
		return formattedQuestion;
	};

	const handleAddQuestion = async () => {
		setSavingQuestionState({ ...InitialData.loading, action: 'add' });
		const res = await QuestionData.addQuestion(formatFinalQuestion(question));
		if (!res.success) return setSavingQuestionState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message, action: 'add' });
		setSavingQuestionState({ ...InitialData.completed, action: '' });
		if (onAddQuestion) onAddQuestion(res.data);
	};

	const handleEditQuestion = async (tagMode = false) => {
		if (tagMode === false) {
			setSavingQuestionState({ ...InitialData.loading, action: 'edit' });
			const res = await QuestionData.editQuestion(formatFinalQuestion(question));
			if (!res.success) return setSavingQuestionState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message, action: 'edit' });
			setSavingQuestionState({ ...InitialData.completed, action: '' });
			if (onEditQuestion) {
				onEditQuestion(res.data);
				setControls((prev) => ({ ...prev, editQuestionMode: false }));
			}
		} else if (tagMode === true && !isEmpty(onSaveTaggedQuestion)) {
			onSaveTaggedQuestion({ ...question, currently_tagging: false, tagged_by: appUser.id });
		}
	};

	const handleDeleteQuestion = async () => {
		setSavingQuestionState({ ...InitialData.loading, action: 'delete' });
		const res = await QuestionData.deleteQuestion(question.id);
		if (!res.success) return setSavingQuestionState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message, action: 'delete' });
		setSavingQuestionState({ ...InitialData.completed, action: '' });
		setControls((prev) => ({ ...prev, editQuestionMode: false }));
		if (onDeleteQuestion) onDeleteQuestion(question.id, question.node_id);
	};

	const checkErrors = (element) => {
		if (isEmpty(question)) return [];
		const errors = [];
		let partErrors = 0;
		if (isEmpty(question.question_stem))
			errors.push({
				element: 'question',
				message: 'The question STEM cannot be empty',
			});
		if (question.question_type === 'parts') {
			if (isEmpty(question.question_options))
				errors.push({
					element: 'question',
					message: 'You need to add at least one part to this question.',
				});
			question.question_options.forEach((part) => {
				if (isEmpty(part.question_text) || isEmpty(part.solution_text) || isEmpty(part.marks)) partErrors++;
			});
			if (partErrors > 0)
				errors.push({
					element: 'question',
					message: 'Please make sure that every part has a question, solution, and marks',
				});
		}
		if (question.question_type === 'mc' && isEmpty(question.question_options))
			errors.push({
				element: 'question',
				message: 'You need to add at least one multiple choice option.',
			});
		if (question.question_type === 'mc' && question.question_options.findIndex((el) => el.is_correct === true) < 0)
			errors.push({
				element: 'question',
				message: 'You need to select the correct multi choice option.',
			});
		if (question.question_type === 'mc' && question.question_options.findIndex((el) => el.is_correct === true) >= 0) {
			const correctOption = question.question_options.find((el) => el.is_correct === true);
			if (isEmpty(correctOption.question_text) || isEmpty(correctOption.solution_text)) {
				errors.push({
					element: 'question',
					message: 'The correct multi choice option cannot contain empty text for the question or solution.',
				});
			}
		}
		if (question.question_type === 'single' && isEmpty(question.overall_solution_text))
			errors.push({
				element: 'question',
				message: 'The solution cannot be empty.',
			});
		if (isEmpty(question.node_id)) {
			errors.push({
				element: 'tags',
				message: 'The question needs to be tagged to a topic.',
			});
		}
		if (isEmpty(question.difficulty))
			errors.push({
				element: 'tags',
				message: 'The question difficulty needs to be selected.',
			});
		if (isEmpty(question.likelihood))
			errors.push({
				element: 'tags',
				message: 'The question likelihood needs to be selected.',
			});
		if (question.reference_source !== 'original' && isEmpty(question.reference_type))
			errors.push({
				element: 'tags',
				message: 'The reference type cannot be empty.',
			});
		if (question.reference_source !== 'original' && !['TEXTBOOK', 'WEBSITE'].includes(question.reference_type) && isEmpty(question.reference_institution))
			errors.push({
				element: 'tags',
				message: 'The reference institution cannot be empty',
			});
		if (question.reference_source !== 'original' && !['TEXTBOOK', 'WEBSITE'].includes(question.reference_type) && isEmpty(question.reference_question_number))
			errors.push({
				element: 'tags',
				message: 'The question number cannot be empty.',
			});
		if (question.reference_source !== 'original' && isEmpty(question.reference_year))
			errors.push({
				element: 'tags',
				message: 'The reference year cannot be empty.',
			});
		if (question.reference_source !== 'original' && question.reference_type === 'WEBSITE' && isEmpty(question.reference_name))
			errors.push({
				element: 'tags',
				message: 'The website link cannot be empty.',
			});
		if (question.reference_source !== 'original' && question.reference_type === 'WEBSITE' && isEmpty(question.reference_name))
			errors.push({
				element: 'tags',
				message: 'Please provide the name of the platform you got the question from.',
			});
		if (question.reference_source !== 'original' && question.reference_type === 'TEXTBOOK' && isEmpty(question.reference_chapter))
			errors.push({
				element: 'tags',
				message: 'Please specify the chapter the question has come from',
			});
		if (question.reference_source !== 'original' && question.reference_type === 'TEXTBOOK' && isEmpty(question.reference_name))
			errors.push({
				element: 'tags',
				message: 'Please provide the name of the textbook.',
			});
		if (question.reference_source !== 'original' && question.reference_type === 'QUIZ' && isEmpty(question.reference_name))
			errors.push({
				element: 'tags',
				message: 'Please specify the name of the quiz.',
			});

		if (!isEmpty(errors) && !isEmpty(element)) return errors.filter((error) => error.element === element);
		return errors;
	};

	const generateStrip = () => {
		const baseStyle = { fontWeight: 600, textAlign: 'center', padding: '5px 10px' };
		if (isEmpty(appUser)) {
			const sx = { ...baseStyle, backgroundColor: 'secondary.main', color: '#FFF', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } };
			return (
				<a href="/auth/login">
					<Box sx={sx} className="text-upper-spaced">
						To use all features you need to sign in and subscribe. Sign in here.
					</Box>
				</a>
			);
		} else if (!isEmpty(appUser) && block.is_subscribed === false) {
			const sx = { ...baseStyle, backgroundColor: 'secondary.main', color: '#FFF', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } };
			return (
				<a href={`/block/${block.code}/subscription`}>
					<Box sx={sx} className="text-upper-spaced">
						To access all questions in this bank, you need to subscribe. Subscribe here.
					</Box>
				</a>
			);
		} else if (!isEmpty(appUser)) {
			const { question_performance } = question;
			if (savingQuestionPerformance.loading) {
				const sx = { ...baseStyle, backgroundColor: 'orange', color: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center' };
				return (
					<Box sx={sx} className="text-upper-spaced">
						<CircularProgress size={20} sx={{ color: '#FFF', marginRight: '10px' }} />
						<span>Saving your data...</span>
					</Box>
				);
			} else if (!savingQuestionPerformance.loading && savingQuestionPerformance.error) {
				const sx = { ...baseStyle, backgroundColor: 'error.main', color: '#FFF' };
				return (
					<Box sx={sx} className="text-upper-spaced">
						There was an error saving your data. Click Here to Try Again.
					</Box>
				);
			} else {
				const time = `${getFullTime(question_performance.updated_at)} on ${getShortDate(question_performance.updated_at)}`;
				// If the user is not paying -> figure out a colour
				if (isEmpty(question_performance)) {
					const sx = { ...baseStyle, backgroundColor: '#eaebea', color: 'text.grey' };
					return (
						<Box sx={sx} className="text-upper-spaced">
							You have not attempted this question yet.
						</Box>
					);
				} else if (!isEmpty(question_performance) && question_performance.checked === false) {
					const sx = { ...baseStyle, backgroundColor: 'text.grey', color: '#FFF' };
					return (
						<Box sx={sx} className="text-upper-spaced">
							Your data was saved @ {time}. But you have not checked the answer yet.
						</Box>
					);
				} else if (!isEmpty(question_performance) && question_performance.checked === true) {
					if (question_performance.correct === null) {
						const sx = { ...baseStyle, backgroundColor: 'text.grey', color: '#FFF' };
						return (
							<Box sx={sx} className="text-upper-spaced">
								You checked the answer @ {time}. Let us know how you went 🙏
							</Box>
						);
					} else if (question_performance.correct === true) {
						const sx = { ...baseStyle, backgroundColor: 'success.main', color: '#FFF' };
						return (
							<Box sx={sx} className="text-upper-spaced">
								Yay! You got this correct 🤍 @ {time}
							</Box>
						);
					} else if (question_performance.correct === false) {
						const sx = { ...baseStyle, backgroundColor: 'error.main', color: '#FFF' };
						return (
							<Box sx={sx} className="text-upper-spaced">
								Aww! You got this incorrect 😔 @ {time}
							</Box>
						);
					}
				}
			}
		}
	};

	return (
		<React.Fragment>
			<Box sx={{ position: 'sticky', top: 0, zIndex: 99 }}>
				<AppBar sx={{ position: 'relative', boxShadow: 'none' }}>
					<Toolbar
						sx={{
							minHeight: '56px !important',
							marginBottom: '-15px',
						}}
					>
						{handleClose && (
							<IconButton edge="start" color="secondary" onClick={() => handleClose()} aria-label="close">
								<CloseIcon />
							</IconButton>
						)}
						<Typography className="heading-font" sx={{ ml: 2, flex: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} variant="h6" component="div">
							{!isEmpty(title) ? title : `${block.name} Questions`}
						</Typography>
					</Toolbar>
				</AppBar>
				<Box sx={styles.questionPathMetaData}>
					<Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
						<Box className="path-name text-upper-spaced">{handlePath(question.node_id)}</Box>
					</Box>
					<Box className="tags">
						{!isEmpty(question.difficulty) && <Chip className="text-upper-spaced tag" color={DIFFICULTY[question.difficulty - 1].color} label={DIFFICULTY[question.difficulty - 1].name} />}
						{!isEmpty(question.likelihood) && <Chip className="text-upper-spaced tag" color={LIKELIHOOD[question.likelihood - 1].color} label={LIKELIHOOD[question.likelihood - 1].name} />}
						{!isEmpty(question.total_marks) && question.question_type !== 'mc' && <Chip className="text-upper-spaced tag" color="secondary" label={question.total_marks + (parseInt(question.total_marks) === 1 ? ' Mark' : ' Marks')} />}
						{isAdmin && isEmpty(onAddQuestion) && (
							<Box sx={{ display: 'inline-block', flexShrink: 0, marginRight: '10px', '& .MuiSvgIcon-root': { color: 'rgba(255,255,255,.5)' } }}>
								{isEmpty(onSaveTaggedQuestion) && controls.editQuestionMode === false && (
									<IconButton color="white" size="small" onClick={onStartEdit}>
										<EditIcon />
									</IconButton>
								)}
								{controls.editQuestionMode === true && (
									<IconButton color="white" size="small" disabled={savingQuestionState.action === 'display-delete-form'} onClick={() => setSavingQuestionState((prev) => ({ ...prev, action: 'display-delete-form' }))}>
										<DeleteIcon />
									</IconButton>
								)}
								{controls.editQuestionMode === true && savingQuestionState.action === 'display-delete-form' && (
									<React.Fragment>
										<span>Are you sure?</span>
										<Chip label="Yes" color="secondary" variant="filled" disabled={savingQuestionState.loading === true && savingQuestionState.action === 'delete'} onClick={handleDeleteQuestion} />
										<Chip label="No" color="secondary" variant="filled" disabled={savingQuestionState.loading === true && savingQuestionState.action === 'delete'} onClick={() => handleCancelSavingQuestionState()} />
									</React.Fragment>
								)}
							</Box>
						)}
					</Box>
				</Box>
				{controls.displayUserControls === true && generateStrip()}
			</Box>
			<Box ref={questionContainerRef} sx={{ ...styles.componentContainer, ...sx, overflow: 'auto', position: 'relative', flexGrow: 1 }}>
				<Box sx={{ position: 'relative', minHeight: 'calc(100% - 10px)' }}>
					{controls.scribbleMode === true && <Whiteboard sx={styles.questionScrapPaper} handleClose={() => setControls((prev) => ({ ...prev, scribbleMode: false }))} />}
					<Container maxWidth="md" sx={{ padding: { xs: '20px 20px 40px', sm: '40px 40px 80px' } }}>
						<Box className="grid-item-element-section">
							{isAdmin && controls.editQuestionMode && (
								<Box sx={{ marginBottom: '20px' }}>
									<Chip color={question.question_type === 'single' ? 'secondary' : 'primary'} className="text-upper-spaced" variant="filled" label="single" onClick={() => setQuestion((prev) => ({ ...prev, question_type: 'single', question_options: [] }))} />
									<Chip color={question.question_type === 'mc' ? 'secondary' : 'primary'} className="text-upper-spaced" variant="filled" label="mc" onClick={() => setQuestion((prev) => ({ ...prev, question_type: 'mc' }))} />
									<Chip color={question.question_type === 'parts' ? 'secondary' : 'primary'} className="text-upper-spaced" variant="filled" label="With parts" onClick={() => setQuestion((prev) => ({ ...prev, question_type: 'parts' }))} />
								</Box>
							)}
							<QuestionSTEM question={question} setQuestion={setQuestion} rerender={controls.rerender} editMode={controls.editQuestionMode} />
							{question.question_type === 'mc' && controls.editQuestionMode === false && <QuestionOptionsMc question={question} adminViewMode={controls.adminViewMode} handleUserAction={handleUserAction} />}
							{question.question_type === 'parts' && controls.editQuestionMode === false && <QuestionOptionsParts controls={controls} question={question} handleUserAction={handleUserAction} handleStepSelection={handleStepSelection} />}
							{isAdmin && (question.question_type === 'mc' || question.question_type === 'parts') && controls.editQuestionMode === true && <QuestionOptionsAdmin mode={!isEmpty(onAddQuestion) ? 'add' : 'edit'} question={question} setQuestion={setQuestion} rerender={controls.rerender} setRerender={(randomString) => setControls((prev) => ({ ...prev, rerender: randomString }))} />}
							<QuestionSolutionText question={question} setQuestion={setQuestion} editMode={controls.editQuestionMode} adminViewMode={controls.adminViewMode} rerender={controls.rerender} handleStepSelection={handleStepSelection} />
							<QuestionHint question={question} setQuestion={setQuestion} rerender={controls.rerender} editMode={controls.editQuestionMode} displayHint={controls.displayHint} />
							{controls.editQuestionMode === false && controls.displayUserControls === true && <ControlsUser question={question} controls={controls} setControls={setControls} handleUserAction={handleUserAction} />}
							{controls.editQuestionMode === false && controls.displayTutors && <TutorsList blockId={question.block_id} />}
						</Box>
						{isAdmin && controls.editQuestionMode === true && <GridItemErrors element="question" errors={checkErrors('question')} />}
						{isAdmin && controls.editQuestionMode === true && (
							<ElementBox title="Tags">
								<MetaDataTags block={block} mode={!isEmpty(onAddQuestion) ? 'add' : 'edit'} question={question} setQuestion={setQuestion} />
								<GridItemErrors element="tags" errors={checkErrors('tags')} />
							</ElementBox>
						)}
						{isAdmin && (
							<Box>
								{!isEmpty(onAddQuestion) && savingQuestionState.loading === false && savingQuestionState.error === true && <ApiErrorMessage errorCode={savingQuestionState.errorCode} errorMessage={savingQuestionState.errorMessage} />}
								{!isEmpty(onAddQuestion) && (
									<LoadingButton variant="contained" color="secondary" startIcon={<SaveAddIcon />} disabled={checkErrors().length > 0} loading={savingQuestionState.loading === true && savingQuestionState.action === 'add'} onClick={handleAddQuestion}>
										Add New Question
									</LoadingButton>
								)}
								{isEmpty(onAddQuestion) && isEmpty(onSaveTaggedQuestion) && controls.editQuestionMode === true && (
									<React.Fragment>
										<LoadingButton startIcon={<CloseIcon />} variant="contained" color="secondary" disabled={savingQuestionState.action === 'edit'} loading={savingQuestionState.loading === true} onClick={onCancelEdit}>
											Cancel Changes
										</LoadingButton>
										<LoadingButton startIcon={<SaveEditIcon />} variant="contained" color="secondary" disabled={checkErrors().length > 0} loading={savingQuestionState.action === 'edit' && savingQuestionState.loading === true} onClick={() => handleEditQuestion(false)}>
											Save Changes
										</LoadingButton>
									</React.Fragment>
								)}
								{isEmpty(onAddQuestion) && !isEmpty(onSaveTaggedQuestion) && controls.editQuestionMode === true && (
									<LoadingButton fullWidth startIcon={<SaveEditIcon />} variant="contained" color="secondary" disabled={checkErrors().length > 0} loading={savingQuestionState.action === 'edit' && savingQuestionState.loading === true} onClick={() => handleEditQuestion(true)}>
										Save & Tag Question
									</LoadingButton>
								)}
							</Box>
						)}
					</Container>
				</Box>
			</Box>
		</React.Fragment>
	);
}
