/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData } from '../../data';
import { isEmpty, useKey } from '../../utils';
import { updateUserEmail } from '../AuthData';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Box, Typography, Button } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../components/Messages';
import styles from './styles';
import { supabase } from '../../supabaseClient';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function UpdateEmailForm() {
	const history = useHistory();
	const [state, setState] = React.useState({
		email: { value: '', error: false, message: '' },
	});
	const [formState, setFormState] = React.useState(InitialData.completed);
	useKey('Enter', () => handleSubmit());

	React.useEffect(() => {
		initialiseEmail();
	}, []);

	const initialiseEmail = async () => {
		const {
			data: { session },
			error,
		} = await supabase.auth.getSession();
		if (!isEmpty(session)) {
			setState((prev) => ({
				...prev,
				email: { value: session.user.email, error: false, message: '' },
			}));
		}
	};

	const handleEmail = (e) => {
		e.persist();
		const email = e.target.value;
		const data = { value: email, error: false, message: '' };
		if (isEmpty(email)) {
			data.error = true;
			data.message = 'Email field cannot be empty.';
		}
		if (!validator.isEmail(email)) {
			data.error = true;
			data.message = 'You must enter a valid email.';
		}
		setState((prev) => ({ ...prev, email: data }));
	};

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) handleSubmit();
	};

	const handleSubmit = async () => {
		if (checkErrors()) return;
		setFormState(InitialData.loading);
		const res = await updateUserEmail(state.email.value);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setFormState({ ...InitialData.completed, data: res.data });
	};

	const checkErrors = () => {
		if (isEmpty(state.email.value) || state.email.error === true) {
			return true;
		}
		return false;
	};

	return (
		<Box id="login-form" sx={styles.authForm}>
			{isEmpty(formState.data) && (
				<React.Fragment>
					<TextField fullWidth label="New Email" className="text-field-light" variant="filled" color="primary" error={state.email.error} helperText={state.email.message} value={state.email.value} onChange={handleEmail} />
					{formState.error && <ApiErrorMessage className="error-message" errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
					<LoadingButton fullWidth className="btn-light" color="secondary" disabled={checkErrors()} loading={formState.loading} variant="contained" onClick={handleSubmit} onKeyDown={handleKeyDown}>
						{formState.loading === true ? 'Updating Email ...' : 'Update Email'}
					</LoadingButton>
				</React.Fragment>
			)}
			{!isEmpty(formState.data) && (
				<React.Fragment>
					<Typography color="secondary" className="heading-font instruction-title" variant="h6" sx={{ marginBottom: '30px' }}>
						Yay! Your new email has been set to: {formState.data.email} 💜
					</Typography>
					<Button fullWidth variant="contained" color="secondary" startIcon={<HomeIcon />} onClick={() => history.push('/')}>
						Go Home
					</Button>
				</React.Fragment>
			)}
		</Box>
	);
}
