import { isEmpty } from '../utils';
import { supabase } from '../supabaseClient';

const BlockData = {
	getBlock: async (code) => {
		try {
			const { data, error } = await supabase.rpc('get_block_by_code', { p_code: code });
			if (isEmpty(data)) return { success: false, code: 'Get Block', message: 'Sorry, this block does not exist.' };
			if (error) throw error;
			const { block_id, ...rest } = data[0];
			return { success: true, data: { id: block_id, ...rest } };
		} catch (err) {
			return { success: false, code: 'Get Block', message: err.message };
		}
	},
};

export default BlockData;
