const styles = {
	tutorsContainer: {
		maxWidth: '900px',
		margin: 'auto',
		padding: { xs: '20px 20px 40px' },
	},
	nodesContainer: {
		position: 'relative',
		height: '100%',
		overflow: 'auto',
		'& .react-flow .react-flow__edge path, & .react-flow__connectionline path': { strokeWidth: 2 },
		'& .react-flow .react-flow__handle': {
			width: '8px',
			height: '8px',
			borderRadius: '50%',
			backgroundColor: '#555',
		},
		'& .react-flow .react-flow__node-customNode': {
			padding: 0,
			border: 'none',
			fontWeight: 'bold',
			cursor: 'pointer',
			height: '25px',
			color: '#FFF',
			borderRadius: '25px',
			fontSize: '12px',
			textAlign: 'center',
			'&.selected': { border: '4px solid #784be8' },
			'& .custom-node': {
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				padding: '10px 7px',
				paddingRight: '0px',
				'&.noContent': {
					backgroundColor: '#98a1a6 !important',
					borderRadius: '25px',
					'&:hover': { filter: 'unset !important' },
				},
				'&.moveNode': {
					backgroundColor: 'orange !important',
					borderRadius: '25px',
					'&:hover': { filter: 'unset !important' },
				},
				'&.disabledNode': {
					backgroundColor: '#bdbdbd !important',
					borderRadius: '25px',
					'&:hover': { filter: 'unset !important' },
				},
				'&.checkedNode': {
					backgroundColor: '#07003c !important',
					borderRadius: '25px',
					'&:hover': { filter: 'unset !important' },
				},
				'&:hover': {
					filter: 'brightness(150%)',
					backgroundColor: 'inherit',
					borderRadius: '25px',
				},
				'& .flex-wrapper': {
					display: 'flex',
					alignItems: 'center',
					'& .node-title': {
						fontFamily: "'Roboto', sans-serif",
						textAlign: 'left',
						display: 'flex',
						alignItems: 'center',
						textTransform: 'uppercase',
						letterSpacing: '1px',
						fontSize: '9px',
						fontWeight: '500',
						pl: '5px',
						'& .title-text': { pl: '5px', pr: '10px' },
						'& .expand-btn': {
							backgroundColor: 'rgba(255,255,255,.3)',
							color: 'rgba(255, 255, 255, 0.8)',
							height: '25px',
							width: '25px',
							borderRadius: '0px 50% 50% 0px',
							'& .expand-icon': { fontSize: '1rem' },
							'&:hover': {
								backgroundColor: 'rgba(0,0,0,.5)',
								color: '#FFF',
							},
						},
						// overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
					},
					'& .options': {
						marginRight: '10px',
						'& .option': {
							color: '#FFF',
							padding: '2px',
							'& .MuiSvgIcon-root': {
								width: '12px',
								height: '12px',
							},
							'&:hover': {
								backgroundColor: '#FFF',
								color: '#000',
							},
						},
					},
				},
				'&.has-content': {
					background: '#FFF',
					color: '#222',
					cursor: 'pointer',
				},
				'& .related-tags': {
					'& .MuiChip-root': { cursor: 'pointer' },
				},
			},
		},
	},
	quizControls: {
		backgroundColor: 'primary.main',
		textAlign: 'right',
		paddingTop: '15px',
		paddingBottom: '5px',
		'& .MuiInputLabel-root': { color: 'secondary.main', '&.Mui-focused': { color: 'secondary.main' } },
		'& .chip-selector .MuiOutlinedInput-input': { padding: '12px 14px' },
		'& .MuiOutlinedInput-input': { color: 'secondary.main' },
		'& .MuiOutlinedInput-notchedOutline': { borderColor: 'secondary.main' },
		'&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'secondary.main' },
		'& .duration-mins': { width: '120px' },
	},
};

export default styles;
