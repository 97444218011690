/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * IMAGES
****************************************************************************************************/
import LogoIconColor from './logo-color.svg';
import LogoIconWhite from './logo-white.svg';
/**************************************************************************************************** 
 * STYLES
****************************************************************************************************/
import { styled } from '@mui/material/styles';
const LogoIconImg = styled("img", { shouldForwardProp: (prop) => !['background', 'size', 'sx'].includes(prop) })(({ theme, background, size, sx }) => ({
    width: size, height: size, padding: '4px', background: background && theme.palette.primary.main, cursor: 'pointer', ...sx,
}))
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
LogoIcon.propTypes = {
    white: PropTypes.bool,
    background: PropTypes.bool,
    size: PropTypes.number,
    sx: PropTypes.object,
}
LogoIcon.defaultProps = {
    white: false,
    background: false,
    size: 64,
    sx: {}
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function LogoIcon({ size, white, background, sx }) {
    const history = useHistory();
    return (
        <LogoIconImg sx={sx} className="logo-icon" src={white ? LogoIconWhite : LogoIconColor} alt="" background={background} size={size} onClick={() => history.push('/')} />
    )
}