/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
import { LoaderCircular } from '../../@core/Loader';
import { BannerMini } from '../../@core/Banner';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
const Sx = {
	fullPage: {
		height: '100vh',
		display: 'flex',
		padding: '20px',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	nonFullPage: {
		padding: '20px',
	},
};
/****************************************************************************************************
 * PROPS
 ****************************************************************************************************/
LoadingMessage.propTypes = {
	fullPage: PropTypes.bool,
	title: PropTypes.string,
	message: PropTypes.string,
};
LoadingMessage.defaultProps = {
	fullPage: false,
	color: 'secondary',
	sx: {},
};
/****************************************************************************************************
 * MDOULE
 ****************************************************************************************************/
export default function LoadingMessage({ title, color, message, fullPage, sx }) {
	return (
		<Box sx={fullPage ? { ...Sx.fullPage, ...sx } : { ...Sx.nonFullPage, ...sx, flexGrow: 1 }}>
			{title && message && <BannerMini centered={true} title={title} blurb={message} sx={{ paddingTop: 0 }} />}
			<LoaderCircular color={color} />
		</Box>
	);
}
