import React from 'react';

// Custom hook that executes only after the component has mounted
const useEffectAfterMount = (func, deps) => {
    const didMount = React.useRef(false);

    React.useEffect(() => {
        if (didMount.current) {
            func();
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

export default useEffectAfterMount;