/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Alert, AlertTitle } from '@mui/material';
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
ErrorAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    severity: PropTypes.oneOf(["error", "warning", "success"]),
    sx: PropTypes.object,
}
ErrorAlert.defaultProps = {
    title: "Error",
    message: "Sorry, there was an error",
    severity: "error",
    sx: {}
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function ErrorAlert({ title, severity, message, sx }) {
    return (
        <Alert severity={severity} sx={{ padding: '20px', marginBottom: '20px', color: 'rgba(255,255,255,.7)', '& .MuiAlert-icon': { color: 'unset', }, ...sx }}>
            <AlertTitle sx={{ marginBottom: '5px' }}>{title}</AlertTitle>
            {message}
        </Alert>
    )
}