/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData, NodeData } from '../../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function NodeDeleteForm({ nodeId, title, handleSubmit, handleCancel }) {
	const [formState, setFormState] = React.useState(InitialData.completed);

	const handleDelete = async () => {
		setFormState(InitialData.loading);
		const res = await NodeData.deleteNode(nodeId);
		if (!res.success)
			return setFormState({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		handleSubmit(res.data);
	};

	return (
		<Box sx={styles.formContainer}>
			<Typography className="heading-font form-title" variant="h5" align="center">
				{title}
			</Typography>
			{!formState.loading && formState.error && <ApiErrorMessage errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
			<LoadingButton fullWidth color="primary" variant="outlined" loading={formState.loading} onClick={() => handleDelete()}>
				Yes, Delete Node
			</LoadingButton>
			<Button fullWidth color="primary" variant="contained" onClick={() => handleCancel()}>
				No, Cancel
			</Button>
		</Box>
	);
}
