/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box, Paper, Typography } from '@mui/material';
import styles from './styles';
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function Privacy() {
    return (
        <Box>
            <Box sx={styles.helpBanner}>
                <Typography className='heading-font primary main-title' align="center" variant="h3">Privacy Policy</Typography>
                <Typography className='heading-font sub-heading' align="center" variant="h5">Last Updated: 22nd August 2022</Typography>
                <Typography className='blurb-text main-blurb' align="center" variant="h6">
                    This page contains our privacy policy. You may wish to print this page for reference.
                </Typography>
            </Box>
            <Box sx={styles.helpContent}>
                <Typography variant="h5" className="emphasise primary heading-font">Our Commitment to Your Privacy</Typography>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} abides by Australia{String.fromCharCode(39)}s National Privacy Principles and is committed to respecting your right to privacy and your right to control the accuracy of your personal information.</p>
                    <p>We are committed to protecting your privacy in all your dealings with us. For example, when you:</p>
                    <ul>
                        <li>Contact us,</li>
                        <li>Visit our various websites,</li>
                        <li>Use any downloadable applications (apps) and any other LEARNBLOCK{String.fromCharCode(8482)} platforms and/or technology whether known now or created in the future ({String.fromCharCode(39)}the Sites{String.fromCharCode(39)}),</li>
                        <li>Respond to one of our surveys or promotions,</li>
                        <li>Subscribe to one or more of our various initiatives, promotions, newsletters, Sites or perhaps buy something from us or share your experiences with us or others via the Sites.</li>
                    </ul>
                    <p>We only collect personal information that is needed for or directly related to the functions and activities of LEARNBLOCK{String.fromCharCode(8482)}. For example, when we deliver the our services including the Sites, platforms and other means of communication between users and/or subscribers.</p>
                    <p>We protect your details through encryption, firewalls and computer security systems.</p>
                    <p>We never share information about you with any other organisation beyond what is necessary to deliver our services to you and/or provide the Sites.</p>
                    <p>You are always welcome to review the information we hold about you, if any.</p>
                    <p>We will use our best endeavours to promptly deal with complaints about any alleged privacy breaches.</p>
                    <p>This policy covers information that identifies you as an individual. It also covers information from which your identity is apparent or could reasonably be ascertained (e.g. by matching different sets of data held by us).</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Information we collect</Typography>
                <Paper className="section">
                    <p>We only collect personal information about you if it is necessary to supply you with what you request or give you the access you need. For example, if:</p>
                    <ul>
                        <li>You subscribe to our newsletters and/or updates;</li>
                        <li>You respond to one of our promotional offers;</li>
                        <li>You buy something from us;</li>
                        <li>You ask us for advice in relation to an issue; or</li>
                        <li>You share your experiences</li>
                        <li>You share research, plans, notes or similar.</li>
                        <li>It is necessary to protect the rights or property of LEARNBLOCK{String.fromCharCode(8482)} and/or any member of the public.</li>
                        <li>It is necessary to lessen a serious threat to a person{String.fromCharCode(39)}s health or safety.</li>
                        <li>It is required by law.</li>
                    </ul>
                    <p>We will only collect information by lawful and fair means and we will take steps to ensure that the information we collect does not intrude to an unreasonable extent on your personal affairs.</p>
                    <p>When we ask you for information, we will tell you:</p>
                    <ul>
                        <li>Who we are and how to contact us;</li>
                        <li>How you can get access to the personal information you give us if you want to check or change it;</li>
                        <li>Any law that requires us to collect it; and</li>
                        <li>The main consequences (if any) if all or part of the information is not provided.</li>
                    </ul>
                    <p>We will usually collect personal information directly from you.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Sensitive information</Typography>
                <Paper className="section">
                    <p>Sensitive information is information that identifies an individual{String.fromCharCode(39)}s racial or ethnic origin, political opinions, membership of a political association, religious beliefs or affiliations, philosophical beliefs, membership of a professional or trade association, membership of a trade union, sexual preferences or practices, criminal record or health information. LEARNBLOCK{String.fromCharCode(8482)} does not intentionally collect sensitive information about you unless you specifically choose to volunteer it to us. For example, you join fraternity or union on LEARNBLOCK{String.fromCharCode(8482)}, in an email, bulletin or tweet to us about your experiences or concerns. When we collect information for research topics, the information is usually collected anonymously and in aggregate form, for example to lobby government, unless you give your consent to being identified. You can, of course, always opt out of giving us access to this kind of information.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Anonymity</Typography>
                <Paper className="section">
                    <p>Wherever it is lawful and practicable we will give you the option of not identifying yourself when contacting or dealing with LEARNBLOCK{String.fromCharCode(8482)}. For example, you can visit the free parts of the Sites, respond to surveys and ask us questions without identifying yourself. However, if you want to receive something from us, ask a question about the personal information we hold about you, buy something from us, access the Site, via hand helds and mobile devices or participate in one of our competitions you may need to give us certain personal information.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Information you may choose to provide</Typography>
                <Paper className="section">
                    <p>If you take part in an online, mail, mobile, text, email, social networking e.g.Twitter{String.fromCharCode(8482)}/ Facebook{String.fromCharCode(8482)} or other survey, the information we collect is usually anonymous and is only used in aggregated form unless we specifically tell you otherwise at the time we collect it. You always have the option not to participate. All information gathered in this way is kept strictly confidential. We may also collect general demographic data so we can check aggregated results against established statistical data, but the identification is no more specific than your postcode and is never used on an individual basis. Where you give us information about your general experiences this may be interesting to others when we publish articles on those topics. If we wish to use your experience and you{String.fromCharCode(39)}ve provided it to us in a private communication (e.g. letter, fax, email, phone call, text, tweet, survey) we will always ask your permission before we publish any relevant information. You always have the right to refuse or to request anonymity.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">How your personal information is used and disclosed</Typography>
                <Paper className="section">
                    <p>We will not use or disclose or permit the use or disclosure of personal information that could be used to identify an individual except in for example when you purchase something from us or respond to one of our promotions or competitions. We will use the information you provide to deliver any information and/or goods and services that you have requested. For example, if you subscribe to an aspect of the Sites we may ask you for your email address so we can send you our newsletters, updates, promotions and/or competitions.</p>
                    <p>We may use information about you that was collected by another organisation or third party software to send you promotional offers, to assist you access aspect of the Sites or generally improve your online experience. We will always take all reasonable steps to make sure you give informed consent for the information to be used in this way.</p>
                    <p>When we send you an e-newsletter, text, tweet or equivalent technological communication we may collect information about opening and click through rates. We usually only use that information in aggregated form.</p>
                    <p>Where personal information you give us is disclosed to an agent of LEARNBLOCK{String.fromCharCode(8482)} (for example to data processing, data analysis, printing, or other contractors) it will only be disclosed to the extent necessary to enable them to undertake the specific task we have contracted them to do. We will always require our contractors to operate under conditions of confidentiality, which will be specified as a term of the contract between LEARNBLOCK{String.fromCharCode(8482)} and the agent. Such an agent will be required to return all original records to LEARNBLOCK{String.fromCharCode(8482)} or securely destroy any copied records and ensure that its employees are familiar with their obligations of privacy and confidentiality. Personal information collected by LEARNBLOCK{String.fromCharCode(8482)} will not knowingly be given, sold or rented for use by any other organisation or for any other purpose. Any partners of LEARNBLOCK{String.fromCharCode(8482)} will be required to abide by Australia{String.fromCharCode(39)}s National Privacy Principles. LEARNBLOCK{String.fromCharCode(8482)} will not knowingly transfer personal information about individuals to anyone else or any other organisation in a foreign country. However, we may use data and information that we have acquired about you to send a more focused campaign or newsletter you.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">We don{String.fromCharCode(39)}t spam</Typography>
                <Paper className="section">
                    <p>We don{String.fromCharCode(39)}t spam and we never pass email or text addresses to third parties except where they act as agents for us and the information is required to deliver the goods or services you have requested from us.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Cookies</Typography>
                <Paper className="section">
                    <p>We use cookies in the following ways:</p>
                    <ul>
                        <li>We offer you the convenience of accepting a permanent cookie when you become a user of the Sites by ticking the Remember Me checkbox or equivalent technology. This will ensure you are greeted appropriately whenever you visit the Sites and are permanently logged in so that you may browse the Sites unimpeded whenever you visit. You can decline this cookie or equivalent technology if you wish, or turn it off after you{String.fromCharCode(39)}ve accepted it, by clicking Logout in the Welcome box on the right hand side of every page.</li>
                        <li>We use a temporary cookie or equivalent technology when you first become a user of the Sites, or during any session in which you change any of your membership information, as a convenience to you so you can avoid having to enter your username and password on every screen. This cookie disappears when you exit your browser.</li>
                        <li>If you decline the Remember Me cookie, a temporary cookie or equivalent technology may be used during each visit to the Sites. The cookie or equivalent technology does not contain any personal information and expires after 30 days.</li>
                        <li>You must accept the temporary cookies in order to gain access to the members-only areas or equivalent areas. Note: If your browser is set to notify you when you receive a cookie, you{String.fromCharCode(39)}ll be prompted to accept the cookie (Yes/No). Please select "Yes".</li>
                        <li>We may decide in the future to use cookies in other parts of our site to improve our service to you. If we do, we will post that information on these pages and notify you of it in any section that uses cookies when you first encounter it, with links to this page.</li>
                    </ul>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">The quality and security of your personal information</Typography>
                <Paper className="section">
                    <p>We will take all reasonable steps to ensure all personal information we hold about you is relevant, not excessive, accurate, complete and up-to-date.</p>
                    <p>Our user records are protected by a range of security measures: encryption, firewalls and/or computer network security systems. Personal information about former users is retained for a period of time after the user relationship ceases as required by law — for example, financial records must be kept for 7 years — and as part of LEARNBLOCK{String.fromCharCode(39)}s intellectual property portfolio. During this time we may contact you to tell you about LEARNBLOCK{String.fromCharCode(8482)} or to ask you why you left LEARNBLOCK{String.fromCharCode(8482)}. At all times, you can request not to receive further contact from us either by indicating so on the material we send you or by contacting us directly.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">How to opt out</Typography>
                <Paper className="section">
                    <p>If you do not wish to receive further material or information from LEARNBLOCK{String.fromCharCode(8482)} you can write to us at Privacy, LEARNBLOCK{String.fromCharCode(8482)} Level 26, 44 Market Street, Sydney NSW 2000.</p>
                    <p>You will need to give sufficient information for us to identify you, for example, your name, email address and street address.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">How to check or correct your personal information</Typography>
                <Paper className="section">
                    <p>To see what personal information we hold on you, please write to us requesting access to your personal information at Privacy, LEARNBLOCK{String.fromCharCode(8482)} Level 26, 44 Market Street, Sydney NSW 2000.</p>
                    <p>So that we can identify you, please provide your full name and address as they appear in our records, your daytime phone number and email address (if applicable), details of the parts of the Sites that you access or subscribe to and/or details of your recent purchases from us. Because email is relatively insecure, we ask that you put this information in a letter or fax rather than in an email.</p>
                    <p>We will send you the personal information we hold about you within ten working days of receiving your request in writing. We will then work with you to alter or update any information that we both agree was inaccurate, incomplete or out-of-date within a further five working days. In the unlikely event that we disagree with you about the changes you want made, we will explain why and, if you wish, add a note to your records stating that you claim the information is inaccurate, incomplete or out-of-date. There is no charge for this service.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">How to update your details</Typography>
                <Paper className="section">
                    <p>To update your details you can write to us at Privacy, LEARNBLOCK{String.fromCharCode(8482)} Level 26, 44 Market Street, Sydney NSW 2000.</p>
                    <p>So that we can identify you, please provide your full name and address as they appear in our records, your daytime phone number and email address (if applicable), details of the parts of the Sites that you access or subscribe to and/or details of your recent purchases from us. Because email is relatively insecure, we ask that you put this information in a letter or fax rather than in an email.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Changes to this privacy policy</Typography>
                <Paper className="section">
                    <p>If our privacy policy changes, we will draw your attention to our new policy through the publications or services you subscribe to or buy or through the Sites.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Complaints</Typography>
                <Paper className="section">
                    <p>We are committed to dealing quickly and appropriately with any complaint you make about your privacy. If you are concerned that this privacy policy may have been breached or that your privacy may have been compromised please contact us in writing immediately, setting out your concerns care of Privacy, LEARNBLOCK{String.fromCharCode(8482)} Level 26, 44 Market Street, Sydney NSW 2000.</p>
                    <p>So that we can identify you, please provide your full name and address as they appear in our records, your daytime phone number and email address (if applicable), details of the parts of the Sites that you access or subscribe to and/or details of your recent purchases from us. Because email is relatively insecure, we ask that you put this information in a letter or fax rather than in an email.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Unresolved complaints</Typography>
                <Paper className="section">
                    <p>If you are not satisfied with our handling of your complaint you can refer it to the Australian Federal Privacy Commissioner.</p>
                    <p>The central office of the Federal Privacy Commissioner is located in Sydney at: Level 3, 175 Pitt  Street Sydney NSW 2000 (as at February {(new Date().getFullYear())}).</p>
                    <p>Contact details (as at February {(new Date().getFullYear())}):</p>
                    <p><b>Privacy Hotline</b> 1300 363 992 or 1800 620 241 (9.00am - 5.00pm EST Monday to Friday) (for the cost of a local call anywhere in Australia)</p>
                    <b>Mail</b> GPO Box 5218 Sydney NSW 2001
                    <br />
                    <b>Fax</b> +61 2 9284 9666
                    <br />
                    <b>Email</b> enquiries@oaic.gov.au
                    <br />
                    <b>Web</b> http://www.oaic.gov.au
                    <p>Please note: Complaints should be made in writing to the Federal Privacy Commissioner at the above address. If you need help to make a complaint, you can phone the hotline for assistance.</p>
                </Paper>
                <Typography variant="h5" className="emphasise primary heading-font">Warning</Typography>
                <Paper className="section">
                    <p>To keep your personal details secure if you{String.fromCharCode(39)}re using a public computer, always Logout of the site after use or decline the Remember Me option.</p>
                    <p>We review this policy regularly and update it in accordance with changes to the law or to the services we offer.</p>
                </Paper>
            </Box>
        </Box>
    )
}