/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function GridItemErrors({ element, errors }) {
	if (isEmpty(errors)) return null;

	return (
		<Box sx={styles.errorWidget}>
			<Box className="errors-title text-upper-spaced">Errors found in the {element}</Box>
			{errors.map((error, errorIndex) => (
				<Box className="error-item" key={`${element}-error-${errorIndex}`}>
					<Box className="error-item__number">{errorIndex + 1}</Box>
					{isEmpty(element) && <Box className="error-item__element-type text-upper-spaced">{error.element}</Box>}
					<Box className="error-item__text text-upper-spaced">{error.message}</Box>
				</Box>
			))}
		</Box>
	);
}
