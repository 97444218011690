/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * CONTEXTS
 ****************************************************************************************************/
import { useAuth } from '../../../auth';
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
import CodeEditorBasic from '../../CodeEditorBasic';
import MarkdownRender from '../../MarkdownRender';
import styles from './styles';
/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
export default function QuestionSolution({ question, setQuestion, editMode, adminViewMode, rerender, handleStepSelection }) {
	const { isAdmin } = useAuth();
	const { question_type, overall_solution_text, question_performance, question_performance_steps } = question;
	return (
		<Box id="question__overall_solution_text">
			{editMode === false && !isEmpty(question_performance) && question_performance.checked === true && !isEmpty(overall_solution_text) && (
				<Box sx={styles.emphasiseBubble}>
					<Box sx={styles.miniTitle}>Solution Summary</Box>
					{adminViewMode === false && (question_performance.checked === false || isEmpty(question_performance.checked)) && (question_type === 'single' || (question_type === 'mc' && !isEmpty(overall_solution_text)) || (question_type === 'parts' && !isEmpty(overall_solution_text))) && (
						<Box className="empty-solution-message">
							<Typography variant="h6" className="section-message">
								Once you select check answer, an overall solution will display here.
							</Typography>
						</Box>
					)}
					{adminViewMode === false && (question_performance.checked === false || isEmpty(question_performance.checked)) && isEmpty(overall_solution_text) && question_type === 'mc' && (
						<Box className="empty-solution-message">
							<Typography variant="h6" className="section-message">
								Once you select check answer, an explanation will appear under each option.
							</Typography>
						</Box>
					)}
					{adminViewMode === false && (question_performance.checked === false || isEmpty(question_performance.checked)) && isEmpty(overall_solution_text) && question_type === 'parts' && (
						<Box className="empty-solution-message">
							<Typography variant="h6" className="section-message">
								Once you select show solution, an explanation will appear under each part.
							</Typography>
						</Box>
					)}
					{adminViewMode === false && question_performance.checked === true && question_type === 'single' && isEmpty(overall_solution_text) && (
						<Box className="empty-solution-message">
							<Typography variant="h6" className="section-message">
								Sorry, there is no overall solution available on this question.
							</Typography>
						</Box>
					)}
					{adminViewMode === true && isEmpty(overall_solution_text) && (
						<Box className="empty-solution-message">
							<Typography variant="h6" className="section-message">
								There is no overall solution for this question.
							</Typography>
						</Box>
					)}
					{!isEmpty(overall_solution_text) && (adminViewMode === true || question_performance.checked === true) && (
						<Box className="grid-item-element-section">
							<MarkdownRender bucket="questions" correct={question_performance.correct} questionPerformanceSteps={question_performance_steps} handleStepSelection={handleStepSelection} children={overall_solution_text} />
						</Box>
					)}
				</Box>
			)}
			{editMode === true && isAdmin && (
				<Box className="grid-item-element-section edit-content">
					<Box className="edit-content__box">
						<Box sx={styles.miniTitle}>Overall Solution Text</Box>
						{!isEmpty(overall_solution_text) && <MarkdownRender bucket="questions" children={overall_solution_text} />}
						<CodeEditorBasic rerender={rerender} language="markdown" bucket="questions" initialCode={overall_solution_text} onChange={(value) => setQuestion((prev) => ({ ...prev, overall_solution_text: value }))} style={{ marginTop: '20px' }} />
					</Box>
				</Box>
			)}
		</Box>
	);
}
