import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails } from '@mui/material';

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

export const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />)(({ theme }) => ({
	paddingLeft: 0,
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	// flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		// marginTop: theme.spacing(1.5),
	},
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const styles = {
	dialogBox: {
		padding: '60px',
		display: 'flex',
		flexDirection: 'column',
		'& .loading-btn': {
			marginTop: '30px',
			borderRadius: '4px',
			height: '60px',
		},
		'& .field-error': {
			color: '#88081B',
			fontSize: '0.75rem',
			lineHeight: '1.66',
			marginTop: '5px',
		},
	},
	tutorBookingsList: { maxWidth: '800px', margin: '50px auto' },
	tutorCourseSelector: {
		'& .list-subheader-title': {
			textTransform: 'uppercase',
			letterSpacing: '1px',
			color: 'primary.main',
		},
	},
	bookATutor: {
		flexGrow: 1,
		backgroundColor: 'primary.main',
		'& .banner': {
			color: '#FFF',
			padding: { xs: '40px', sm: '60px' },
			maxWidth: '800px',
		},
		'& .section-step': {
			margin: '40px 0px',
			'& .section-title': { margin: '20px 0px', color: 'secondary.main' },
			'& .blurb-text': {
				color: 'text.grey',
				margin: '10px 0px',
			},
			'& .text-field': {
				margin: '20px 0px',
				'&.multi-text-fields': { margin: '10px 0px' },
				'& .MuiFilledInput-root': { backgroundColor: '#FFF' },
				'& .MuiFormHelperText-root.Mui-error': {
					filter: 'brightness(130%)',
				},
			},
		},
		'& .submitted-application-msg': {
			textAlign: 'center',
			backgroundColor: 'secondary.main',
			padding: { xs: '40px', sm: '60px' },
			borderRadius: '20px',
			maxWidth: '600px',
			width: 'calc(100% - 40px)',
			margin: 'auto',
		},
	},
	tutorGridContainer: {
		textAlign: 'center',
		flexGrow: 1,
		overflow: 'auto',
	},
	tutorListContainer: {
		flexGrow: 1,
		width: '100%',
		'& .tutor-element': {
			width: '100%',
			backgroundColor: '#FFF',
			margin: '40px auto',
			'&:first-of-type': { marginTop: '0px' },
			padding: { xs: '20px', sm: '40px' },
			boxShadow: '0 0 35px rgb(0 0 0 / 10%)',
			// border: '2px solid rgba(0,0,0,.08)',
			borderRadius: '10px',
			'& .tutor-banner': {
				display: 'flex',
				alignItems: 'center',
				marginBottom: '20px',
				justifyContent: { xs: 'center', sm: 'flex-start' },
				textAlign: { xs: 'center', sm: 'left' },
				flexDirection: { xs: 'column', sm: 'row' },
				'& .tutor-pic': {
					width: '60px',
					height: '60px',
					flexShrink: 0,
					marginRight: { xs: '0px', sm: '20px' },
					marginBottom: { xs: '10px', sm: '0px' },
				},
			},
			'& .tutor-details': {
				textAlign: 'left',
				'& .section': {
					marginBottom: '15px',
					'& .section-title': {
						marginBottom: '10px',
						fontWeight: 600,
						color: 'primary.main',
						display: 'flex',
						alignItems: 'center',
						'& .section-icon': {
							fontSize: '18px',
							marginRight: '5px',
						},
					},
					'& .tutor-bio': {
						color: 'text.grey',
						fontWeight: 400,
					},
					'& .tutor-courses': {
						margin: '15px 0px',
						'& .course': {
							'&.MuiAccordion-root': {
								borderWidth: '2px',
								'& .MuiAccordionSummary-root': {
									backgroundColor: '#FFF',
								},
								'& .MuiAccordionDetails-root': {
									borderWidth: '2px',
								},
							},
							'& .course-tag': {
								fontWeight: 600,
								color: 'primary.main',
							},
							'& .course-name': {
								paddingLeft: '0px',
								color: 'primary.main',
							},
							'& .course-offered-as': {
								margin: '5px 0px 10px',
								'& .course-offering': {
									margin: 0,
									marginRight: '2px',
									fontSize: '11px',
								},
							},
							'& .course-meta': {
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							},
							'& .meta-item': {
								display: 'flex',
								alignItems: 'center',
								color: 'text.grey',
								fontWeight: 600,
								marginRight: '5px',
								'& .meta-icon': { fontSize: '18px', marginRight: '5px' },
								'& .meta-text': {},
							},
							'& .course-time': {
								color: 'text.grey',
							},
							'& .course-desc': {
								color: 'text.grey',
								fontWeight: 400,
							},
						},
					},
					'& .availabilities': {
						// marginTop: '5px',
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
						'& .meta-item': {
							margin: '5px',
							marginLeft: 0,
						},
						'& .no-times': {
							color: 'secondary.main',
							fontStyle: 'italic',
							fontSize: '14px',
						},
						'& .availability': {
							margin: '2px',
							marginLeft: '0px',
							fontSize: '11px',
							'& .course-type-icon': {
								backgroundColor: 'rgba(0,0,0,.3)',
								height: '100%',
								fontSize: '10px',
								display: 'flex',
								alignItems: 'center',
								borderRadius: '24px',
								marginLeft: '-5px',
								padding: '0 7px',
								border: '2px solid rgba(0,0,0,.1)',
							},
						},
					},
					'& .temp-bookings': {
						display: 'flex',
						columnGap: '10px',
						rowGap: '10px',
						flexWrap: 'wrap',
						'& .temp-booking': {
							backgroundColor: 'secondary.main',
							width: { xs: '100%', sm: 'calc(50% - 5px)' },
							flexShrink: 0,
							padding: '20px',
							color: '#FFF',
							borderRadius: '10px',
							textAlign: 'center',
							'& .MuiListItemIcon-root': {
								minWidth: '40px',
							},
						},
					},
				},
			},
			'& .content': {
				color: 'text.grey',
				margin: '20px 0px',
				'& .text-content': { fontWeight: 400 },
			},
		},
	},
	tutorElement: {
		width: 'calc(100% - 40px)',
		maxWidth: '600px',
		borderRadius: '20px',
		margin: '20px',
		display: 'inline-block',
		verticalAlign: 'top',
		'& .tutor-banner': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			backgroundColor: 'primary.main',
			color: '#FFF',
			flexShrink: 0,
			width: '100%',
			borderRadius: '10px 10px 50% 50%',
			padding: '50px 80px 80px',
			'& .tutor-pic': {
				width: '80px',
				height: '80px',
				fontSize: '40px',
			},
			'& .title': {
				margin: '10px 0',
				letterSpacing: '1px',
				'& .tutor-name': { color: '#FFF' },
				'& .rate-per-hour': {
					color: 'secondary.main',
					fontSize: '0.8em',
				},
			},
			'& .options': { marginBottom: '-20px' },
		},
		'& .tutor-details': {
			'& .content': {
				textAlign: 'center',
				padding: {
					xs: '30px 20px 40px',
					sm: '30px 40px 40px',
					md: '40px 80px 60px',
				},
				color: 'text.grey',
				'& .MuiTypography-root': {
					fontWeight: 400,
				},
			},
			'& .rate': {
				position: { xs: 'relative', sm: 'absolute' },
				top: { xs: 0, sm: '15px' },
				right: { xs: 0, sm: '15px' },
				fontSize: '20px',
				margin: 0,
				marginBottom: { xs: '15px', sm: '0px' },
				height: '50px',
				borderRadius: '60px',
				letterSpacing: '1px',
				backgroundColor: '#FFF',
				color: 'rgba(0,0,0,.82)',
			},
			'& .options': {
				paddingLeft: '15px',
				backgroundColor: 'primary.main',
				borderRadius: '0px 0px 10px 10px',
				display: 'flex',
				alignItems: 'center',
				height: '60px',
			},
		},
		'& .tutor-bio': {
			backgroundColor: '#FFF',
			color: '#000',
			'& p': {
				fontSize: { xs: '14px', sm: '18px' },
				margin: 0,
				padding: { xs: '40px 30px', sm: '40px 60px 50px' },
				letterSpacing: '1px',
			},
			'& .tutor-timeslots': {
				padding: '20px 30px',
				'& .day-of-week': {
					marginBottom: '20px',
					borderRadius: '20px',
					backgroundColor: '#EEE',
					padding: '20px 20px 30px',
					'& .day': {
						fontSize: '16px',
						textTransform: 'uppercase',
						letterSpacing: '1px',
						fontWeight: 500,
						marginBottom: '10px',
					},
				},
			},
		},
	},
	tutorStudent: {
		padding: { xs: '20px', sm: '40px' },
		borderRadius: '10px',
		'& .tutor-title': {
			textAlign: 'center',
			'& .tutor-image': {
				width: '80px',
				height: '80px',
				borderRadius: '50%',
			},
			'& .tutor-name': {},
		},
		'& .tutor-options': { textAlign: 'center' },
		'& .tutor-enquiry': {
			backgroundColor: 'primary.light',
			padding: { xs: '20px', sm: '20px 40px' },
			borderRadius: '10px 10px 0 0',
			marginTop: '20px',
			color: 'secondary.main',
			'& .time-sent': {
				color: 'text.grey',
				textTransform: 'uppercase',
				fontSize: '12px',
				letterSpacing: '1px',
			},
		},
		'& .tutor-action': {
			padding: { xs: '20px', sm: '30px 40px 20px' },
			backgroundColor: 'secondary.main',
			borderRadius: '0 0 10px 10px',
		},
	},
	tutorAdminTrialBookings: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: '800px',
		margin: '30px auto',
		'& .student-trial-booking': {
			padding: '40px',
			borderRadius: '10px',
			width: '100%',
			maxWidth: '350px',
			margin: '20px',
			textAlign: 'center',
		},
	},
	adminStudentsList: {
		maxWidth: '800px',
		margin: '30px auto',
	},
	table: {
		backgroundColor: 'rgba(255,255,255,.1)',
		backgroundImage: 'none',
		'& .MuiTableCell-root': { fontSize: '1rem' },
		'& .table-row': {
			'&:hover': { backgroundColor: 'primary.light', cursor: 'pointer' },
			'& .MuiTableCell-root': {
				color: 'text.secondary',
				fontWeight: 500,
			},
			'&:last-child td, &:last-child th': { border: 0 },
		},
	},
};

export default styles;
