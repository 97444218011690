/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Chip, TextField } from '@mui/material';
import DifficultyRating from './DifficultyRating';
import { SelectInstitutionByBlock } from '../../Institutions';
import MapRenderer from '../../MapRenderer';
import styles from './styles';
/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
export default function MetaDataTags({ block, question, setQuestion, mode }) {
	const updateSpecificTag = (tagType, tagValue) => {
		if (mode === 'add') localStorage.setItem(tagType, tagValue);
		setQuestion((prev) => ({
			...prev,
			[tagType]: tagValue,
		}));
	};

	return (
		<Box id="meta-data-tags" sx={styles.questionOptions}>
			{mode !== 'add' && (
				<React.Fragment>
					{isEmpty(question.node_id) && (
						<Box className="text-upper-spaced" sx={{ backgroundColor: 'error.main', fontWeight: 600, color: '#FFF', padding: '5px 10px' }}>
							You have not tagged this question to a node yet.
						</Box>
					)}
					<Box sx={{ height: '400px' }}>
						<MapRenderer block={block} module="questions" withFooter={false} multi={false} initialNodeId={question.node_id} onChangeCheckedNode={(nodeId) => updateSpecificTag('node_id', nodeId)} />
					</Box>
				</React.Fragment>
			)}
			<Box className="question-option">
				<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.difficulty) })}>
					<Box className="tag-name">Difficulty</Box>
				</Box>
				<Box className="question-option__selection">
					<DifficultyRating value={isEmpty(question.difficulty) ? null : question.difficulty} readOnly={false} onChange={(newValue) => updateSpecificTag('difficulty', newValue)} />
				</Box>
			</Box>
			<Box className="question-option">
				<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.likelihood) })}>
					<Box className="tag-name">Likelihood</Box>
				</Box>
				<Box className="question-option__selection">
					<Chip color="primary" className="text-upper-spaced" variant={question.likelihood === 3 ? 'filled' : 'outlined'} label="Very Likely" onClick={() => updateSpecificTag('likelihood', 3)} />
					<Chip color="primary" className="text-upper-spaced" variant={question.likelihood === 2 ? 'filled' : 'outlined'} label="Fairly Likely" onClick={() => updateSpecificTag('likelihood', 2)} />
					<Chip color="primary" className="text-upper-spaced" variant={question.likelihood === 1 ? 'filled' : 'outlined'} label="Unlikely" onClick={() => updateSpecificTag('likelihood', 1)} />
				</Box>
			</Box>
			<Box className="question-option">
				<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.reference_source) })}>
					<Box className="tag-name">Source</Box>
				</Box>
				<Box className="question-option__selection">
					<Chip color="primary" className="text-upper-spaced" variant={question.reference_source === 'original' ? 'filled' : 'outlined'} label="original" onClick={() => updateSpecificTag('reference_source', 'original')} />
					<Chip color="primary" className="text-upper-spaced" variant={question.reference_source === 'copied' ? 'filled' : 'outlined'} label="copied" onClick={() => updateSpecificTag('reference_source', 'copied')} />
					<Chip color="primary" className="text-upper-spaced" variant={question.reference_source === 'linked' ? 'filled' : 'outlined'} label="linked" onClick={() => updateSpecificTag('reference_source', 'linked')} />
				</Box>
			</Box>
			{(question.reference_source === 'copied' || question.reference_source === 'linked') && (
				<React.Fragment>
					<Box className="question-option">
						<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.reference_type) })}>
							<Box className="tag-name">Reference Type</Box>
						</Box>
						<Box className="question-option__selection">
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A1' ? 'filled' : 'outlined'} label="A1" onClick={() => updateSpecificTag('reference_type', 'A1')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A2' ? 'filled' : 'outlined'} label="A2" onClick={() => updateSpecificTag('reference_type', 'A2')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A3' ? 'filled' : 'outlined'} label="A3" onClick={() => updateSpecificTag('reference_type', 'A3')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A4' ? 'filled' : 'outlined'} label="A4" onClick={() => updateSpecificTag('reference_type', 'A4')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A5' ? 'filled' : 'outlined'} label="A5" onClick={() => updateSpecificTag('reference_type', 'A5')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'A6' ? 'filled' : 'outlined'} label="A6" onClick={() => updateSpecificTag('reference_type', 'A6')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'HY' ? 'filled' : 'outlined'} label="Mid Year Exam" onClick={() => updateSpecificTag('reference_type', 'HY')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'MS' ? 'filled' : 'outlined'} label="Mid Sem Exam" onClick={() => updateSpecificTag('reference_type', 'MS')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'EY' ? 'filled' : 'outlined'} label="Yearly Exam" onClick={() => updateSpecificTag('reference_type', 'EY')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'ES' ? 'filled' : 'outlined'} label="End of Sem Exam" onClick={() => updateSpecificTag('reference_type', 'ES')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'QUIZ' ? 'filled' : 'outlined'} label="QUIZ" onClick={() => updateSpecificTag('reference_type', 'QUIZ')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'LECTURE' ? 'filled' : 'outlined'} label="LECTURE" onClick={() => updateSpecificTag('reference_type', 'LECTURE')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'WORKSHOP' ? 'filled' : 'outlined'} label="WORKSHOP" onClick={() => updateSpecificTag('reference_type', 'WORKSHOP')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'TUTORIAL' ? 'filled' : 'outlined'} label="TUTORIAL" onClick={() => updateSpecificTag('reference_type', 'TUTORIAL')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'TEXTBOOK' ? 'filled' : 'outlined'} label="TEXTBOOK" onClick={() => updateSpecificTag('reference_type', 'TEXTBOOK')} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_type === 'WEBSITE' ? 'filled' : 'outlined'} label="WEBSITE" onClick={() => updateSpecificTag('reference_type', 'WEBSITE')} />
						</Box>
					</Box>
					{!['TEXTBOOK', 'WEBSITE'].includes(question.reference_type) && (
						<React.Fragment>
							<Box className="question-option">
								<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.reference_institution) })}>
									<Box className="tag-name">Institution</Box>
								</Box>
								<Box className="question-option__selection full-width">
									<SelectInstitutionByBlock blockId={question.block_id} initialInstitutionId={question.reference_institution} onChange={(institutionId) => updateSpecificTag('reference_institution', institutionId)} />
								</Box>
							</Box>
							<Box className="question-option">
								<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.reference_question_number) })}>
									<Box className="tag-name">Question Number</Box>
								</Box>
								<Box className="question-option__selection">
									<TextField fullWidth className="text-input" variant="outlined" placeholder={`Type the question number`} value={question.reference_question_number} onChange={(e) => updateSpecificTag('reference_question_number', e.target.value)} error={!['TEXTBOOK', 'WEBSITE'].includes(question.reference_type) && isEmpty(question.reference_question_number)} helperText={!['TEXTBOOK', 'WEBSITE'].includes(question.reference_type) && isEmpty(question.reference_question_number) && 'This field cannot be empty'} />
								</Box>
							</Box>
							<Box className="question-option">
								<Box className={clsx('question-option__type', 'text-upper-spaced', { 'with-error': isEmpty(question.reference_year) })}>
									<Box className="tag-name">Year</Box>
								</Box>
								<Box className="question-option__selection">
									<TextField fullWidth type="number" className="text-input" variant="outlined" placeholder={`Type year (e.g. 2023)`} value={question.reference_year} onChange={(e) => updateSpecificTag('reference_year', e.target.value)} error={isEmpty(question.reference_year)} helperText={isEmpty(question.reference_year) && 'This field cannot be empty'} />
								</Box>
							</Box>
						</React.Fragment>
					)}
					<Box className="question-option">
						<Box className="question-option__type text-upper-spaced">Reference Name</Box>
						<Box className="question-option__selection full-width">
							<TextField fullWidth className="text-input" variant="outlined" placeholder={`Does this reference have a name?`} value={question.reference_name} onChange={(e) => updateSpecificTag('reference_name', e.target.value)} />
						</Box>
					</Box>
					<Box className="question-option">
						<Box className="question-option__type text-upper-spaced">Reference Link</Box>
						<Box className="question-option__selection full-width">
							<TextField fullWidth className="text-input" variant="outlined" placeholder={`Does this reference have a URL link?`} value={question.reference_link} onChange={(e) => updateSpecificTag('reference_link', e.target.value)} />
						</Box>
					</Box>
				</React.Fragment>
			)}
			{question.reference_source === 'copied' && (
				<Box className="question-option">
					<Box className="question-option__type text-upper-spaced">Question Changes</Box>
					<Box className="question-option__selection">
						<Box className="question-option__selection__instruction-text text-upper-spaced">Did you make any changes to the original question?</Box>
						<Box>
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_copy_updated === true ? 'filled' : 'outlined'} label="Yes" onClick={() => updateSpecificTag('reference_copy_updated', true)} />
							<Chip color="primary" className="text-upper-spaced" variant={question.reference_copy_updated === false ? 'filled' : 'outlined'} label="No" onClick={() => updateSpecificTag('reference_copy_updated', false)} />
						</Box>
					</Box>
				</Box>
			)}
			<Box className="question-option">
				<Box className="question-option__type text-upper-spaced">Comment</Box>
				<Box className="question-option__selection full-width">
					<TextField fullWidth multiline className="text-input" sx={{ '& .MuiOutlinedInput-root': { borderRadius: '4px !important' } }} variant="outlined" placeholder={`Do you have any additional comments to add?`} value={question.reference_comment} onChange={(e) => updateSpecificTag('reference_comment', e.target.value)} />
				</Box>
			</Box>
		</Box>
	);
}
