/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData } from '../../data';
import { isEmpty, useKey } from '../../utils';
import { updateUserName } from '../AuthData';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Box, Typography, Button } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../components/Messages';
import styles from './styles';
import { useAuth } from '../AuthProvider';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function UpdatePassForm() {
	const history = useHistory();
	const { appUser, setAppUser } = useAuth();
	const [state, setState] = React.useState({
		name: { value: appUser.name, error: false, message: '' },
	});
	const [formState, setFormState] = React.useState(InitialData.completed);
	useKey('Enter', () => handleSubmit());

	const handleName = (e) => {
		e.persist();
		const name = e.target.value;
		const data = { value: name, error: false, message: '' };
		if (isEmpty(name)) {
			data.error = true;
			data.message = 'Name field cannot be empty.';
		}
		if (!validator.isAlpha(name, undefined, { ignore: ' ' })) {
			data.error = true;
			data.message = 'Name field can only contain letters.';
		}
		setState((prev) => ({ ...prev, name: data }));
	};

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) handleSubmit();
	};

	const handleSubmit = async () => {
		if (checkErrors()) return;
		setFormState(InitialData.loading);
		const res = await updateUserName(appUser.id, state.name.value);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setAppUser(res.data);
		setFormState({ ...InitialData.completed, data: res.data });
	};

	const checkErrors = () => {
		if (isEmpty(state.name.value) || state.name.error === true) {
			return true;
		}
		return false;
	};

	return (
		<Box id="login-form" sx={styles.authForm}>
			{isEmpty(formState.data) && (
				<React.Fragment>
					<TextField fullWidth label="Name" className="text-field-light" variant="filled" color="primary" error={state.name.error} helperText={state.name.message} value={state.name.value} onChange={handleName} />
					{formState.error && <ApiErrorMessage className="error-message" errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
					<LoadingButton fullWidth className="btn-light" color="secondary" disabled={checkErrors()} loading={formState.loading} variant="contained" onClick={handleSubmit} onKeyDown={handleKeyDown}>
						{formState.loading === true ? 'Updating Name ...' : 'Update Name'}
					</LoadingButton>
				</React.Fragment>
			)}
			{!isEmpty(formState.data) && (
				<React.Fragment>
					<Typography color="secondary" className="heading-font instruction-title" variant="h6" sx={{ marginBottom: '30px' }}>
						Yay! Your new name has been set to: {formState.data.name} 💜
					</Typography>
					<Button fullWidth variant="contained" color="secondary" startIcon={<HomeIcon />} onClick={() => history.push('/')}>
						Go Home
					</Button>
				</React.Fragment>
			)}
		</Box>
	);
}
