/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Accordion, AccordionSummary, IconButton, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, VisibilityOff as HideMcIcon, Visibility as ShowMcIcon } from '@mui/icons-material';
import MarkdownRender from '../../MarkdownRender';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
const MULTI_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export default function QuestionMcOptions({ question, adminViewMode, handleUserAction }) {
	const [userState, setUserState] = React.useState({
		hiddenOptions: [],
		expandedOptions: [],
	});

	React.useEffect(() => {
		if (adminViewMode === true) {
			const expandedOptions = [];
			question.question_options.forEach((multiChoiceOption, multiIndex) => {
				if (!isEmpty(multiChoiceOption.solutionText) && !expandedOptions.includes(multiIndex)) expandedOptions.push(multiIndex);
			});
			setUserState((prev) => ({
				...prev,
				expandedOptions: expandedOptions,
			}));
		} else if (adminViewMode === false && !isEmpty(question.question_performance)) {
			const expandedOptions = [];
			if (question.question_performance.checked === true) {
				// Check if the correctMcIndex has solutionText
				const correctIndex = question.question_options.findIndex((el) => el.is_correct === true);
				const correctAnswer = question.question_options[correctIndex].solution_text;
				if (!isEmpty(correctAnswer)) expandedOptions.push(correctIndex);
				// Then check to see if there is a selected answer by the user
				if ('mc_option_id' in question.question_performance) {
					const userSelectedIndex = question.question_options.findIndex((el) => el.id === question.question_performance.mc_option_id);
					const userSelectedAnswer = question.question_options[userSelectedIndex].solution_text;
					if (!isEmpty(userSelectedAnswer)) expandedOptions.push(userSelectedIndex);
				}
			}
			setUserState((prev) => ({
				...prev,
				hiddenOptions: [],
				expandedOptions: expandedOptions,
			}));
		} else {
			setUserState({ hiddenOptions: [], expandedOptions: [] });
		}
	}, [question, adminViewMode]);

	const handleExpandMcOption = (multiChoiceOptionId, solutionText) => {
		console.log(question.question_performance);
		if (adminViewMode === true) {
			return;
		} else if (!('checked' in question.question_performance) || question.question_performance.checked === false) {
			handleUserAction('selectedMcOption', multiChoiceOptionId);
		} else if (question.question_performance.checked === true && isEmpty(solutionText)) {
			return;
		} else if (question.question_performance.checked === true && !isEmpty(solutionText)) {
			const multiIndex = question.question_options.findIndex((el) => el.id === multiChoiceOptionId);
			setUserState((prev) => ({
				...prev,
				expandedOptions: prev.expandedOptions.includes(multiIndex) ? prev.expandedOptions.filter((el) => el !== multiIndex) : [...prev.expandedOptions, multiIndex],
			}));
		}
	};

	const handleMcSolution = (solutionText) => {
		if (isEmpty(solutionText) && isEmpty(question.overall_solution_text)) {
			return 'Sorry, there is no explanation for this question';
		} else if (isEmpty(solutionText) && !isEmpty(question.overall_solution_text)) {
			return 'Please refer to the overall solution.';
		} else if (!isEmpty(solutionText) && !isEmpty(question.overall_solution_text)) {
			return solutionText + '\nFor further explanation, please refer to the overall solution.';
		} else {
			return solutionText;
		}
	};

	return (
		<Box sx={styles.mcOptionsUser}>
			{question.question_options.map((multiChoiceOption, multiIndex) => {
				return (
					<Accordion
						key={`mc-option-${multiIndex}`}
						className={clsx('mc-option', {
							adminViewMode: adminViewMode,
							checkedAnswer: question.question_performance.checked === true,
							hiddenOption: userState.hiddenOptions.includes(multiIndex),
							selectedOption: question.question_performance.mc_option_id === multiChoiceOption.id,
							correctOption: (question.question_performance.checked === true || adminViewMode === true) && multiChoiceOption.is_correct === true,
							incorrectOption: question.question_performance.checked === true && question.question_performance.mc_option_id === multiChoiceOption.id && multiChoiceOption.is_correct !== true,
						})}
						expanded={userState.expandedOptions.includes(multiIndex) ? true : false}
						onClick={() => handleExpandMcOption(multiChoiceOption.id, multiChoiceOption.solution_text)}
					>
						<AccordionSummary className="option-content" expandIcon={(userState.checkedAnswer === true || adminViewMode === true) && !isEmpty(multiChoiceOption.solutionText) ? <ExpandMoreIcon className="option-content__action-btn" /> : null}>
							<Box className="option-content__main">
								<div className={clsx('option-content__num', { selected: question.question_performance.mc_option_id === multiChoiceOption.id })}>{MULTI_LETTERS[multiIndex]}</div>
								<MarkdownRender className="option-content__text" children={multiChoiceOption.question_text} />
							</Box>
							{(question.question_performance.checked === false || isEmpty(question.question_performance.checked)) && adminViewMode === false && question.question_performance.mc_option_id !== multiChoiceOption.id && (
								<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<IconButton
										sx={{ flexShrink: 0 }}
										className="option-content__action-btn"
										size="small"
										disabled={question.question_performance.mc_option_id === multiChoiceOption.id}
										onClick={(e) => {
											e.stopPropagation();
											setUserState((prev) => ({
												...prev,
												hiddenOptions: prev.hiddenOptions.includes(multiIndex) ? prev.hiddenOptions.filter((el) => el !== multiIndex) : [...prev.hiddenOptions, multiIndex],
											}));
										}}
									>
										{userState.hiddenOptions.includes(multiIndex) ? <HideMcIcon /> : <ShowMcIcon />}
									</IconButton>
								</Box>
							)}
						</AccordionSummary>
						<AccordionDetails
							className="option-solution"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Box className="option-solution__title text-upper-spaced">Explanation</Box>
							<MarkdownRender children={handleMcSolution(multiChoiceOption.solution_text)} />
						</AccordionDetails>
					</Accordion>
				);
			})}
		</Box>
	);
}
