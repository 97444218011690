const styles = {
	subscriptionOptions: {
		flexGrow: 1,
		backgroundColor: 'primary.main',
		padding: '20px',
		paddingBottom: '60px',
		'& .pricing-banner': {
			textAlign: 'center',
			color: '#FFF',
			maxWidth: '600px',
			margin: '20px auto 30px',
			'& .mini-title': { color: 'secondary.main', letterSpacing: '1px', fontSize: '16px', textTransform: 'uppercase', marginBottom: '10px' },
			'& .main-title': { marginBottom: '20px', lineHeight: '1.4' },
			'& .sub-title': { lineHeight: '1.6', color: 'text.grey', '& .emphasise-block': { marginLeft: '7px', color: 'secondary.main' } },
		},
		'& .pricing-plans': {
			display: 'flex',
			justifyContent: 'center',
			flexWrap: 'wrap',
			'& .plan': {
				color: '#FFF',
				backgroundColor: 'rgba(0,0,0,.1)',
				padding: '40px',
				width: '300px',
				flexShrink: 0,
				border: '4px solid',
				borderColor: '#FFF',
				margin: '20px',
				borderRadius: '20px',
				'& .plan-duration': { color: 'secondary.main', marginBottom: '10px' },
				'& .plan-price': {
					marginBottom: '10px',
					'& .main-price': {
						'& .pay-frequency': {
							fontSize: '50%',
							color: 'text.grey',
						},
						marginBottom: '10px',
					},
					'& .payment-terms': {
						color: 'text.grey',
						fontSize: '14px',
					},
				},
			},
		},
	},
	subscribeNow: {
		'& .plans': {
			textAlign: 'center',
			'& .plan': {
				margin: '20px auto',
				borderRadius: '20px',
				cursor: 'pointer',
				position: 'relative',
				textAlign: 'center',
				width: '100%',
				minHeight: '200px',
				backgroundColor: 'primary.main',
				color: '#FFF',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				'& .plan-duration': {
					marginBottom: '10px',
				},
				'& .save-chip': {
					position: 'absolute',
					top: '10px',
					right: '10px',
				},
				'& .plan-price': {},
				'&:hover, &.active': {
					backgroundColor: 'secondary.main',
					color: '#FFF',
					'& .save-chip': {
						backgroundColor: '#FFF',
						color: 'primary.main',
					},
				},
			},
		},
	},
};

export default styles;
