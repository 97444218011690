/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
const Sx = {
	homeFooter: (white) => ({
		width: '100%',
		padding: '20px 20px 15px',
		display: 'flex',
		justifyContent: { xs: 'center', sm: 'space-between' },
		flexWrap: 'wrap',
		minHeight: '50px',
		'& .group-links': {
			display: 'flex',
			justifyContent: 'space-evenly',
			flexWrap: 'wrap',
			alignItems: 'center',
			paddingBottom: '5px',
			'& .link': {
				padding: '0 15px',
				whiteSpace: 'nowrap',
				color: white === true ? '#FFF' : 'primary.main',
				textDecoration: 'none',
				fontWeight: 500,
				fontSize: '14px',
				'&:hover': { color: 'secondary.main' },
			},
		},
	}),
};
HomeFooter.defaultProps = {
	white: false,
	sx: {},
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function HomeFooter({ white, sx }) {
	return (
		<Box id="home-footer" sx={() => ({ ...Sx.homeFooter(white), ...sx })}>
			<Box className="group-links">
				<a className="link" href="/support/terms">
					Terms
				</a>
				<a className="link" href="/support/contact">
					Contact
				</a>
			</Box>
			<Box className="group-links">
				<a className="link" href="/support/copyright">
					Copyright © {new Date().getFullYear()} Learnblock
				</a>
			</Box>
		</Box>
	);
}
