/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Link } from '@mui/material';
import { SocialMediaLinks } from '../../@core/Social';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function Contact() {
	return (
		<Box sx={styles.helpBanner}>
			<Typography className="heading-font main-title" align="center" variant="h3">
				Contact
			</Typography>
			<Typography className="heading-font sub-heading" align="center" variant="h5">
				We're always here to help.
			</Typography>
			<Typography className="blurb-text main-blurb" align="center" variant="h6">
				If you experience any problems or have any questions, send us an email at <Link href="mailto:support@learnblock.com">support@learnblock.com</Link> or connect with us on social media 💜
			</Typography>
			<SocialMediaLinks />
		</Box>
	);
}
