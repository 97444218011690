/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
import { loadStripe } from '@stripe/stripe-js';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData, SubscriptionData } from '../../data';
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
import { CreditCard as PayIcon, AdminPanelSettings as ManagePlanIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage, LoadingPageMessage, NoDataMessage } from '../Messages';
import { AnimationParticles } from '../../@core/Animation';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/

// else if (!isEmpty(appUser) && query.payment === 'success') {
//     const sx = { ...baseStyle, backgroundColor: 'success.main', color: '#FFF' };
//     return (
//         <Box sx={sx} className="text-upper-spaced">
//             {block.is_subscribed === true && 'Thanks for supporting us! You are subscribed 🤍'}
//             {block.is_subscribed === false && 'Sorry, sometimes payments take a minute to register on our system. Refresh this page to check your subscription status.'}
//         </Box>
//     );
// } else if (!isEmpty(appUser) && block.is_subscribed === false && query.payment === 'cancel') {
//     const sx = { ...baseStyle, backgroundColor: 'error.main', color: '#FFF', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } };
//     return (
//         <Box sx={sx} className="text-upper-spaced">
//             That's ok that you cancelled! When you're ready, we're just a click away 😊
//         </Box>
//     );
// }

export default function SubscriptionOptions({ block }) {
	const [plans, setPlans] = React.useState(InitialData.loading);
	const [activePriceId, setActivePriceId] = React.useState('');
	const [checkingOut, setCheckingOut] = React.useState({ ...InitialData.completed, price_id: '' });
	const [manageSubscription, setManageSubscription] = React.useState(InitialData.completed);

	React.useEffect(() => {
		if (block.is_subscribed === false) {
			getBlockPrices(block.id);
		}

		return () => {
			setPlans(InitialData.loading);
		};
	}, [block]);

	const getBlockPrices = async (block_id) => {
		setPlans(InitialData.loading);
		const res = await SubscriptionData.getStripePrices(block_id);
		if (!res.success) return setPlans({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setPlans({
			...InitialData.completed,
			data: res.data
				.map((el) => ({
					price_id: el.id,
					product_id: el.product,
					dollar_amount: el.unit_amount / 100,
					currency: el.currency,
					numMonths: el.type === 'one_time' ? Infinity : el.recurring.interval === 'year' ? 12 : el.recurring.interval_count,
				}))
				.sort((a, b) => a.numMonths - b.numMonths),
		});
	};

	const handleCheckout = async (priceId) => {
		setCheckingOut({ ...InitialData.loading, price_id: priceId });
		const res = await SubscriptionData.createStripeCheckout(block.id, priceId);
		if (!res.success) return setCheckingOut({ ...InitialData.error, errorCode: res.code, errorMessage: res.message, price_id: priceId });
		const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
		await stripe.redirectToCheckout({ sessionId: res.data.id });
	};

	const handleManagePlan = async () => {
		setManageSubscription(InitialData.loading);
		const res = await SubscriptionData.getStripeCustomerPortal(block.code);
		if (!res.success) return setManageSubscription({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		window.location.href = res.data.url;
	};

	return (
		<Box sx={styles.subscriptionOptions}>
			<AnimationParticles />
			<Box className="pricing-banner">
				<Typography className="mini-title">
					{block.is_subscribed === false && 'Pricing'}
					{block.is_subscribed === true && `You're subscribed to ${block.name}`}
				</Typography>
				<Typography className="main-title heading-font" align="center" variant="h3">
					{block.is_subscribed === false && 'Choose the right plan for you.'}
					{block.is_subscribed === true && `Thanks for Subscribing`}
				</Typography>
				<Typography className="sub-title heading-font" align="center" variant="h5">
					{block.is_subscribed === false && (
						<span>
							<span>We believe in transparent pricing. All our plans come with unlimited features. You just pay for how long you want access to</span>
							<span className="emphasise-block">{block.name}</span>.
						</span>
					)}
					{block.is_subscribed === true && <span>Subscriptions on Learnblock are managed by Stripe. To securely manage your plan click the button below and we'll redirect you to the Stripe Portal.</span>}
				</Typography>
			</Box>
			{block.is_subscribed === true && (
				<Box sx={{ textAlign: 'center', maxWidth: '600px', margin: 'auto' }}>
					<LoadingButton sx={{ '&.Mui-disabled': { backgroundColor: 'rgba(255,255,255,.1)', color: 'rgba(255,255,255,.3)', '& .MuiLoadingButton-loadingIndicator': { color: 'secondary.main' } } }} startIcon={<ManagePlanIcon />} loading={manageSubscription.loading} variant="contained" color="secondary" onClick={handleManagePlan}>
						Manage My Plan
					</LoadingButton>
					{manageSubscription.error && <ApiErrorMessage sx={{ marginTop: '20px' }} errorCode={manageSubscription.errorCode} errorMessage={manageSubscription.errorMessage} />}
				</Box>
			)}
			{block.is_subscribed === false && (
				<React.Fragment>
					{plans.loading && <LoadingPageMessage />}
					{!plans.loading && plans.error && <ApiErrorMessage errorCode={plans.errorCode} errorMessage={plans.errorMessage} />}
					{!plans.loading && !plans.error && isEmpty(plans.data) && <NoDataMessage customMessage={'Sorry, there was an error returning the plans for this block. Please try again later or contact support@learnblock.com'} />}
					{!plans.loading && !plans.error && !isEmpty(plans.data) && (
						<Box className="pricing-plans">
							{plans.data.map((plan, planIndex) => {
								return (
									<Box key={`plan-${plan.price_id}`} className={clsx('box-shadow2', 'plan', { active: activePriceId === plan.price_id })} onClick={() => setActivePriceId(plan.price_id)}>
										{/* {planIndex > 0 && <Chip className="save-chip text-upper-spaced" color="secondary" variant="filled" label={`Save $${plans.data[0].dollar_amount * plan.numMonths - plan.dollar_amount}!`} />} */}
										<Box className="plan-duration">
											<Typography className="heading-font" variant="h5">
												{plan.numMonths === Infinity ? 'Lifetime Pass' : `${plan.numMonths} Month Pass`}
											</Typography>
										</Box>
										<Box className="plan-price">
											{plan.numMonths === Infinity ? (
												<Typography className="main-price heading-font" variant="h4">
													<span className="dollar-amount">${plan.dollar_amount}</span>
													<span className="pay-frequency"> / life *</span>
												</Typography>
											) : (
												<Typography className="main-price heading-font" variant="h4">
													<span className="dollar-amount">${plan.dollar_amount / plan.numMonths}</span>
													<span className="pay-frequency"> / month *</span>
												</Typography>
											)}
											<span className="heading-font payment-terms">
												{plan.numMonths === 1 && '* renews monthly, cancel anytime'}
												{plan.numMonths === 6 && `* $${plan.dollar_amount} billed for ${plan.numMonths} months, renews bi-annually`}
												{plan.numMonths === 12 && `* $${plan.dollar_amount} billed for ${plan.numMonths} months, renews yearly`}
												{plan.numMonths === Infinity && '* pay once and have access for life'}
											</span>
										</Box>
										<LoadingButton startIcon={<PayIcon />} disabled={checkingOut.loading} loading={checkingOut.loading && checkingOut.price_id === plan.price_id} fullWidth color="secondary" variant="contained" onClick={() => handleCheckout(plan.price_id)}>
											Buy Plan
										</LoadingButton>
									</Box>
								);
							})}
						</Box>
					)}
				</React.Fragment>
			)}
		</Box>
	);
}
