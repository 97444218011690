/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Checkbox, SvgIcon, Box, Typography } from '@mui/material';
import { FiberManualRecord as EndIcon } from '@mui/icons-material';
import styles from './styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { alpha, styled } from '@mui/material/styles';
import { NodeTreeContext } from '../utils/treeHelpers';
/****************************************************************************************************
 * HELPERS
 ****************************************************************************************************/
function MinusSquare(props) {
	return (
		<SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
		</SvgIcon>
	);
}

function PlusSquare(props) {
	return (
		<SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			<path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
		</SvgIcon>
	);
}

function CloseSquare(props) {
	return (
		<SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
			{/* tslint:disable-next-line: max-line-length */}
			{/* <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" /> */}
			<EndIcon />
		</SvgIcon>
	);
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	[`& .${treeItemClasses.content}`]: {
		padding: '4px 8px',
	},
	[`& .${treeItemClasses.label}`]: {
		textTransform: 'uppercase',
		fontSize: '14px !important',
		letterSpacing: '1px !important',
	},
	[`& .${treeItemClasses.iconContainer}`]: {
		'& .close': {
			opacity: 0.3,
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 15,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
	},
}));

const StyledTreeItem = ({ bgColor, color, nodeId, node, labelIcon: LabelIcon, labelInfo, labelText, ...other }) => {
	const { checkedNodes, onCheckNode } = React.useContext(NodeTreeContext);
	return (
		<StyledTreeItemRoot
			nodeId={nodeId}
			label={
				<Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
					<Checkbox
						color="primary"
						sx={{
							padding: '0px',
							'& .MuiSvgIcon-root': {
								width: '16px',
								height: '16px',
							},
						}}
						checked={checkedNodes.findIndex((checked) => checked.id === nodeId) >= 0}
						onChange={(event) => {
							onCheckNode(node);
						}}
						onClick={(e) => e.stopPropagation()}
					/>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
					<Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
						{labelText}
					</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</Box>
			}
			{...other}
		/>
	);
};

/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function TopicTree({ nodes }) {
	const { checkedNodes, onToggleNode } = React.useContext(NodeTreeContext);
	const root_id = 'b6c54489-38a0-5f50-a60a-fd8d76219cae';
	const [expanded, setExpanded] = React.useState([root_id]);

	React.useEffect(() => {
		const expandedNodes = findExpandedNodes(nodes);
		setExpanded(expandedNodes);
	}, []);

	const findExpandedNodes = (node) => {
		const expandedIds = [];
		function dfs(currentNode) {
			if (currentNode.data.expanded === true) expandedIds.push(currentNode.data.id);
			if (currentNode.data.children !== null) {
				for (const child of currentNode.data.children) {
					dfs(child);
				}
			}
		}
		dfs(node);
		return expandedIds;
	};

	const handleNodeFocus = (event, value) => {
		if (value === root_id) return;
		onToggleNode(event, { id: value });
	};

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
	};

	const renderTree = (nodes) => {
		const { data } = nodes;
		return (
			<StyledTreeItem key={data.id} nodeId={data.id} labelText={data.name} labelInfo={data.questions_count} node={data}>
				{Array.isArray(data.children) ? data.children.map((node) => renderTree(node)) : null}
			</StyledTreeItem>
		);
	};

	return (
		<Box id="tree-container" sx={{ ...styles.topicsList, minHeight: 'calc(100vh - 160px)' }}>
			<TreeView defaultCollapseIcon={<MinusSquare />} defaultExpandIcon={<PlusSquare />} defaultEndIcon={<CloseSquare />} selected={checkedNodes.map((el) => el.id)} expanded={expanded} onNodeFocus={handleNodeFocus} onNodeToggle={handleToggle} multiSelect sx={{ height: 'auto', flexGrow: 1, width: '100%', maxWidth: '600px', margin: 'auto', overflowY: 'auto', padding: '5px 0px' }}>
				{renderTree(nodes)}
			</TreeView>
		</Box>
	);
}
