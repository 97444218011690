/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
import { useAuth } from '../../auth';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, AppBar, Toolbar, Typography, Chip } from '@mui/material';
import { LogoIcon } from '../../@core/Logo';
import Contact from './Contact';
import Terms from './Terms';
import Privacy from './Privacy';
import Copyright from './Copyright';
import PaymentPolicy from './PaymentPolicy';
import { LoginRegisterBtns } from '../../auth/elements';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function HelpCenter() {
	const { view } = useParams();
	const { appUser } = useAuth();
	const history = useHistory();

	const changeView = (newView) => {
		history.push(`/support/${newView}`);
	};

	return (
		<Box>
			<AppBar position="sticky" sx={{ ...styles.header, overflow: 'auto' }}>
				<Toolbar className="block-toolbar">
					<Box className="block-icon-container">
						<LogoIcon size={40} white={true} sx={{ marginRight: '2px' }} />
					</Box>
					<Typography component="div" sx={{ textTransform: 'uppercase', letterSpacing: '1px', fontSize: '14px', marginRight: '10px' }}>
						Support Center
					</Typography>
					<Chip className="text-upper-spaced" variant={view === 'contact' ? 'contained' : 'outlined'} color="secondary" onClick={() => changeView('contact')} label="Contact" />
					<Chip className="text-upper-spaced" variant={view === 'terms' ? 'contained' : 'outlined'} color="secondary" onClick={() => changeView('terms')} label="Terms" />
					<Chip className="text-upper-spaced" variant={view === 'privacy' ? 'contained' : 'outlined'} color="secondary" onClick={() => changeView('privacy')} label="Privacy" />
					<Chip className="text-upper-spaced" variant={view === 'copyright' ? 'contained' : 'outlined'} color="secondary" onClick={() => changeView('copyright')} label="Copyright" />
					<Chip className="text-upper-spaced" variant={view === 'payments' ? 'contained' : 'outlined'} color="secondary" onClick={() => changeView('payments')} label="Payments" />
					<Box sx={{ flexGrow: 1 }}></Box>
					<Box sx={{ marginLeft: '5px', flexGrow: 0 }}>
						<LoginRegisterBtns sx={{ marginRight: !isEmpty(appUser) ? '8px' : '0px', '& .toggle-btn': { borderRadius: '0px !important' } }} />
					</Box>
				</Toolbar>
			</AppBar>
			{view === 'contact' && <Contact />}
			{view === 'terms' && <Terms />}
			{view === 'privacy' && <Privacy />}
			{view === 'copyright' && <Copyright />}
			{view === 'payments' && <PaymentPolicy />}
		</Box>
	);
}
