/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box, Typography, Paper } from '@mui/material';
import styles from './styles';
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function PaymentPolicy() {
    return (
        <Box>
            <Box sx={styles.helpBanner}>
                <Typography className='heading-font primary main-title' align="center" variant="h3">Payment Policy</Typography>
                <Typography className='heading-font sub-heading' align="center" variant="h5">Last Updated: 22nd August 2022</Typography>
                <Typography className='blurb-text main-blurb' align="center" variant="h6">
                    This page contains our payment policy. You may wish to print this page for reference.
                </Typography>
            </Box>
            <Box sx={styles.helpContent}>
                <Paper className="section">
                    Our pro plan memberships are unavailable for purchase yet. When they are made available, this policy will be updated.
                </Paper>
            </Box>
        </Box>
    )
}