/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useEffectAfterMount } from '../../utils';
import { InitialData, UploadData } from '../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { BrokenImage, Functions as MathBlockIcon, Handyman as MathIconFix } from '@mui/icons-material';
import { SnackbarAlert } from '../../@core/Alert';
import './index.css';
import styles from './styles';
import { debounce } from 'lodash';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function CodeEditorBasic({ rerender, bucket, language, initialCode, onChange, sx, style }) {
	const textRef = React.useRef();
	const [code, setCode] = React.useState('');
	const debouncedSave = React.useCallback(
		debounce((nextText) => {
			if (onChange) onChange(nextText);
		}, 500),
		[rerender]
	);
	const [uploadingImage, setUploadingImage] = React.useState(InitialData.completed);

	React.useEffect(() => {
		// const obj = new SelectionText(textRef.current);
		setCode(initialCode);
	}, [rerender]);

	useEffectAfterMount(() => {
		debouncedSave(code);
	}, [code, debouncedSave, rerender]);

	const handlePaste = async (e) => {
		// Check if the paste event contains an image
		if (e.clipboardData && e.clipboardData.items[0].type.indexOf('image') === 0) {
			e.preventDefault();
			const cursorPosition = e.target.selectionStart;
			const file = e.clipboardData.files[0];
			setUploadingImage(InitialData.loading);
			const res = await UploadData.uploadImage(bucket, file);
			if (!res.success) return setUploadingImage({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
			setUploadingImage(InitialData.completed);
			setCode((prev) => prev.slice(0, cursorPosition) + `![](LB_IMG_BASE_URL/${res.path})` + prev.slice(cursorPosition));
		}
	};

	const handleCleanupImage = () => {
		const regex1 = /https:\/\/storage\.googleapis\.com\/learnblock-storage-core\/medicine\/questions/g;
		const regex2 = /https:\/\/storage\.googleapis\.com\/learnblock-storage-core\/63a7d602756bdab9a209fe80/g;
		const regex3 = /https:\/\/storage\.googleapis\.com\/learnblock-storage-core\/63908c8b8a650125cdc049c9/g;
		const regex4 = /https:\/\/storage\.googleapis\.com\/learnblock-storage-core\/63a92b574d8bbadc7d35f599/g;
		const regex5 = /https:\/\/storage\.googleapis\.com\/learnblock-storage-core\/undefined/g;
		let newCode = code.replace(regex1, 'LB_IMG_BASE_URL');
		newCode = newCode.replace(regex2, 'LB_IMG_BASE_URL');
		newCode = newCode.replace(regex3, 'LB_IMG_BASE_URL');
		newCode = newCode.replace(regex4, 'LB_IMG_BASE_URL');
		newCode = newCode.replace(regex5, 'LB_IMG_BASE_URL');
		newCode = newCode.replace(/\[image\](.*?\.png)/g, '![](https://storage.googleapis.com/learnblock-storage-core/$1)');
		setCode(newCode);
	};

	const convertMarkdownLatex = () => {
		// let start = textRef.current.selectionStart;
		// let end = textRef.current.selectionEnd;
		// let selectedText = textRef.current.value.slice(start, end);
		const regex = /\$\$(.*?)\$\$/gs;
		const replacement = (match, group) => {
			let trimmedGroup = group.trim();
			trimmedGroup = '$$\n% solution-step#\n' + trimmedGroup + '\n$$\n';
			return trimmedGroup;
		};
		setCode((prev) => prev.replace(regex, replacement));
	};

	const insertMathBlock = () => {
		let cursorPosition = textRef.current.selectionStart;
		let mathBlock = `\n$$\n% solution-step#\n$$\n`;
		setCode((prev) => prev.slice(0, cursorPosition) + mathBlock + prev.slice(cursorPosition));
	};

	return (
		<Box
			sx={{
				marginBottom: '30px',
				position: 'relative',
				'& .code-line .title': { color: 'blue !important', fontWeight: 'bold' },
				'& .table-header.important': { color: 'blue !important' },
				...(sx && sx),
			}}
			onClick={(e) => {
				e.stopPropagation();
			}}
		>
			{!uploadingImage.loading && uploadingImage.error && <SnackbarAlert open={true} message={uploadingImage.errorMessage} handleClose={() => setUploadingImage(InitialData.completed)} severity="error" />}
			<GrammarlyEditorPlugin clientId="client_A4Ark5HEpVxPqwn14eVDqp">
				<CodeEditor
					className={clsx({ imageUploading: uploadingImage.loading })}
					value={code}
					ref={textRef}
					language={language}
					placeholder="Please enter some text."
					onChange={(evt) => setCode(evt.target.value)}
					onPaste={handlePaste}
					padding={15}
					style={{
						backgroundColor: '#f5f5f5',
						color: '#000',
						fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
						fontSize: 12,
						borderRadius: '10px',
						border: '1px solid rgba(0,0,0,.26)',
						...(style && style),
					}}
				/>
			</GrammarlyEditorPlugin>
			<Box sx={styles.uploadingState}>
				{uploadingImage.loading && (
					<Typography className="text saving">
						<CircularProgress className="circular-progress" size={18} />
						<span>Uploading Image...</span>
					</Typography>
				)}
				{!uploadingImage.loading && uploadingImage.error && <Typography className="text error">{uploadingImage.errorMessage}</Typography>}
			</Box>
			<Box sx={{ marginTop: '10px' }}>
				{!uploadingImage.loading && !uploadingImage.error && (
					<IconButton size="small" onClick={handleCleanupImage}>
						<BrokenImage />
					</IconButton>
				)}
				<IconButton size="small" onClick={convertMarkdownLatex}>
					<MathIconFix />
				</IconButton>
				<IconButton size="small" onClick={insertMathBlock}>
					<MathBlockIcon />
				</IconButton>
			</Box>
		</Box>
	);
}
