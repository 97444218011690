/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { capitalizeFirst } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { RadioGroup, FormControl, FormLabel, FormControlLabel, Radio } from '@mui/material';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
import { styled } from '@mui/material/styles';
const RadioContainer = styled(FormControl)(({ theme }) => ({
	// flexDirection: 'row',
	alignItems: 'center',
	'& .MuiTypography-root': { textTransform: 'uppercase', margin: 0, letterSpacing: '1px', color: 'rgba(0,0,0,.5)' },
	'& .radio-btn': {
		color: 'rgba(0,0,0,.5)',
		'&.Mui-checked': {
			color: theme.palette.primary.main,
			'& + .MuiTypography-root': { color: theme.palette.primary.main },
		},
	},
}));
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
RadioHorizontal.defaultProps = {
	row: true,
};
export default function RadioHorizontal({ label, row, selected, onChange, options, sx }) {
	return (
		<RadioContainer component="fieldset" sx={{ ...(sx && sx) }}>
			{label && <FormLabel sx={{ marginRight: '15px', marginBottom: row === false ? '15px !important' : 0, fontWeight: 600, letterSpacing: '1px', textTransform: 'uppercase' }}>{label}</FormLabel>}
			<RadioGroup row={row} name="position" value={selected} onChange={onChange}>
				{options.map((option, index) => {
					return <FormControlLabel key={`radio-btn-key-` + option.value} value={option.value} control={<Radio className="radio-btn" />} label={option.label ? option.label : capitalizeFirst(option.value)} labelPlacement="end" />;
				})}
			</RadioGroup>
		</RadioContainer>
	);
}
