/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
import { useAuth } from '../AuthProvider';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Chip } from '@mui/material';
import { Password as PasswordIcon, Email as EmailIcon, Badge as NameIcon } from '@mui/icons-material';
import { LogoIconTextTitle } from '../../@core/Logo';
import { UpdateNameForm, UpdateEmailForm, UpdatePassForm } from '../forms';
import { LoginRegisterBtns } from '../elements';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function AccountPage() {
	const history = useHistory();
	const { view } = useParams();
	const { appUser } = useAuth();

	const changeView = (newView) => {
		history.push(`/account/${newView}`);
	};

	if (isEmpty(appUser)) return <Redirect to="/" />;

	return (
		<Box sx={{ ...styles.authPageContainer, backgroundColor: 'primary.main' }}>
			<Box className="banner">
				<Box sx={{ position: 'absolute', top: '8px', right: '8px' }}>
					<LoginRegisterBtns />
				</Box>
				<LogoIconTextTitle size="small" />
				<Typography className="main-title heading-font" variant="h3">
					Account Settings
				</Typography>
				<Typography color="white.main" className="heading-font banner-blurb" variant="h5">
					Managing your account is simple. Just select an option below.
				</Typography>
				<Box sx={{ marginBottom: '30px' }}>
					<Chip icon={<NameIcon />} className="text-upper-spaced" label="Update Name" color="secondary" variant={view === 'update-name' ? 'filled' : 'outlined'} onClick={() => changeView('update-name')} />
					<Chip icon={<EmailIcon />} className="text-upper-spaced" label="Update Email" color="secondary" variant={view === 'update-email' ? 'filled' : 'outlined'} onClick={() => changeView('update-email')} />
					<Chip icon={<PasswordIcon />} className="text-upper-spaced" label="Update Password" color="secondary" variant={view === 'update-password' ? 'filled' : 'outlined'} onClick={() => changeView('update-password')} />
				</Box>
				<Box sx={{ maxWidth: '600px', width: '100%' }}>
					{view === 'update-password' && <UpdatePassForm />}
					{view === 'update-email' && <UpdateEmailForm />}
					{view === 'update-name' && <UpdateNameForm />}
				</Box>
			</Box>
		</Box>
	);
}
