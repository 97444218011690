/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData, TutorData } from '../../data';
/****************************************************************************************************
 * COMPONENTS / STYLES
 ****************************************************************************************************/
import { Box, Divider } from '@mui/material';
import {
	LoadingPageMessage,
	ApiErrorMessage,
	NoDataMessage,
} from '../Messages';
import styles from './styles';
import { TutorElement } from '.';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function TutorsList({ blockId }) {
	const [tutors, setTutors] = React.useState(InitialData.loading);

	React.useEffect(() => {
		const returnTutors = async (blockId) => {
			setTutors(InitialData.loading);
			const res = await TutorData.getTutors(blockId);
			if (!res.success)
				return setTutors({
					...InitialData.error,
					errorCode: res.code,
					errorMessage: res.message,
				});
			setTutors({ ...InitialData.completed, data: res.data });
		};
		returnTutors(blockId);
		return () => {
			setTutors(InitialData.completed);
		};
	}, [blockId]);

	return (
		<React.Fragment>
			{tutors.loading && <LoadingPageMessage />}
			{!tutors.loading && tutors.error && (
				<ApiErrorMessage
					errorCode={tutors.errorCode}
					errorMessage={tutors.errorMessage}
				/>
			)}
			{!tutors.loading && !tutors.error && tutors.data.length === 0 && (
				<NoDataMessage customMessage="Sorry there are no tutors currently available for this block." />
			)}
			{!tutors.loading && !tutors.error && tutors.data.length > 0 && (
				<Box sx={styles.tutorListContainer}>
					{tutors.data.map((tutor, index) => (
						<TutorElement key={`tutor-${index}`} tutor={tutor} />
					))}
				</Box>
			)}
		</React.Fragment>
	);
}
