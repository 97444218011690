/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
/****************************************************************************************************
 * IMAGES
 ****************************************************************************************************/
import LogoIcon from './logo-color.svg';
import LogoIconWhite from './logo-white.svg';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
const Sx = {
	logoContainer: (theme, white) => ({
		fontFamily: "'Gochi Hand', sans-serif",
		textAlign: 'left',
		display: 'flex',
		alignItems: 'center',
		'& .logo-icon': {
			cursor: 'pointer',
			filter: 'brightness(150%)',
			'&.logo-small': { width: '32px' },
			'&.logo-large': { width: { xs: '32px', sm: '50px' } },
		},
		'& .logo-text': {
			margin: 0,
			fontFamily: "'Gochi Hand', sans-serif",
			fontWeight: 600,
			marginLeft: '10px',
			marginRight: '10px',
			color: white === true ? '#FFF' : 'primary.main',
			'& .cursor-pointer': { cursor: 'pointer' },
		},
	}),
};
/****************************************************************************************************
 * PROPS
 ****************************************************************************************************/
LogoIconText.defaultProps = {
	white: PropTypes.bool,
	size: PropTypes.oneOf(['small', 'large']),
};
LogoIconText.defaultProps = {
	logoWhite: false,
	white: false,
	size: 'small',
	sx: {},
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function LogoIconText({ logoWhite, white, size, sx }) {
	const history = useHistory();
	return (
		<Box sx={(theme) => ({ ...Sx.logoContainer(theme, white), ...sx })}>
			<img className={`logo-icon logo-${size}`} alt="" src={logoWhite ? LogoIconWhite : LogoIcon} onClick={() => history.push('/')} />
			<Typography className="logo-text" variant={size === 'small' ? 'h5' : 'h3'}>
				<span className="cursor-pointer" onClick={() => history.push('/')}>
					Learnblock
				</span>
			</Typography>
		</Box>
	);
}
