/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { CircularProgress } from '@mui/material';
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
LoaderCircular.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    centered: PropTypes.bool,
}
LoaderCircular.defaultProps = {
    size: 60,
    color: 'primary',
    centered: true,
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function LoaderCircular({ size, color, centered }) {
    return (
        <CircularProgress
            size={size}
            color={color}
            sx={{ margin: centered ? '40px auto' : 'inherit', display: centered ? 'block' : 'inline-block' }}
        />
    )
}