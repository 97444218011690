/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
import { InitialData, QuestionData } from '../../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { ApiErrorMessage, LoadingPageMessage, NoDataMessage } from '../../Messages';
import { QuestionElement } from '../../Questions';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function TagQuestions({ block, handleClose }) {
	const [currQuestion, setCurrQuestion] = React.useState({});
	const [loadingQuestion, setLoadingQuestion] = React.useState(InitialData.loading);
	// Now save the question but retain original state of question until successful save

	React.useEffect(() => {
		getQuestionToTag(block.id);
	}, [block]);

	const getQuestionToTag = async (block_id) => {
		setLoadingQuestion(InitialData.loading);
		const res = await QuestionData.getQuestionToTag(block_id);
		if (!res.success)
			return setLoadingQuestion({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		setCurrQuestion(res.data);
		setLoadingQuestion(InitialData.completed);
	};

	const handleSaveTag = async (newQuestion) => {
		setLoadingQuestion(InitialData.loading);
		const res = await QuestionData.editQuestion(newQuestion);
		if (!res.success) {
			return setLoadingQuestion({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		} else {
			// Get a new question to tag
			getQuestionToTag(block.id);
		}
	};

	const handleDeleteQuestion = (questionId) => {
		// Once the question is deleted, simply return a new one
		getQuestionToTag(block.id);
	};

	return (
		<React.Fragment>
			{loadingQuestion.loading && <LoadingPageMessage />}
			{!loadingQuestion.loading && loadingQuestion.error && <ApiErrorMessage errorCode={loadingQuestion.errorCode} errorMessage={loadingQuestion.errorMessage} />}
			{!loadingQuestion.loading && !loadingQuestion.error && (
				<React.Fragment>
					{isEmpty(currQuestion) && <NoDataMessage customMessage={'Sorry there are no questions remaining to be tagged.'} />}
					{!isEmpty(currQuestion) && <QuestionElement handleClose={handleClose} block={block} questionData={currQuestion} onDeleteQuestion={handleDeleteQuestion} onSaveTaggedQuestion={handleSaveTag} displayUserControls={false} />}
				</React.Fragment>
			)}
		</React.Fragment>
	);
}
