/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
import { useDialog } from '../../contexts';
import { InitialData, InstitutionsData } from '../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddInstitutionForm from './AddInstitutionForm';
const filter = createFilterOptions();
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function SelectInstitutionByBlock({ blockId, initialInstitutionId, onChange }) {
	const [institutions, setInstitutions] = React.useState(InitialData.loading);
	const [value, setValue] = React.useState('');
	const { openDialog, closeDialog } = useDialog();

	React.useEffect(() => {
		if (!isEmpty(blockId)) {
			getInstitutions(blockId);
		}
	}, [blockId]);

	React.useEffect(() => {
		if (onChange) {
			onChange(!isEmpty(value) ? value.id : '');
		}
	}, [value]);

	const getInstitutions = async (blockId) => {
		setInstitutions(InitialData.loading);
		const res = await InstitutionsData.getInstitutions(blockId);
		if (!res.success) return setInstitutions({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		if (!isEmpty(initialInstitutionId)) {
			const findInstitution = res.data.find((el) => el.id === initialInstitutionId);
			setValue(findInstitution);
		}
		setInstitutions({ ...InitialData.completed, data: res.data });
	};

	const openAddInstitutionDialog = (name) => {
		openDialog(<AddInstitutionForm name={name} onSubmit={handleAddNewInstitutions} />);
	};

	const handleAddNewInstitutions = (newInstitution) => {
		setValue(newInstitution);
		setInstitutions((prev) => ({ ...prev, data: [...prev.data, newInstitution] }));
		closeDialog();
	};

	return (
		<Autocomplete
			sx={{
				flexGrow: 1,
				'& .MuiFilledInput-root': { paddingLeft: '20px', paddingRight: '20px', paddingBottom: '5px' },
			}}
			loading={institutions.loading}
			value={value}
			onChange={(event, newValue) => {
				if (typeof newValue === 'string') {
					openAddInstitutionDialog(newValue);
				} else if (newValue && newValue.inputValue) {
					// Create a new value from the user input
					openAddInstitutionDialog(newValue.inputValue);
				} else {
					setValue(newValue);
				}
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				const { inputValue } = params;
				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option.name);
				if (inputValue !== '' && !isExisting) {
					filtered.push({
						inputValue,
						name: `Add "${inputValue}"`,
					});
				}
				return filtered;
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			options={institutions.data}
			getOptionLabel={(option) => {
				// Value selected with enter, right from the input
				if (typeof option === 'string') {
					return option;
				}
				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return option.name;
			}}
			renderOption={(props, option) => <li {...props}>{option.name}</li>}
			freeSolo
			renderInput={(params) => (
				<TextField
					fullWidth
					{...params}
					label="Institution Name"
					variant="outlined"
					sx={{
						'& .MuiOutlinedInput-root': { borderRadius: '56px' },
						'& .MuiOutlinedInput-notchedOutline': { borderWidth: '2px' },
						'& .MuiFormHelperText-root': { marginLeft: '15px' },
					}}
					error={isEmpty(value) || isEmpty(value.name)}
					helperText={isEmpty(value) && 'This field cannot be empty'}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{institutions.loading ? <CircularProgress color="inherit" size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
}
