/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData } from '../../data';
import { isEmpty, useKey } from '../../utils';
import { forgotPass } from '../AuthData';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../components/Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
ForgotPassForm.defaultProps = {
	darkBackground: true,
};
export default function ForgotPassForm({ darkBackground }) {
	const history = useHistory();
	const [state, setState] = React.useState({
		email: { value: '', error: false, message: '' },
	});
	const [formState, setFormState] = React.useState(InitialData.completed);
	useKey('Enter', () => handleSubmit());

	const handleEmail = (e) => {
		e.persist();
		const email = e.target.value;
		const data = { value: email, error: false, message: '' };
		if (isEmpty(email)) {
			data.error = true;
			data.message = 'Email field cannot be empty.';
		}
		if (!validator.isEmail(email)) {
			data.error = true;
			data.message = 'You must enter a valid email.';
		}
		setState((prev) => ({ ...prev, email: data }));
	};

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) handleSubmit();
	};

	const handleSubmit = async () => {
		if (checkErrors()) return;
		setFormState(InitialData.loading);
		const res = await forgotPass(state.email.value);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setFormState({ ...InitialData.completed, data: res.data });
	};

	const checkErrors = () => {
		if (isEmpty(state.email.value) || state.email.error === true) {
			return true;
		}
		return false;
	};

	return (
		<Box id="login-form" sx={styles.authForm}>
			<TextField fullWidth label="Email" className="text-field-light" variant="filled" color="primary" error={state.email.error} helperText={state.email.message} value={state.email.value} onChange={handleEmail} />
			{formState.error && <ApiErrorMessage className="error-message" errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
			<LoadingButton fullWidth className={darkBackground === true ? 'btn-light' : 'btn-dark'} disabled={checkErrors()} loading={formState.loading} variant="contained" onClick={handleSubmit} onKeyDown={handleKeyDown}>
				{formState.loading === true ? 'Sending Reset Link ...' : 'Send Password Reset Link'}
			</LoadingButton>
			<Box className="auth-options">
				<span className="instruction-text">Suddenly remembered?</span>
				<span className="action-text" onClick={() => history.push('/auth/login')}>
					Login
				</span>
			</Box>
		</Box>
	);
}
