import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { isEmpty } from '../../../utils';

const StyledRating = styled(Rating)(({ theme }) => ({
	'& .MuiRating-iconEmpty .MuiSvgIcon-root': {
		color: theme.palette.action.disabled,
	},
}));

const customIcons = {
	1: {
		icon: <SentimentVerySatisfiedIcon color="success" />,
		label: 'Very Easy',
	},
	2: {
		icon: <SentimentSatisfiedAltIcon color="success" />,
		label: 'Easy',
	},
	3: {
		icon: <SentimentSatisfiedIcon color="warning" />,
		label: 'Medium',
	},
	4: {
		icon: <SentimentDissatisfiedIcon color="error" />,
		label: 'Hard',
	},
	5: {
		icon: <SentimentVeryDissatisfiedIcon color="error" />,
		label: 'Very Hard',
	},
};

function IconContainer(props) {
	const { value, ...other } = props;
	return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
	value: PropTypes.number.isRequired,
};

export default function DifficultyRating({ value, readOnly, onChange }) {
	return <StyledRating name="highlight-selected-only" value={value} IconContainerComponent={IconContainer} getLabelText={(value) => (!isEmpty(value) ? customIcons[value].label : null)} highlightSelectedOnly onChange={(event, newValue) => onChange(newValue)} readOnly={readOnly} />;
}
