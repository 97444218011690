const styles = {
    institutionForm: {
        '& .chip-options': {
            my: '20px',
            '& .title-text': { mb: '10px' }
        },
        '& .MuiFormControl-root': { marginBottom: '20px' },
        '& .MuiOutlinedInput-root': { borderRadius: '56px', },
        '& .MuiOutlinedInput-notchedOutline': { borderWidth: '2px' },
        '& .MuiFormHelperText-root': { marginTop: '10px', marginLeft: '10px' }
    }
}

export default styles;