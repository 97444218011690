/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Button } from '@mui/material';
import { Home as HomeIcon, Support as SupportIcon } from '@mui/icons-material';
import { LogoIconTextTitle } from '../../@core/Logo';
import { DualButton } from '../../@core/Button';
import { LoginRegisterBtns } from '../../auth/elements';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
ErrorPage.propTypes = {
	title: PropTypes.string,
	subheading: PropTypes.string,
	message: PropTypes.string,
};
ErrorPage.defaultProps = {
	title: 'Whoops!',
	subheading: 'This page no longer exists.',
	message: "Let's get you back on track 😊",
};
export default function ErrorPage({ title, subheading, message }) {
	const history = useHistory();

	return (
		<Box sx={styles.errorContainer}>
			<Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
				<LoginRegisterBtns white={true} />
			</Box>
			<LogoIconTextTitle size="small" />
			<Typography color="white.main" className="heading-font title" variant="h2">
				{title}
			</Typography>
			<Typography color="white.main" className="heading-font subheading" variant="h4">
				{subheading}
			</Typography>
			<Typography color="text.grey" className="heading-font message" variant="h5">
				{message}
			</Typography>
			<DualButton
				white={true}
				buttons={[
					<Button startIcon={<HomeIcon />} color="secondary" variant="contained" onClick={() => history.push('/')}>
						Return Home
					</Button>,
					<Button startIcon={<SupportIcon />} color="secondary" sx={{ marginLeft: '10px', '&:hover': { color: '#FFF', borderColor: 'secondary.main' } }} variant="outlined" onClick={() => history.push('/support/contact')}>
						Get Support
					</Button>,
				]}
			/>
		</Box>
	);
}
