/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import { Dialog, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
/****************************************************************************************************
 * CONTEXT
 ****************************************************************************************************/
// Create context
const DialogContext = React.createContext();
export function useDialog() {
	return React.useContext(DialogContext);
}
/****************************************************************************************************
 * REDUCER
 ****************************************************************************************************/
// Initialise the reducer
const initialState = { open: false, component: null, width: false };
// Setup the reducer
const reducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case 'OPEN':
			return {
				...state,
				open: true,
				component: payload.component,
				width: payload.width,
				sx: payload.sx,
				hideClose: payload.hideClose,
			};
		case 'CLOSE':
			return { open: false, component: null, width: false };
		default:
			return state;
	}
};
/****************************************************************************************************
 * PROVIDER
 ****************************************************************************************************/
export function DialogProvider({ children }) {
	const [dialog, dispatchDialog] = React.useReducer(reducer, initialState);

	React.useEffect(() => {
		const closeDialogOnRouteChange = (e) => {
			e.preventDefault();
			closeDialog();
		};

		if (dialog.open === true) {
			window.history.pushState(null, '', window.location.href);
			window.addEventListener('popstate', closeDialogOnRouteChange);
		}

		if (dialog.open === false) {
			window.removeEventListener('popstate', closeDialogOnRouteChange);
		}

		return () => {
			window.removeEventListener('popstate', closeDialogOnRouteChange);
		};
	}, [dialog]);

	const openDialog = (component, width = 0, sx = {}, hideClose = false) => {
		dispatchDialog({
			type: 'OPEN',
			payload: {
				component,
				width: width ? width : false,
				sx,
				hideClose,
			},
		});
	};

	const closeDialog = () => {
		dispatchDialog({ type: 'CLOSE' });
	};

	const value = {
		dialog,
		openDialog,
		closeDialog,
	};

	return (
		<DialogContext.Provider value={value}>
			<Dialog
				fullScreen={dialog.width === 'fullscreen' ? true : false}
				open={dialog.open}
				onClose={(e, reason) => {
					if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
						return;
					} else {
						closeDialog();
					}
				}}
				PaperProps={{
					sx: {
						borderRadius: dialog.width === 'fullscreen' ? '0px' : '10px',
						backgroundColor: '#FFF',
						color: 'primary.main',
						backgroundImage: 'unset',
						height: dialog.width && typeof dialog.width === 'number' ? dialog.width : dialog.width === 'fullscreen' ? '100%' : 'initial',
						width: dialog.width && typeof dialog.width === 'number' ? dialog.width : dialog.width === 'fullscreen' ? '100%' : '600px',
						maxWidth: '100%',
						padding: { xs: '60px 30px', sm: '60px' },
						fontWeight: 500,
						...dialog.sx,
					},
				}}
			>
				{dialog.hideClose === false && (
					<IconButton
						className="handle-close-btn"
						sx={{
							position: 'absolute',
							top: '15px',
							right: '15px',
							color: 'rgba(0,0,0,.5)',
							backgroundColor: '#FFF',
							'&:hover': { backgroundColor: '#FFF' },
							zIndex: 9999,
						}}
						onClick={() => closeDialog()}
					>
						<CloseIcon />
					</IconButton>
				)}
				{dialog.component}
			</Dialog>
			{children}
		</DialogContext.Provider>
	);
}
