const styles = {
	authForm: {
		'& .logo-text': { color: '#FFF' },
		'& .main-title': { marginBottom: '20px' },
		'& .instruction-title': { marginBottom: '20px' },
		'& .MuiTextField-root': {
			mb: '20px',
		},
		'& .MuiFormHelperText-root': {
			paddingLeft: '10px',
			paddingTop: '5px',
			'&.Mui-error': {
				color: '#ef5350',
			},
		},
		'& .MuiFilledInput-root': {
			borderRadius: '56px',
			'&:after': { display: 'none' },
		},
		'& .MuiFilledInput-input': {
			paddingLeft: '20px',
			'&:-webkit-autofill': { borderRadius: '56px' },
		},
		'.text-field-light': {
			'& .MuiFilledInput-root': {
				backgroundColor: '#FFF',
			},
		},
		'& .MuiInputLabel-root': {
			left: '10px',
		},
		'& .MuiButton-root': {
			margin: '0px',
			'&.Mui-disabled': {
				backgroundColor: 'rgba(0,0,0,.2) !important',
				color: 'rgba(0,0,0,.5) !important',
			},
			'&.btn-light.Mui-disabled': {
				backgroundColor: 'rgba(255,255,255,.2) !important',
				color: 'rgba(255,255,255,.5) !important',
			},
		},
		'& .error-message': { padding: '0px !important', marginBottom: '40px' },
		'& .auth-options': {
			textAlign: 'center',
			marginTop: '20px',
			'& > span': { margin: '5px' },
			'& .instruction-text': { color: 'text.grey' },
			'& .action-text': {
				fontWeight: 500,
				color: 'secondary.main',
				cursor: 'pointer',
				textDecoration: 'none',
				'&:hover': { filter: 'brightness(150%)' },
			},
		},
	},
};

export default styles;
