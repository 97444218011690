const styles = {
    errorContainer: {
        backgroundColor: "primary.main",
        padding: '20px', paddingTop: '100px', textAlign: 'center', minHeight: '100vh',
        '& .logo-text': { color: '#FFF' },
        '& .heading-font': {
            marginBottom: '30px',
            '&.title': { marginTop: '20px' }
        }
    },
    dualButtonSx: { bgcolor: '#FFF', color: '#000', '&:hover': { bgcolor: 'rgba(255,255,255,.7)', } }
}

export default styles;