/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '..';
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Menu, ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Tooltip, IconButton, Avatar, Typography } from '@mui/material';
import { TouchApp as ClickIcon, ArrowDropDown as ArrowDropDownIcon, Login, AppRegistration } from '@mui/icons-material';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
LoginRegisterBtns.defaultProps = {
	white: true,
	sx: {},
};
const options = ['Login', 'Register'];
export default function LoginRegisterBtns({ sx, variant }) {
	const history = useHistory();
	const { isAuthenticated, logoutUser, appUser } = useAuth();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleClick = () => {
		if (options[selectedIndex] === 'Login') {
			history.push('/auth/login');
		} else if (options[selectedIndex] === 'Register') {
			history.push('/auth/register');
		}
	};

	const handleMenuItemClick = (event, index) => {
		setSelectedIndex(index);
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	if (!isAuthenticated)
		return (
			<React.Fragment>
				<ButtonGroup sx={{ borderRadius: '56px', ...sx }} color={variant ? variant : 'secondary'} variant="contained">
					<Button onClick={() => history.push('/auth/login')} startIcon={<Login />}>
						Sign in
					</Button>
					<Button onClick={() => history.push('/auth/register')} className="toggle-btn" startIcon={<AppRegistration />}>
						Sign up
					</Button>
				</ButtonGroup>
				{/* <ButtonGroup sx={sx} color="secondary" variant="contained" ref={anchorRef} aria-label="split button">
					<Button startIcon={<ClickIcon />} onClick={handleClick}>
						{options[selectedIndex]}
					</Button>
					<Button className="toggle-btn" sx={{ px: '15px !important' }} size="small" aria-controls={open ? 'split-button-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-label="select merge strategy" aria-haspopup="menu" onClick={handleToggle}>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup> */}
				<Popper
					sx={{
						zIndex: 999,
						minWidth: '125px',
					}}
					open={open}
					anchorEl={anchorRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
							}}
						>
							<Paper>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList id="split-button-menu" autoFocusItem>
										{options.map((option, index) => (
											<MenuItem key={option} disabled={index === 2} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
												{option}
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</React.Fragment>
		);

	return (
		<React.Fragment>
			<Tooltip title="Account">
				<IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ...sx }}>
					<Avatar sx={{ bgcolor: 'secondary.main' }}>{appUser.name[0]}</Avatar>
				</IconButton>
			</Tooltip>
			<Menu
				sx={{ mt: '45px' }}
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<MenuItem onClick={() => history.push('/account')}>
					<Typography textAlign="center">Account</Typography>
				</MenuItem>
				<MenuItem onClick={logoutUser}>
					<Typography textAlign="center">Logout</Typography>
				</MenuItem>
			</Menu>
		</React.Fragment>
	);
}
