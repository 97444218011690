import React from 'react';
import Particles from 'react-tsparticles';

const AnimationParticles = () => {
    return (
        <Particles
            className="particles-container"
            options={{
                particles: {
                    number: {
                        value: 40,
                        density: {
                            enable: true,
                            value_area: 1000,
                        },
                    },
                    shape: {
                        type: "circle",
                        stroke: {
                            width: 0,
                            color: '#000000',
                        },
                        polygon: {
                            nb_sides: 5,
                        },
                    },
                    size: {
                        value: 3,
                        random: true,
                        anim: {
                            enable: false,
                            speed: 40,
                            size_min: 0.1,
                            sync: false,
                        },
                    },
                    color: {
                        value: "#ffffff",
                    },
                    opacity: {
                        value: 0.5,
                        random: false,
                        anim: {
                            enable: false,
                            speed: 1,
                            opacity_min: 0.1,
                            sync: false,
                        }
                    },
                    links: {
                        enable: true,
                        color: "#ffffff",
                        distance: 150,
                        opacity: 0.4,
                        width: 1.6,
                    },
                    move: {
                        enable: true,
                        speed: 2,
                        direction: "none",
                        random: false,
                        straight: false,
                        bounce: false,
                        outMode: "out",
                        attract: {
                            enable: false,
                            rotateX: 600,
                            rotateY: 1200
                        }
                    },
                    collisions: {
                        enable: false,
                    },
                },
                detectRetina: true,
            }}
        // options={{
        //     "particles": {
        //         "number": {
        //             "value": 50,
        //             "density": {
        //                 "enable": true,
        //                 "value_area": 1000
        //             }
        //         },
        //         "color": {
        //             "value": `${color ? color : "#0069ff"}`
        //         },
        //         "shape": {
        //             "type": "circle",
        //             "stroke": {
        //                 "width": 0,
        //                 "color": "#000000"
        //             },
        //             "polygon": {
        //                 "nb_sides": 5
        //             },
        //             "image": {
        //                 "src": "img/github.svg",
        //                 "width": 100,
        //                 "height": 100
        //             }
        //         },
        //         "opacity": {
        //             "value": 0.5,
        //             "random": false,
        //             "anim": {
        //                 "enable": false,
        //                 "speed": 1,
        //                 "opacity_min": 0.1,
        //                 "sync": false
        //             }
        //         },
        //         "size": {
        //             "value": 3,
        //             "random": true,
        //             "anim": {
        //                 "enable": false,
        //                 "speed": 40,
        //                 "size_min": 0.1,
        //                 "sync": false
        //             }
        //         },
        //         "line_linked": {
        //             "enable": true,
        //             "distance": 150,
        //             "color": `${color ? color : "#0069ff"}`,
        //             "opacity": 0.4,
        //             "width": 1.6
        //         },
        //         "move": {
        //             "enable": true,
        //             "speed": 3,
        //             "direction": "none",
        //             "random": false,
        //             "straight": false,
        //             "out_mode": "out",
        //             "bounce": false,
        //             "attract": {
        //                 "enable": false,
        //                 "rotateX": 600,
        //                 "rotateY": 1200
        //             }
        //         }
        //     },
        //     "retina_detect": true
        // }}
        />
    )
}

export default AnimationParticles;