/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData, NodeData } from '../../../data';
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function NodeAddForm({ title, module, block_id, parent_id, position, handleSubmit }) {
	const [state, setState] = React.useState({
		module: '',
		block_id: '',
		parent_id: '0',
		position: 1,
		name: '',
	});
	const [formState, setFormState] = React.useState(InitialData.completed);

	React.useEffect(() => {
		setState((prev) => ({
			...prev,
			module,
			block_id,
			parent_id,
			position,
		}));
	}, []);

	const submitNode = async () => {
		if (isEmpty(state.name)) {
			setFormState({
				...InitialData.error,
				errorCode: 'form-error',
				errorMessage: 'You cannot submit this form without providing a valid topic name.',
			});
		} else {
			setFormState(InitialData.loading);
			const res = await NodeData.addNode(state);
			if (!res.success)
				return setFormState({
					...InitialData.error,
					errorCode: res.code,
					errorMessage: res.message,
				});
			handleSubmit(res.data);
		}
	};

	return (
		<Box sx={styles.formContainer}>
			<Typography className="heading-font form-title" align="center" variant="h5">
				{title}
			</Typography>
			{!formState.loading && formState.error && <ApiErrorMessage errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
			<TextField
				autoFocus
				fullWidth
				label="What is the name of this node?"
				sx={{ my: 2 }}
				value={state.name}
				error={isEmpty(state.name)}
				onChange={(e) => {
					e.persist();
					setState((prev) => ({ ...prev, name: e.target.value }));
				}}
			/>
			<LoadingButton fullWidth variant="contained" loading={formState.loading} onClick={() => submitNode(state)}>
				Add Node
			</LoadingButton>
		</Box>
	);
}
