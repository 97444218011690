const styles = {
	authPageContainer: {
		minHeight: '100vh',
		'& .banner': {
			padding: { xs: '100px 20px 120px', md: '100px 60px' },
			maxWidth: '800px',
			width: '100%',
			margin: 'auto',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& .logo-icon': { filter: 'brightness(120%)' },
			'& .logo-text': { color: '#FFF' },
			'& .main-title': { margin: '15px 0px', color: '#FFF !important', lineHeight: '1.4' },
			'& .banner-blurb': { maxWidth: '600px', margin: '10px auto 40px' },
			'& .settings-options': {
				'& .option': { padding: '10px 15px', height: 'auto', margin: '2px' },
			},
			'& .login-form-wrapper': {
				padding: '0px 40px',
			},
		},
	},
	accountPageContainer: {},
};

export default styles;
