/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box } from '@mui/material';
import LoaderCircular from './LoaderCircular';
/**************************************************************************************************** 
 * STYLES
****************************************************************************************************/
const Sx = {
    background: '#FFF', margin: '20px auto', padding: '20px', borderRadius: '10px',
    '& img': { margin: 'auto', maxWidth: '100%', },
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function LoaderImage({ src, sx, ...rest }) {
    const [loaded, setLoaded] = React.useState(false);
    return (
        <Box sx={{ ...Sx, ...sx }}>
            <img alt="" src={src} onLoad={() => setLoaded(true)} {...rest} style={{ display: loaded ? 'block' : 'none' }} />
            {!loaded && <LoaderCircular centered={true} />}
        </Box>
    )
}