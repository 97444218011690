/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Typography } from '@mui/material';
import { LogoIconText, LogoIconTextTitle } from '../Logo';
/**************************************************************************************************** 
 * STYLES
****************************************************************************************************/
import { styled } from '@mui/material/styles';
const MiniBannerWrapper = styled("div", { shouldForwardProp: prop => !['centered', 'sx'].includes(prop) })(({ theme, centered }) => ({
    zIndex: 99, padding: '100px 0px 20px', textAlign: centered ? 'center' : 'left',
    [theme.breakpoints.down('md')]: { maxWidth: '100%', },
    "&.timeline-page": {
        marginBottom: -160,
        [theme.breakpoints.up('sm')]: { marginBottom: "-80px" },
        [theme.breakpoints.up('md')]: { marginBottom: 0 },
    },
    '& .title': { fontFamily: "'Fredoka One', sans-serif", },
    '& .sub-title': {
        fontFamily: "'Poppins', sans-serif", fontWeight: 600, marginTop: '20px',
        '& .action-btn': { marginRight: 10, background: theme.palette.secondary.main, color: '#FFF' },
    },
    '& .blurb': {
        margin: '20px auto', maxWidth: centered ? '800px' : '100%',
        '& .emphasise': {
            fontWeight: 600,
            '&.primary': { color: theme.palette.primary.main },
            '&.secondary': { color: theme.palette.secondary.main },
            '&.danger': { color: 'red' },
        },
    },
}));
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
BannerMini.propTypes = {
    title: PropTypes.string,
    blurb: PropTypes.string,
    additionalComponent: PropTypes.node,
    actionBtn: PropTypes.node,
    centered: PropTypes.bool,
    whiteIcon: PropTypes.bool,
    sx: PropTypes.object,
}
BannerMini.defaultProps = {
    centered: true,
    whiteIcon: false,
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function BannerMini({ title, blurb, additionalComponent, actionBtn, centered, whiteIcon, sx }) {
    return (
        <MiniBannerWrapper centered={centered} sx={{ ...(sx && sx) }}>
            <LogoIconText white={whiteIcon} sx={{ position: 'absolute', top: '20px', left: '20px' }} />
            <Typography className="sub-title heading-font" variant="h3">
                {actionBtn}
                <span>{title}</span>
            </Typography>
            {blurb && (<Typography align="center" className="blurb heading-font" variant="h4">{blurb}</Typography>)}
            {additionalComponent}
        </MiniBannerWrapper>
    )
}