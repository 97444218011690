import BackgroundImage from './bg-home.jpg';

const styles = {
	homeContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: '100vh',
		backgroundColor: 'primary.main',
		backgroundImage: `url(${BackgroundImage})`,
		backgroundSize: 'cover',
		backgroundAttachment: 'fixed',
		backgroundRepeat: 'no-repeat',
		'& .login-register-container': {
			position: 'absolute',
			top: '8px',
			right: '8px',
		},
		'& .home-banner': {
			padding: {
				xs: '100px 10px 20px',
				sm: '100px 20px 20px',
				md: '100px 60px 20px',
			},
			width: '100%',
			maxWidth: '900px',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			'& .logo-icon': { filter: 'brightness(120%)' },
			'& .logo-text': { color: '#FFF' },
			'& .main-title': {
				margin: '15px 0px 30px',
				color: '#FFF !important',
			},
			'& .banner-blurb': { maxWidth: '600px', margin: '30px auto 40px' },
		},
	},
};

export default styles;
