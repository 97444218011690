/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import ReactFlow, { ReactFlowProvider, useReactFlow, Background, Controls } from 'react-flow-renderer';
import { useParams, useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty, useQuery } from '../../utils';
import { InitialData, NodeData } from '../../data';
import { useDialog } from '../../contexts';
import { useAuth } from '../../auth';
import { nodeTypes } from './utils/nodeTypes';
import { calcNodeSize, NodeTreeContext, d3Hierarchy, generateTree, updateQuestionsCount, getElements, handleNodeColor } from './utils/treeHelpers';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Chip, Box, Button, Typography, AppBar, Toolbar, IconButton, Dialog } from '@mui/material';
import { TouchApp as ClickIcon, List as ListIcon, AccountTree as TreeIcon, Tag as TagIcon } from '@mui/icons-material';
import { ApiErrorMessage, LoadingPageMessage } from '../../components/Messages';
import { QuestionElement } from '../Questions';
import { NodeAddForm, NodeDeleteForm, NodeEditForm } from './forms';
import { BrowseQuestions, TagQuestions } from './elements';
import { TopicTree } from './displays';
import { TutorsList, BecomeATutor } from '../Tutors';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
MapOfNodes.defaultProps = {
	multi: true,
	withFooter: true,
	initialNodeId: '',
};

// const ModalTransition = React.forwardRef(function Transition(props, ref) {
// 	return <Fade in={true} ref={ref} {...props} />;
// });

function MapOfNodes({ block, initialNodeId, module, withFooter, multi, onChangeCheckedNode }) {
	const { isAdmin, appUser } = useAuth();
	const history = useHistory();
	const query = useQuery();
	const [pageLoading, setPageLoading] = React.useState(InitialData.loading);
	const [state, setState] = React.useState({
		mapDisplayStyle: 'tree', // list, tree
		hierarchy: null,
		nodes: [],
		edges: [],
		moveNode: {},
		checked: [],
		singleChecked: {},
	});
	const [questionOptions, setQuestionOptions] = React.useState({
		open: false,
		mode: 'study', // study, tag
		duration: 15,
	});
	const reactFlowWrapper = React.useRef(null);
	const { fitView, project, setCenter, getZoom } = useReactFlow();
	const { openDialog, closeDialog } = useDialog();
	const { view } = useParams();

	React.useEffect(() => {
		initialiseData();
		return () => {
			setPageLoading(InitialData.completed);
		};
	}, []);

	React.useEffect(() => {
		if (multi === false && onChangeCheckedNode && !isEmpty(state.singleChecked)) {
			onChangeCheckedNode(state.singleChecked.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.singleChecked]);

	const initialiseData = async () => {
		setPageLoading(InitialData.loading);
		const res = await NodeData.getNodes(block.id, module);
		if (!res.success)
			return setPageLoading({
				...InitialData.error,
				errorCode: res.code,
				errorMessage: res.message,
			});
		const treeData = generateTree(block.name, res.data, module);
		updateQuestionsCount(treeData);
		if (!isEmpty(treeData)) {
			const hierarchy = d3Hierarchy(treeData);
			hierarchy.descendants().forEach((d, i) => {
				d.data.expanded = i === 0 ? true : false;
				d.data.children = d.children;
				d.children = undefined;
			});
			const { nodes, edges } = getElements(hierarchy, true);
			setState((prev) => ({
				...prev,
				hierarchy,
				nodes: handleNodeColor(nodes),
				edges: edges,
				moveNode: {},
				checked: [],
				singleChecked: {},
			}));
		}
		setPageLoading(InitialData.completed);
	};

	const onPaneClick = (event) => {
		fitView({ duration: 200 });
		setState((prev) => ({
			...prev,
			checked: [],
		}));
	};

	const onToggleNode = (event, node) => {
		const { hierarchy } = state;
		const hierarchyNode = hierarchy.find((n) => n.data.id === node.id);
		if (!hierarchyNode || !hierarchyNode.data.children) return;
		const [nodeHeight, nodeWidth] = calcNodeSize(hierarchyNode);
		hierarchyNode.data.expanded = !hierarchyNode.data.expanded;
		if (!event) {
			fitView({ duration: 200 });
		} else {
			const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
			const x = hierarchyNode.data.expanded ? event.clientX - left + nodeWidth : event.clientX - left - nodeWidth / 2;
			const position = project({ x, y: event.clientY - top });
			const zoom = getZoom();
			setCenter(position.x, position.y, { duration: 200, zoom });
		}
		const nextElements = getElements(hierarchy, true);
		setState((prev) => ({
			...prev,
			nodes: handleNodeColor(nextElements.nodes),
			edges: nextElements.edges,
		}));
	};

	const onAddFirstNode = () => {
		openDialog(
			<NodeAddForm
				title={`Add First Node in ${block.name}`}
				module={module}
				block_id={block.id}
				parent_id={'0'}
				position={1}
				handleSubmit={(_newNode) => {
					const newNode = d3Hierarchy(_newNode);
					newNode.children = undefined;
					newNode.data.expanded = false;
					const nextElements = getElements(newNode, false);
					setState((prev) => ({
						...prev,
						nodes: handleNodeColor(nextElements.nodes),
						edges: nextElements.edges,
					}));
					closeDialog();
				}}
			/>
		);
	};

	const onAddChildNode = (node) => {
		const { hierarchy } = state;
		// Find the node in the hierarchy
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		openDialog(
			<NodeAddForm
				title={`Add Node Inside ${node.name}`}
				module={module}
				block_id={block.id}
				parent_id={node.id}
				position={isEmpty(selectedNode.data.children) ? 1 : selectedNode.data.children.length + 1}
				handleSubmit={(_newNode) => {
					// Convert the new node into a hierarchy node
					const newNode = d3Hierarchy(_newNode);
					// Add Properties
					newNode.depth = selectedNode.depth + 1;
					newNode.data.depth = newNode.depth;
					newNode.height = selectedNode.height;
					newNode.parent = selectedNode;
					newNode.children = undefined;
					newNode.data.expanded = false;
					newNode.data.questions_count = 0;
					// Make the selectedNode expand
					selectedNode.data.children = [newNode];
					selectedNode.data.expanded = true;
					selectedNode.children = selectedNode.data.children;
					// Get the elements
					const nextElements = getElements(hierarchy, false);
					setState((prev) => ({
						...prev,
						nodes: handleNodeColor(nextElements.nodes),
						edges: nextElements.edges,
					}));
					closeDialog();
				}}
			/>
		);
	};

	const onAddSiblingNode = (node) => {
		const { hierarchy } = state;
		// Find the node in the hierarchy
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		openDialog(
			<NodeAddForm
				title={`Add Node Below ${node.name}`}
				module={module}
				block_id={block.id}
				parent_id={node.parent_id}
				position={selectedNode.data.position + 1}
				handleSubmit={(_newNode) => {
					// Handle the new node
					const newNode = d3Hierarchy(_newNode);
					newNode.depth = selectedNode.depth;
					newNode.data.depth = newNode.depth;
					newNode.height = selectedNode.height;
					newNode.parent = selectedNode.parent;
					newNode.children = undefined;
					newNode.data.expanded = false;
					newNode.data.questions_count = 0;
					// Find the parent node
					const parentNode = hierarchy.find((n) => n.data.id === newNode.data.parent_id);
					if (!parentNode) return;
					if (!parentNode.children) parentNode.children = [];
					parentNode.children.splice(newNode.data.position - 1, 0, newNode);
					parentNode.children.forEach((node, index) => {
						node.data.position = index + 1;
					});
					// Get the elements
					const nextElements = getElements(hierarchy, true);
					setState((prev) => ({
						...prev,
						nodes: handleNodeColor(nextElements.nodes),
						edges: nextElements.edges,
					}));
					closeDialog();
				}}
			/>
		);
	};

	const onEditNode = (node) => {
		const { hierarchy } = state;
		// Find the node in the hierarchy
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		openDialog(
			<NodeEditForm
				nodeId={node.id}
				name={node.name}
				position={node.position}
				handleSubmit={(_newNode) => {
					selectedNode.data.name = _newNode.name;
					const nextElements = getElements(hierarchy, true);
					setState((prev) => ({
						...prev,
						nodes: handleNodeColor(nextElements.nodes),
						edges: nextElements.edges,
					}));
					closeDialog();
				}}
			/>
		);
	};

	const onDeleteNode = (node) => {
		const { hierarchy } = state;
		// Find the node in the hierarchy
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		openDialog(
			<Box sx={{ textAlign: 'center' }}>
				<NodeDeleteForm
					title={`Are you sure you want to delete the node: ${node.name}`}
					nodeId={node.id}
					handleSubmit={(nodeId) => {
						// Find the parent node
						const parentNode = hierarchy.find((n) => n.data.id === node.parent_id);
						// Then within the children of the parentNode slice out the current node and then fix the positions
						if (!parentNode || !parentNode.children) return;
						// Find the index of the current node within the children
						const nodeIndex = parentNode.children.findIndex((n) => n.data.id === nodeId);
						// Now splice this index out
						parentNode.children.splice(nodeIndex, 1);
						// After the children node has been spliced, check to make sure there is anything in the children
						if (!isEmpty(parentNode.children)) {
							// Update all the positions
							parentNode.children.forEach((node, index) => {
								node.data.position = index + 1;
							});
						} else {
							parentNode.children = undefined;
							parentNode.data.expanded = false;
							parentNode.data.children = undefined;
						}
						// Refresh the tree
						const nextElements = getElements(hierarchy, true);
						setState((prev) => ({
							...prev,
							nodes: handleNodeColor(nextElements.nodes),
							edges: nextElements.edges,
						}));
						closeDialog();
					}}
					handleCancel={closeDialog}
				/>
			</Box>
		);
	};

	const onStartMoveNode = (node) => {
		setState((prev) => ({
			...prev,
			moveNode: node,
		}));
	};

	const onCancelMoveNode = (node) => {
		setState((prev) => ({
			...prev,
			moveNode: {},
		}));
	};

	const onMoveNodeIntoSiblingPosition = (_fromNode, _toNode) => {
		const { hierarchy } = state;
		const parentNodeOfToNode = hierarchy.find((n) => n.data.id === _toNode.parent_id);
		const parentNodeOfFromNode = hierarchy.find((n) => n.data.id === _fromNode.parent_id);
		const fromNode = hierarchy.find((n) => n.data.id === _fromNode.id);
		const toNode = hierarchy.find((n) => n.data.id === _toNode.id);
		// CHANGE THE FROM NODE BEFORE MOVING IT
		fromNode.data.parent_id = toNode.data.parent_id;
		fromNode.data.position = toNode.data.position + 1;
		const depthDifference = toNode.depth - fromNode.depth;
		const updateDepths = (d) => {
			if (d.data.children) {
				d.depth = d.depth + depthDifference;
				d.data.depth = d.depth;
				d.data.children.forEach(updateDepths);
			} else {
				d.depth = d.depth + depthDifference;
				d.data.depth = d.depth;
			}
		};
		updateDepths(fromNode);
		fromNode.height = toNode.height;
		fromNode.parent = parentNodeOfToNode;
		// Inside the fromNode parent, you want to remove this child
		const findElIndex = parentNodeOfFromNode.children.findIndex((n) => n.data.id === fromNode.data.id);
		if (findElIndex >= 0) {
			// Remove this node from the fromNode parent
			parentNodeOfFromNode.children.splice(findElIndex, 1);
			// If you end up splicing the last value in the children, then you need to reset the children
			if (parentNodeOfFromNode.children.length === 0) {
				parentNodeOfFromNode.children = undefined;
				parentNodeOfFromNode.data.expanded = false;
				parentNodeOfFromNode.data.children = undefined;
			} else {
				// Update all the positions
				parentNodeOfFromNode.children.forEach((node, index) => {
					node.data.position = index + 1;
				});
			}
		}
		// Now update the toNode parent.
		if (parentNodeOfToNode.children) {
			// Find the position of the node you're moving to
			const elIndex = parentNodeOfToNode.children.findIndex((n) => n.data.id === toNode.data.id);
			if (elIndex >= 0) {
				parentNodeOfToNode.children.splice(elIndex + 1, 0, fromNode);
				if (parentNodeOfToNode.children.length === 0) {
					parentNodeOfToNode.children = undefined;
					parentNodeOfToNode.data.expanded = false;
					parentNodeOfToNode.data.children = undefined;
				} else {
					// Update all the positions
					parentNodeOfToNode.children.forEach((node, index) => {
						node.data.position = index + 1;
					});
				}
			}
		}
		const updateList = [];
		if (!isEmpty(parentNodeOfToNode.children)) {
			updateList.push(
				...parentNodeOfToNode.children.map((node, nodeIndex) => ({
					id: node.data.id,
					name: node.data.name,
					position: nodeIndex + 1,
				}))
			);
		}
		if (!isEmpty(parentNodeOfFromNode.children) && _fromNode.parent_id !== _toNode.parent_id) {
			updateList.push(
				...parentNodeOfFromNode.children.map((node, nodeIndex) => ({
					id: node.data.id,
					name: node.data.name,
					position: nodeIndex + 1,
				}))
			);
		}
		const nextElements = getElements(hierarchy, false);
		setState((prev) => ({
			...prev,
			nodes: handleNodeColor(nextElements.nodes),
			edges: nextElements.edges,
			moveNode: {},
		}));
		NodeData.moveNode(fromNode.data.id, fromNode.data.parent_id, updateList);
	};

	const onMoveNodeIntoChildPosition = (_fromNode, _toNode) => {
		const { hierarchy } = state;
		// Find the nodes that need to move
		const parentNode = hierarchy.find((n) => n.data.id === _fromNode.parent_id);
		const fromNode = hierarchy.find((n) => n.data.id === _fromNode.id);
		const toNode = hierarchy.find((n) => n.data.id === _toNode.id);
		// CHANGE THE FROM NODE BEFORE MOVING IT
		fromNode.data.parent_id = toNode.data.id;
		fromNode.data.position = 1;
		const depthDifference = toNode.depth - fromNode.depth + 1;
		const updateDepths = (d) => {
			if (d.data.children) {
				d.depth = d.depth + depthDifference;
				d.data.depth = d.depth;
				d.data.children.forEach(updateDepths);
			} else {
				d.depth = d.depth + depthDifference;
				d.data.depth = d.depth;
			}
		};
		updateDepths(fromNode);

		fromNode.height = toNode.height;
		fromNode.parent = toNode;
		toNode.data.children = [fromNode];
		toNode.data.expanded = true;
		toNode.children = toNode.data.children;

		// Handle the parent node
		if (parentNode.children) {
			const fromIndex = parentNode.children.findIndex((n) => n.data.id === fromNode.data.id);
			if (fromIndex >= 0) {
				parentNode.children.splice(fromIndex, 1);
				if (!isEmpty(parentNode.children)) {
					// Update all the positions
					parentNode.children.forEach((node, index) => {
						node.data.position = index + 1;
					});
				} else {
					parentNode.children = undefined;
					parentNode.data.expanded = false;
					parentNode.data.children = undefined;
				}
			}
		}
		const updateList = [];
		// Update previous list
		if (!isEmpty(parentNode.children)) {
			updateList.push(
				...parentNode.children.map((node, nodeIndex) => ({
					id: node.data.id,
					name: node.data.name,
					position: nodeIndex + 1,
				}))
			);
		}
		// Update new list
		updateList.push({
			id: fromNode.data.id,
			name: fromNode.data.name,
			position: 1,
		});
		const nextElements = getElements(hierarchy, false);
		setState((prev) => ({
			...prev,
			nodes: handleNodeColor(nextElements.nodes),
			edges: nextElements.edges,
			moveNode: {},
		}));
		NodeData.moveNode(fromNode.data.id, fromNode.data.parent_id, updateList);
	};

	const onAddContentToNode = (node) => {
		const { hierarchy } = state;
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		if (node.module === 'questions') {
			openDialog(
				<QuestionElement
					title={`Add New Question to Node: ${node.name}`}
					block={block}
					node_id={node.id}
					displayUserControls={false}
					handleClose={closeDialog}
					onAddQuestion={() => {
						selectedNode.data.questions_count = selectedNode.data.questions_count + 1;
						const nextElements = getElements(hierarchy, false);
						setState((prev) => ({
							...prev,
							nodes: handleNodeColor(nextElements.nodes),
							edges: nextElements.edges,
						}));
						closeDialog();
					}}
				/>,
				'fullscreen',
				{ padding: '0px !important' },
				true
			);
		} else if (node.module === 'content') {
			// To be completed
		}
	};

	const getAllDescendants = (node) => {
		const descendants = [];
		if (node.data.children) {
			node.data.children.forEach((child) => {
				descendants.push(child);
				descendants.push(...getAllDescendants(child));
			});
		}
		return descendants;
	};

	const onCheckNode = (node) => {
		const { checked, hierarchy } = state;
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		if (multi === true) {
			const descendants = getAllDescendants(selectedNode);
			const nodeList = [selectedNode.data, ...descendants.map((n) => n.data)];
			const index = checked.findIndex((el) => el.id === selectedNode.data.id);
			if (index >= 0) {
				// This means that the node exists and therefore you should filter OUT the selectedNodes
				setState((prev) => ({
					...prev,
					checked: prev.checked.filter((prevNode) => nodeList.findIndex((el) => el.id === prevNode.id) < 0),
				}));
			} else {
				// This means the node does NOT exist and therefore you should push it into the selected nodes
				// However before you do this, you should remove any references to the children nodes
				const filteredNodeList = nodeList.filter((prevNode) => checked.findIndex((el) => el.id === prevNode.id) < 0);
				setState((prev) => ({
					...prev,
					checked: [...prev.checked, ...filteredNodeList],
				}));
			}
		} else {
			setState((prev) => ({
				...prev,
				singleChecked: selectedNode.data,
			}));
		}
	};

	const onLockUnlockNode = (node) => {
		const { hierarchy } = state;
		const selectedNode = hierarchy.find((n) => n.data.id === node.id);
		if (!selectedNode) return;
		const descendants = getAllDescendants(selectedNode);
		const nodeList = [selectedNode, ...descendants];
		nodeList.forEach((d, i) => {
			d.data.disabled = !d.data.disabled;
		});
		const nextElements = getElements(hierarchy, false);
		setState((prev) => ({
			...prev,
			nodes: handleNodeColor(nextElements.nodes),
			edges: nextElements.edges,
		}));
		NodeData.handleDisabledNodes(nodeList.map((el) => el.data.id));
		// IMPLEMENT CODE TO UPDATE DISABLED STATUS IN THE DATABASE
	};

	const returnQuestionPath = (nodeId) => {
		const { hierarchy } = state;
		const returnPath = (tree, nodeId, path = [], found = {}) => {
			path = path || [];
			found = found || {};
			for (const node of tree) {
				node.path = path.concat(node.data.name);
				if (node.data.id === nodeId) {
					Object.assign(found, node);
				} else if (node.data.children && node.data.children.length > 0) {
					returnPath(node.data.children, nodeId, node.path, found);
				}
			}
			return found.path;
		};
		const path = returnPath([hierarchy], nodeId);
		path[0] = 'Question In';
		return path.join(' > ');
	};

	const onDeleteQuestion = (nodeId) => {
		const { hierarchy } = state;
		const selectedNode = hierarchy.find((n) => n.data.id === nodeId);
		selectedNode.data.questions_count = selectedNode.data.questions_count - 1;
		const nextElements = getElements(hierarchy, false);
		setState((prev) => ({
			...prev,
			nodes: handleNodeColor(nextElements.nodes),
			edges: nextElements.edges,
		}));
	};

	const handleOpenQuestions = () => {
		setQuestionOptions((prev) => ({ ...prev, open: true }));
	};

	const handleCloseQuestions = () => {
		initialiseData();
		setQuestionOptions((prev) => ({ ...prev, open: false, mode: 'study' }));
	};

	const handleChangeView = (view = '') => {
		let url = `/block/${block.code}`;
		if (!isEmpty(view)) url += `/${view}`;
		history.push(url);
	};

	const generateStrip = () => {
		const baseStyle = { fontWeight: 600, textAlign: 'center', padding: '5px 10px', boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' };
		if (isEmpty(appUser)) {
			const sx = { ...baseStyle, backgroundColor: 'secondary.main', color: '#FFF', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } };
			return (
				<a href="/auth/login">
					<Box sx={sx} className="text-upper-spaced">
						To use all features you need to sign in and subscribe. Sign in here.
					</Box>
				</a>
			);
		} else if (!isEmpty(appUser) && block.is_subscribed === false) {
			const sx = { ...baseStyle, backgroundColor: 'secondary.main', color: '#FFF', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } };
			return (
				<a href={`/block/${block.code}/subscription`}>
					<Box sx={sx} className="text-upper-spaced">
						You only have access to 5 questions. Purchase a questions pass for unlimited access.
					</Box>
				</a>
			);
		}
	};

	const handleBecomeATutor = () => {
		openDialog(<BecomeATutor onSubmitApplication={closeDialog} />, 'fullscreen', { padding: '0px !important' }, false);
	};

	// const handleRenderTagQuestions = () => {
	//     <Box sx={styles.nodesContainer}>
	//             <TagQuestions block_id={block.id} />
	//         </Box>
	// }

	if (pageLoading.loading) return <LoadingPageMessage fullPage={true} />;
	if (!pageLoading.loading && pageLoading.error) return <ApiErrorMessage sx={{ margin: '20px auto' }} errorCode={pageLoading.errorCode} errorMessage={pageLoading.errorMessage} />;
	if (!pageLoading.loading && !pageLoading.error && isEmpty(state.nodes)) {
		return (
			<Box
				sx={{
					padding: '20px',
					maxWidth: '600px',
					margin: '50px auto',
					textAlign: 'center',
				}}
			>
				<Typography align="center" className="heading-font" variant="h5">
					{isAdmin ? `There are no nodes in the ${module} module yet. To get started, add one below.` : `Sorry, there are no nodes in the ${module} module yet.`}
				</Typography>
				{isAdmin && (
					<Button variant="contained" sx={{ marginTop: '20px' }} onClick={() => onAddFirstNode()}>
						Add First Node
					</Button>
				)}
			</Box>
		);
	}

	return (
		<React.Fragment>
			<NodeTreeContext.Provider
				value={{
					multi: multi,
					initialNodeId,
					singleChecked: state.singleChecked,
					checkedNodes: state.checked,
					onCheckNode,
					moveNode: state.moveNode,
					onLockUnlockNode,
					onAddChildNode,
					onAddSiblingNode,
					onEditNode,
					onDeleteNode,
					onStartMoveNode,
					onCancelMoveNode,
					onMoveNodeIntoSiblingPosition,
					onMoveNodeIntoChildPosition,
					onToggleNode,
					onAddContentToNode,
				}}
			>
				{questionOptions.open === true && view === 'questions' && (
					<Dialog fullScreen open={questionOptions.open} PaperProps={{ sx: { backgroundColor: '#FFF', display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh' } }}>
						{questionOptions.mode === 'study' && <BrowseQuestions block={block} returnPath={returnQuestionPath} nodes={state.checked} handleClose={handleCloseQuestions} onDeleteQuestion={(nodeId) => onDeleteQuestion(nodeId)} />}
						{questionOptions.mode === 'tag' && <TagQuestions block={block} handleClose={handleCloseQuestions} />}
					</Dialog>
				)}
				{view === 'questions' && (
					<React.Fragment>
						{state.mapDisplayStyle === 'list' && (
							<Box sx={styles.nodesContainer} ref={reactFlowWrapper}>
								<TopicTree nodes={state.hierarchy} />
							</Box>
						)}
						{state.mapDisplayStyle === 'tree' && (
							<Box sx={styles.nodesContainer} ref={reactFlowWrapper}>
								<ReactFlow
									fitView
									minZoom={-Infinity}
									nodes={state.nodes}
									edges={state.edges}
									nodeTypes={nodeTypes}
									onNodeClick={(e, node) => onCheckNode(node)}
									elementsSelectable={false}
									onPaneClick={(e) => onPaneClick(e)}
									proOptions={{
										account: 'paid-pro',
										hideAttribution: true,
									}}
								>
									<Controls />
									<Background />
								</ReactFlow>
							</Box>
						)}
					</React.Fragment>
				)}
				{view === 'tutors' && (
					<Box sx={styles.nodesContainer}>
						<Box sx={styles.tutorsContainer}>
							<TutorsList blockId={block.id} />
						</Box>
					</Box>
				)}
			</NodeTreeContext.Provider>
			{view === 'questions' && withFooter && generateStrip()}
			{withFooter && (
				<AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
					<Toolbar
						sx={{
							minHeight: '56px !important',
							paddingLeft: '0px !important',
							paddingRight: '0px !important',
						}}
					>
						<Box sx={{ overflow: 'auto', display: 'flex', alignItems: 'center', flexGrow: 1, marginRight: '10px' }}>
							{view === 'questions' && (
								<Box sx={{ marginLeft: '10px' }}>
									{isAdmin && (
										<IconButton sx={{ color: '#FFF' }} onClick={() => setQuestionOptions((prev) => ({ ...prev, open: true, mode: 'tag' }))}>
											<TagIcon />
										</IconButton>
									)}
									<IconButton sx={{ color: state.mapDisplayStyle === 'tree' ? 'secondary.main' : '#FFF' }} color="secondary" onClick={() => setState((prev) => ({ ...prev, mapDisplayStyle: 'tree' }))}>
										<TreeIcon />
									</IconButton>
									<IconButton sx={{ color: state.mapDisplayStyle === 'list' ? 'secondary.main' : '#FFF' }} color="secondary" onClick={() => setState((prev) => ({ ...prev, mapDisplayStyle: 'list' }))}>
										<ListIcon />
									</IconButton>
								</Box>
							)}
							<Box className="text-upper-spaced" sx={{ marginLeft: '15px', color: 'secondary.main', fontWeight: 500, display: { xs: 'none', md: 'block' } }}>
								{view === 'questions' && 'Select topics to practice and press go.'}
								{view === 'tutors' && 'Browse and book the best tutors in the country.'}
							</Box>
							<Box sx={{ flexGrow: 1 }}></Box>
							<Box sx={{ marginLeft: '10px', flexShrink: 0 }}>
								<Chip className="text-upper-spaced" color="secondary" variant={view === 'questions' ? 'filled' : 'outlined'} label="Questions" onClick={() => handleChangeView('questions')} />
								<Chip className="text-upper-spaced" color="secondary" variant={view === 'tutors' ? 'filled' : 'outlined'} label="Tutors" onClick={() => handleChangeView('tutors')} />
							</Box>
						</Box>
						{view === 'questions' && (
							<Button disabled={state.checked.length === 0 ? true : false} startIcon={<ClickIcon />} color="secondary" variant="contained" sx={{ flexShrink: 0, margin: 0, borderRadius: '56px 0px 0px 56px', '&.Mui-disabled': { backgroundColor: 'rgba(255,255,255,.15)', color: 'rgba(255,255,255,.3)' } }} onClick={() => handleOpenQuestions()}>
								Go
							</Button>
						)}
						{view === 'tutors' && (
							<Button startIcon={<ClickIcon />} color="secondary" variant="contained" sx={{ flexShrink: 0, margin: 0, paddingRight: '15px', borderRadius: '56px 0px 0px 56px' }} onClick={() => handleBecomeATutor()}>
								We're Hiring
							</Button>
						)}
					</Toolbar>
				</AppBar>
			)}
		</React.Fragment>
	);
}

export default function MapRenderer(props) {
	return (
		<ReactFlowProvider>
			<MapOfNodes {...props} />
		</ReactFlowProvider>
	);
}

{
	/* 
    const handleChangeDurationForQuizMode = (event) => {
		const value = event.target.value;
		setQuestionOptions((prev) => ({ ...prev, duration: value }));
	};
    {questionOptions.mode === 'quiz' && (
    <TextField
        autoFocus
        className="duration-mins"
        type="number"
        variant="outlined"
        sx={{ flexShrink: 0, width: '120px', '& .MuiOutlinedInput-notchedOutline': { display: 'none' }, '& .MuiInputLabel-root, & .MuiOutlinedInput-root': { fontWeight: 500, backgroundColor: 'secondary.main' }, '& .MuiOutlinedInput-input': { padding: '5px 14px', paddingRight: '0px', backgroundColor: 'rgba(255,255,255,.7)', color: 'rgba(0,0,0,.7)', borderRadius: '56px 0 0 56px' } }}
        color="secondary"
        value={questionOptions.duration}
        placeholder="Num"
        onChange={(event) => handleChangeDurationForQuizMode(event)}
        InputProps={{
            endAdornment: (
                <InputAdornment sx={{ '& .MuiTypography-root': { color: 'rgba(255, 255, 255, 0.87)' } }} position="end">
                    Mins
                </InputAdornment>
            ),
        }}
    />
)} */
}
