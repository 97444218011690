/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import './styles.scss';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
/****************************************************************************************************
 * PALETTE
 ****************************************************************************************************/
const defaultTheme = createTheme({
	palette: {
		background: { main: '#040027', lightgrey: '#f9f9f9' },
		gradient: {
			light: 'linear-gradient(120deg, #ad35a0, #4d7ae8)',
			dark: 'linear-gradient(110deg, #050037, #10006c, #350888)',
		},
		primary: createColor('#07003c'),
		secondary: createColor('#ab3693'), // PINK SECONDARY
		// secondary: createColor('#0063ff'), // BLUE COMBO: #001de5 || #030b5d
		lightblue: createColor('#28c2ff'),
		white: createColor('#FFFFFF'),
		blue: createColor('#0053d6'),
		navy: createColor('#171941'), // {dark: '#040023'} #06052F
		pink: createColor('#f575eb'), // { main: #f575eb, flat: '#a454b8', flat2: '#9f45b5', flatLight: '#b66fc8' },
		purple: createColor('#b46bff'), // { dark: "#350888", darker: '#2a066d', darker2: '#040027', lavender: '#7769c4' },
		darkPurple: createColor('#350888'),
		success: createColor('#00796b'), // { main: '#00796b', light: '#c1e5e1', bright: '#00d7c3' },
		error: createColor('#88081B'), // { main: '#88081B', light: '#ffe6e6', flat: '#ef5350', bright: '#c62828', },
		text: { grey: '#515e72', navy: '#07003c' },
	},
});
/****************************************************************************************************
 * COMPONENT STYLE OVERRIDES
 ****************************************************************************************************/
const theme = responsiveFontSizes(
	createTheme({
		palette: {
			mode: 'light',
			...defaultTheme.palette,
		},
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						color: 'rgba(0,0,0,.87)',
						'& .MuiList-root': { padding: 0 },
					},
				},
			},
			MuiAlert: {
				styleOverrides: {
					root: {
						marginBottom: '25px',
						'& .MuiAlert-icon': { color: 'unset' },
					},
					standardSuccess: {
						backgroundColor: defaultTheme.palette.success.main,
						color: '#FFF',
					},
					standardError: {
						backgroundColor: defaultTheme.palette.error.main,
						color: '#FFF',
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					root: {
						'& .MuiPaper-root': {},
					},
				},
			},
			MuiTypography: {
				styleOverrides: {
					root: { fontWeight: 500 },
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					noOptions: { color: 'rgba(0,0,0,.87)' },
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						marginTop: '5px',
						marginLeft: 0,
						marginRight: 0,
					},
				},
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						'& .MuiButton-root': { margin: 0 },
					},
					grouped: {
						'&:not(:last-of-type):hover': { borderRightColor: 'transparent' },
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					root: {
						// '& .MuiTabScrollButton-root.Mui-disabled': { display: 'none' },
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					root: {
						margin: 5,
						marginLeft: 0,
						fontWeight: 500,
						paddingLeft: '5px',
						paddingRight: '5px',
						'&.chip-title': {
							backgroundColor: '#FFF !important',
							color: '#000 !important',
							textTransform: 'uppercase',
							letterSpacing: '1px',
						},
						'&.chip-uppercase': {
							textTransform: 'uppercase',
							letterSpacing: '1px',
						},
					},
					label: {
						lineHeight: 1,
						'& .MuiSvgIcon-root': { fontSize: '1rem' },
					},
					icon: {
						'&.MuiSvgIcon-root': {
							fontSize: '1rem',
						},
					},
					outlined: {
						borderWidth: '2px',
						'&.MuiChip-colorWhite:hover': {
							backgroundColor: '#FFF !important',
							color: '#000',
							'& .MuiChip-deleteIcon': {
								color: '#000',
							},
						},
					},
					filled: {
						'&:not(.MuiChip-colorWhite)': {
							color: 'rgba(255, 255, 255, 0.87)',
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						whiteSpace: 'nowrap',
						height: '56px',
						borderRadius: '56px',
						paddingLeft: '25px',
						paddingRight: '25px',
						margin: '10px',
						marginLeft: 0,
						'&:last-of-type': { marginRight: 0 },
						'&.Mui-disabled': {
							color: 'rgba(0,0,0,.5)',
							backgroundColor: 'rgba(0,0,0,.2)',
							borderColor: 'transparent',
						},
					},
					outlined: {
						border: '3px solid',
						'&:hover': {
							border: '3px solid',
						},
					},
					outlinedPrimary: {
						'&:hover': {
							backgroundColor: defaultTheme.palette.primary.main,
							borderColor: defaultTheme.palette.primary.main,
							color: '#FFF',
						},
					},
					outlinedSecondary: {
						'&:hover': {
							backgroundColor: defaultTheme.palette.secondary.main,
						},
					},
					contained: {
						'&:hover': {
							filter: 'brightness(110%)',
						},
					},
					containedPrimary: {
						border: `3px solid ${defaultTheme.palette.primary.main}`,
					},
				},
			},
			MuiFab: {
				variants: [
					{
						props: { variant: 'fixed' },
						style: {
							position: 'fixed',
							bottom: '20px',
							right: '20px',
							zIndex: 999,
						},
					},
				],
				styleOverrides: {
					root: {
						'&.white': { color: '#000', backgroundColor: '#FFF' },
						'&.pink': {
							color: '#FFF',
							backgroundColor: defaultTheme.palette.pink.main,
						},
						'&:hover': {
							filter: 'brightness(110%)',
						},
					},
				},
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						'&.MuiMenu-root': {
							zIndex: 9999,
						},
					},
				},
			},
			MuiStepLabel: {
				styleOverrides: {
					root: { cursor: 'pointer' },
					label: {
						color: 'rgba(255,255,255,.5)',
						letterSpacing: 1,
						marginLeft: 10,
						cursor: 'pointer',
						'&:hover': {
							filter: 'brightness(150%)',
							color: '#FFF',
						},
						'&.Mui-completed': {
							color: defaultTheme.palette.primary.main,
						},
						'&.Mui-active': { color: '#FFF' },
					},
				},
			},
			MuiStepContent: {
				styleOverrides: {
					root: {
						borderLeftWidth: 3,
						borderColor: 'rgba(255,255,255,.5)',
						marginLeft: 24,
					},
				},
			},
			MuiFilledInput: {
				styleOverrides: {
					root: {
						borderRadius: '4px',
						border: '2px solid rgba(0,0,0,.08)',
						':before': { display: 'none' },
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: '56px',
					},
					notchedOutline: {
						borderWidth: '2px',
					},
				},
			},
		},
	})
);
/****************************************************************************************************
 * THEME PROVIDER
 ****************************************************************************************************/
export default function MuiThemeProvider({ children }) {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
}
