/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData } from '../../data';
import { isEmpty, useKey } from '../../utils';
import { loginUser } from '../AuthData';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../components/Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
LoginForm.defaultProps = {
	darkBackground: true,
};
export default function LoginForm({ darkBackground }) {
	const history = useHistory();
	const [state, setState] = React.useState({
		email: { value: '', error: false, message: '' },
		password: { value: '', error: false, message: '' },
	});
	const [formState, setFormState] = React.useState(InitialData.completed);
	useKey('Enter', () => handleSubmit());

	React.useEffect(() => {
		return () => {
			setFormState(InitialData.completed);
		};
	}, []);

	const handleEmail = (e) => {
		e.persist();
		const email = e.target.value;
		const data = { value: email, error: false, message: '' };
		if (isEmpty(email)) {
			data.error = true;
			data.message = 'Email field cannot be empty.';
		}
		if (!validator.isEmail(email)) {
			data.error = true;
			data.message = 'You must enter a valid email.';
		}
		setState((prev) => ({ ...prev, email: data }));
	};

	const handlePassword = (e) => {
		e.persist();
		const password = e.target.value;
		const data = { value: password, error: false, message: '' };
		if (isEmpty(password)) {
			data.error = true;
			data.message = 'Password field cannot be empty.';
		}
		if (!validator.isStrongPassword(password)) {
			data.error = true;
			data.message = 'Your password should contain at least 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 symbol.';
		}
		setState((prev) => ({ ...prev, password: data }));
	};

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) handleSubmit();
	};

	const handleSubmit = async () => {
		if (checkErrors()) return;
		setFormState(InitialData.loading);
		const res = await loginUser(state.email.value, state.password.value);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setFormState({ ...InitialData.completed, data: res.data });
	};

	const checkErrors = () => {
		if (isEmpty(state.email.value) || isEmpty(state.password.value) || state.email.error === true || state.password.error === true) {
			return true;
		}
		return false;
	};

	return (
		<Box id="login-form" sx={styles.authForm}>
			<TextField fullWidth label="Email" className="text-field-light" variant="filled" color="primary" error={state.email.error} helperText={state.email.message} value={state.email.value} onChange={handleEmail} />
			<TextField fullWidth type="password" label="Password" className="text-field-light" variant="filled" color="primary" error={state.password.error} helperText={state.password.message} value={state.password.value} onChange={handlePassword} />
			{formState.error && <ApiErrorMessage className="error-message" errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
			<LoadingButton fullWidth className={darkBackground === true ? 'btn-light' : 'btn-dark'} color="secondary" disabled={checkErrors()} loading={formState.loading} variant="contained" onClick={handleSubmit} onKeyDown={handleKeyDown}>
				{formState.loading === true ? 'Logging in ...' : 'Login'}
			</LoadingButton>
			<Box className="auth-options">
				<span className="action-text" onClick={() => history.push('/auth/forgotpass')}>
					Forgot Password
				</span>
				<span className="instruction-text">Don't have an account?</span>
				<span className="action-text" onClick={() => history.push('/auth/register')}>
					Register Account
				</span>
			</Box>
		</Box>
	);
}
