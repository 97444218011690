import { supabase } from '../supabaseClient';
import { v4 as uuidv4 } from 'uuid';

export const getUserProfile = async (userId) => {
	try {
		const { data, error, status } = await supabase.from('profiles').select('id, name, avatar_url, email, admin').eq('id', userId).single();
		if (error && status !== 406) throw error;
		return { success: true, data };
	} catch (err) {
		return {
			success: false,
			code: 'User Profile',
			message: err.message,
		};
	}
};

export const registerUser = async (name, email, password) => {
	try {
		const {
			data: { user },
			error,
		} = await supabase.auth.signUp({ email, password, options: { data: { name: name } } });
		if (error) throw error;
		if (user.identities.length === 0) throw { message: 'This user account already exists.' };
		return { success: true, data: true };
	} catch (err) {
		return {
			success: false,
			code: 'Register',
			message: err.message,
		};
	}
};

export const loginUser = async (email, password) => {
	try {
		const { data, error } = await supabase.auth.signInWithPassword({ email: email, password: password });
		if (error) throw error;
		return { success: true, data: true };
	} catch (err) {
		return {
			success: false,
			code: 'Login',
			message: err.error_description || err.message,
		};
	}
};

export const forgotPass = async (email) => {
	try {
		const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
			redirectTo: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/account/update-password' : 'https://learnblock.com/account/update-password',
		});
		if (error) throw error;
		return { success: true, data: true };
	} catch (err) {
		return {
			success: false,
			code: 'Forgot Password',
			message: err.message,
		};
	}
};

export const downloadUserAvatar = async (path) => {
	try {
		const { data, error } = await supabase.storage.from('avatars').download(path);
		if (error) throw error;
		const url = URL.createObjectURL(data);
		return { success: true, data: url };
	} catch (err) {
		return {
			success: false,
			code: 'Avatar Download',
			message: err.message,
		};
	}
};

export const uploadUserAvatar = async (userId, file) => {
	try {
		const fileExt = file.name.split('.').pop();
		const fileName = `${uuidv4()}.${fileExt}`;
		const filePath = fileName;
		let { data: avatar, error: uploadError } = await supabase.storage.from('avatars').upload(filePath, file);
		if (uploadError) throw uploadError;
		const { data, error } = await supabase
			.from('profiles')
			.upsert({
				id: userId,
				avatar_url: avatar.path,
				updated_at: new Date(),
			})
			.select('id, name, avatar_url, admin')
			.single();
		if (error) throw error;
		return { success: true, data };
	} catch (err) {
		return {
			success: false,
			code: 'avatar/upload',
			message: err.message,
		};
	}
};

export const updateUserName = async (userId, newName) => {
	try {
		const { data, error } = await supabase.from('profiles').upsert({ id: userId, name: newName, updated_at: new Date() }).select('id, name, avatar_url, admin').single();
		if (error) throw error;
		return { success: true, data };
	} catch (err) {
		return {
			success: false,
			code: 'Update Name',
			message: err.message,
		};
	}
};

export const updateUserEmail = async (newEmail) => {
	try {
		const { data, error } = await supabase.auth.updateUser({
			email: newEmail,
		});
		if (error) throw error;
		return { success: true, data: data.user };
	} catch (err) {
		return {
			success: false,
			code: 'Update Email',
			message: err.message,
		};
	}
};

export const updateUserPassword = async (newPassword) => {
	try {
		const { data, error } = await supabase.auth.updateUser({ password: newPassword });
		if (error) throw error;
		return { success: true, data: true };
	} catch (err) {
		return {
			success: false,
			code: 'Update Password',
			message: err.message,
		};
	}
};
