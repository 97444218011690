/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../auth';
import { useDialog } from '../../contexts';
import { AppUtils, isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Chip, Avatar, Button, Tab, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Today as DayIcon, Group as MaxSeatsIcon, CheckBox, CheckBoxOutlineBlank, Payment as CostIcon, AccessTime as DurationIcon } from '@mui/icons-material';
import RenderCalendlyBooking from './RenderCalendlyBooking';
import TutorElementCourse from './TutorElementCourse';
import { returnAvailabilitiesByCourse, calcPriceBasedOnDuration, renderTimestampStringFromMins, groupSelectedTimesByCourse } from './helpers';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function TutorElement({ tutor }) {
	const { appUser } = useAuth();
	const { tutor_id, tutor_name, calendly_url, tutor_pic, tutor_timezone, bio, courses, availabilities, bookings } = tutor;
	const [displayEventType, setDisplayEventType] = React.useState('class'); // class || workshop
	const [filters, setFilters] = React.useState([]);
	const filterList = [...new Set(courses.filter((course) => !isEmpty(course.tag) && course.tutor_course.event_type === displayEventType).map((course) => course.tag.domain))];
	const [tempBookings, setTempBookings] = React.useState([]);
	const { openDialog } = useDialog();

	const onBookTutor = () => {
		// <RenderCalendlyBooking calendlyUrl={calendly_url} forName={appUser.name} forEmail={appUser.email} />
		openDialog(
			<Box sx={{ textAlign: 'center' }}>
				<Typography className="heading-font" variant="h4" sx={{ marginBottom: '20px' }}>
					Whoops, this feature is not production ready 😅
				</Typography>
				<Typography className="heading-font" sx={{ color: 'text.grey' }} variant="h6">
					We're busy programming this functionality to allow you to book tutors with ease. We'll email you with an update when it's ready.
				</Typography>
			</Box>,
			false,
			{},
			false
		);
	};

	const handleAddBooking = (newBooking) => {
		const cpBookings = [...tempBookings];
		const bookingRightBeforeThisOne = tempBookings.findIndex((prevBooking) => prevBooking.courseData.id === newBooking.courseData.id && prevBooking.course_type === newBooking.course_type && prevBooking.day_of_week === newBooking.day_of_week && newBooking.start_time === prevBooking.end_time);
		const bookingRightAfterThisOne = tempBookings.findIndex((prevBooking) => prevBooking.courseData.id === newBooking.courseData.id && prevBooking.course_type === newBooking.course_type && prevBooking.day_of_week === newBooking.day_of_week && newBooking.end_time === prevBooking.start_time);
		if (bookingRightBeforeThisOne >= 0) {
			const updatedBooking = {
				...cpBookings[bookingRightBeforeThisOne],
				end_time: newBooking.end_time,
			};
			cpBookings.splice(bookingRightBeforeThisOne, 1, updatedBooking);
		} else if (bookingRightAfterThisOne >= 0) {
			const updatedBooking = {
				...newBooking,
				end_time: cpBookings[bookingRightAfterThisOne].end_time,
			};
			cpBookings.splice(bookingRightAfterThisOne, 1, updatedBooking);
		} else {
			cpBookings.push(newBooking);
		}

		setTempBookings(cpBookings);
	};

	const handleRemoveBooking = (course, time) => {
		setTempBookings((prev) => prev.filter((booking) => !(booking.courseData.id === course.id && booking.day_of_week === time.day_of_week && booking.start_time === time.start_time && booking.end_time === time.end_time)));
	};

	return (
		<Box className="tutor-element">
			<Box className="tutor-banner">
				<Avatar className="tutor-pic" src={tutor_pic}>
					{tutor_name[0]}
				</Avatar>
				<Typography className="heading-font tutor-name" color="primary" variant="h5">
					{AppUtils.nameWithHiddenLastName(tutor_name)}
				</Typography>
			</Box>
			<Box className="tutor-details">
				<Box className="section">
					<Box className="text-upper-spaced section-title">A Short Bio</Box>
					<Typography className="tutor-bio">{bio}</Typography>
				</Box>
				<Box className="section">
					<Box className="text-upper-spaced section-title">{tutor_name.split(' ')[0]} offers (* = coming soon)</Box>
					<Box sx={{ '& .MuiButton-root': { height: '40px', px: '20px', margin: '5px', ml: '0px' } }}>
						<Button size="small" variant={displayEventType === 'class' ? 'contained' : 'outlined'}>
							Weekly Lessons
						</Button>
						<Button size="small" variant="outlined" disabled={true}>
							* Live Workshops
						</Button>
						<Button size="small" variant="outlined" disabled={true}>
							* Mentorship
						</Button>
					</Box>
					<Box sx={{ marginTop: '10px', '& .filter-text': { color: 'text.grey', fontWeight: 600 }, '& .MuiChip-root': { fontSize: '11px' } }}>
						{filterList.map((filterEl) => {
							return <Chip key={`filter-${filterEl}`} className="text-upper-spaced" icon={filters.includes(filterEl) ? <CheckBox /> : <CheckBoxOutlineBlank />} color="secondary" variant={filters.includes(filterEl) ? 'filled' : 'outlined'} label={filterEl} onClick={() => setFilters((prev) => (prev.includes(filterEl) ? prev.filter((el) => el !== filterEl) : [...prev, filterEl]))} />;
						})}
					</Box>
					{displayEventType === 'class' && (
						<Box className="tutor-courses">
							{returnAvailabilitiesByCourse(courses, availabilities, bookings, tempBookings)
								.filter((courseEl) => (isEmpty(filters) ? true : filters.includes(courseEl.tagDomain)))
								.map((course, courseIndex) => {
									return <TutorElementCourse key={`course-${course.data.id}`} timezone={tutor_timezone} tutor_id={tutor_id} courseData={course} handleAddBooking={handleAddBooking} handleRemoveBooking={handleRemoveBooking} bookingsForCourse={groupSelectedTimesByCourse(course.data.id, tempBookings)} />;
								})}
						</Box>
					)}
				</Box>
				<Box className="section" sx={{ marginTop: '20px' }}>
					{isEmpty(tempBookings) && <Box sx={{ color: 'text.grey', fontSize: '14px', marginBottom: '10px' }}>After you have selected all the sessions you want, you will be able to reserve your spot by selecting the button below. No payment is required until after you've attended the session(s).</Box>}
					<Button disabled={isEmpty(tempBookings)} variant="contained" fullWidth color="secondary" onClick={onBookTutor}>
						Reserve Selected Sessions
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
