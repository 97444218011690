const styles = {
    uploadingState: {
        // backgroundColor: '#FFF',
        '& .text': {
            fontWeight: 500, margin: '20px 0px 0px', fontSize: '11px', textTransform: 'uppercase', letterSpacing: '1px',
            '&.saving': {
                display: 'flex', alignItems: 'center', color: 'orange',
                '& .circular-progress': { color: 'orange', marginRight: '10px', }
            },
            '&.error': { color: 'red', },
            '&.success': { color: 'text.grey', }
        }
    }
}

export default styles;