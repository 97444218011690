import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Backdrop } from '@mui/material';
import { LogoIcon } from '../Logo';

LoaderBackdrop.propTypes = {
    loading: PropTypes.bool,
}

LoaderBackdrop.defaultProps = {
    loading: true,
}

export default function LoaderBackdrop({ loading }) {
    return (
        <Backdrop
            sx={{ color: '#fff', backgroundColor: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={loading}
        >
            <Box sx={{ position: 'relative', marginBottom: '60px' }}>
                <CircularProgress size={80} color="secondary" />
                <LogoIcon size={40} sx={{
                    position: 'absolute', top: 'calc(50% - 22.5px)', left: 'calc(50% - 20px)',
                    animation: `spin infinite 2s linear`,
                    '@keyframes spin': {
                        "from": { transform: "rotate(0deg)" },
                        "to": { transform: "rotate(360deg)" },
                    }
                }} />
            </Box>
        </Backdrop>
    )
}