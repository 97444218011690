/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * HELPERS
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
import { calcPriceBasedOnDuration, renderDurationStringFromMins, renderTimestampStringFromMins } from './helpers';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography, Chip } from '@mui/material';
import { Paid as MoneyIcon, Notes as DescIcon, Today as DayIcon, Group as MaxSeatsIcon, RadioButtonChecked, RadioButtonUnchecked, Payment as CostIcon, AccessTime as DurationIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from './styles';
import { sortBookingTimesByDayAndTime } from './helpers';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/

export default function TutorElementCourse({ tutor_id, courseData, timezone, handleAddBooking, handleRemoveBooking, bookingsForCourse }) {
	const [coursePreference, setCoursePreference] = React.useState('individual');
	const [expanded, setExpanded] = React.useState(false);
	const { tagDomain, tagGroup, data: course, individual_times, group_times } = courseData;
	const times = { individual: individual_times || [], group: group_times || [] };

	return (
		<Accordion className="course" expanded={expanded} onChange={(event, isExpanded) => setExpanded(isExpanded)}>
			<AccordionSummary>
				<Box
					sx={{
						paddingX: '10px',
					}}
				>
					<Box className="course-tag text-upper-spaced">
						{tagDomain}
						{!isEmpty(tagGroup) && `: ${tagGroup}`}
					</Box>
					<Typography className="course-name heading-font">
						<span>{course.name}</span>
					</Typography>
					{!isEmpty(bookingsForCourse) && (
						<Box className="course-meta">
							<span className="meta-item text-upper-spaced">
								<MoneyIcon className="meta-icon" />
								<span className="meta-text">{`Total: $${calcPriceBasedOnDuration(bookingsForCourse)}`}</span>
							</span>
						</Box>
					)}
					<Box className="availabilities">
						<span className="meta-item text-upper-spaced">
							<DayIcon className="meta-icon" />
							<span className="meta-text">{`Selected Sessions: `}</span>
						</span>
						{isEmpty(bookingsForCourse) && (
							<span className="no-times">
								<span>You have not selected any sessions yet.</span>
							</span>
						)}
						{!isEmpty(bookingsForCourse) &&
							sortBookingTimesByDayAndTime(bookingsForCourse).map((booking) => {
								return (
									<Chip
										key={`booking-${course.id}-${booking.course_type}-${booking.day_of_week}-${booking.start_time}-${booking.end_time}`}
										size="small"
										className="availability text-upper-spaced"
										icon={<span className="course-type-icon">{booking.course_type === 'individual' ? '1 on 1' : booking.course_type}</span>}
										color="secondary"
										variant="filled"
										label={renderTimestampStringFromMins(booking.day_of_week, booking.start_time, booking.end_time, timezone)}
										onDelete={(e) => {
											e.stopPropagation();
											handleRemoveBooking(course, booking);
										}}
									/>
								);
							})}
					</Box>
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box className="section">
					<Box className="section-title text-upper-spaced">
						<DescIcon className="section-icon" />
						<span>About</span>
					</Box>
					<Typography className="course-desc">{isEmpty(course.desc) ? 'Sorry, there is no description current available' : course.desc}</Typography>
				</Box>
				<Box className="section">
					<Box className="section-title text-upper-spaced">
						<DayIcon className="section-icon" />
						<span>Reserve a Session</span>
					</Box>
					<Box className="course-offered-as">
						{course.offered_as.map((el) => {
							return (
								<Chip
									key={`course-${course.id}-${el}`}
									size="small"
									className="course-offering text-upper-spaced"
									icon={coursePreference === el ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
									color="primary"
									variant={coursePreference === el ? 'filled' : 'outlined'}
									label={el === 'individual' ? '1 on 1' : el}
									onClick={(e) => {
										e.stopPropagation();
										setCoursePreference(el);
									}}
								/>
							);
						})}
					</Box>
					<Box className="availabilities">
						{isEmpty(times[coursePreference]) && (
							<span className="no-times">
								<span>Sorry, all groups are full.</span>
							</span>
						)}
						{!isEmpty(times[coursePreference]) &&
							sortBookingTimesByDayAndTime(times[coursePreference]).map((booking, bookingIndex) => {
								return (
									<Chip
										key={`booking-${course.id}-${booking.course_type}-${booking.day_of_week}-${booking.start_time}-${booking.end_time}`}
										size="small"
										className="availability text-upper-spaced"
										icon={<DurationIcon />}
										color="secondary"
										variant="outlined"
										label={renderTimestampStringFromMins(booking.day_of_week, booking.start_time, booking.end_time, timezone)}
										onClick={(e) => {
											e.stopPropagation();
											const tempBooking = {
												...booking,
												tutor_course_id: course.id,
												tutor_id: tutor_id,
												courseData: course,
											};
											handleAddBooking(tempBooking);
										}}
									/>
								);
							})}
					</Box>
				</Box>
				<Box className="section">
					<Box className="section-title text-upper-spaced">
						<MoneyIcon className="section-icon" />
						<span>Price / {coursePreference === 'individual' ? '1 on 1' : coursePreference} Session</span>
					</Box>
					<Box className="course-meta">
						{coursePreference === 'individual' && (
							<React.Fragment>
								<span className="meta-item text-upper-spaced">
									<CostIcon className="meta-icon" />
									<span className="meta-text">{`$${course.price_individual} / ${renderDurationStringFromMins(course.duration_individual)}`}</span>
								</span>
							</React.Fragment>
						)}
						{coursePreference === 'group' && (
							<React.Fragment>
								<span className="meta-item text-upper-spaced">
									<CostIcon className="meta-icon" />
									<span className="meta-text">{`$${course.price_group} / ${renderDurationStringFromMins(course.duration_group)}`}</span>
								</span>
								<span className="meta-item text-upper-spaced">
									<MaxSeatsIcon className="meta-icon" />
									<span className="meta-text">{`Max ${course.max_seats} Spots`}</span>
								</span>
							</React.Fragment>
						)}
					</Box>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
}
