const styles = {
	blockSelector: {
		'& .MuiListSubheader-root': {
			textTransform: 'uppercase',
			letterSpacing: '1px',
		},
		'& .MuiFormLabel-root.MuiInputLabel-root': {
			left: '10px',
			top: '3px',
			'&.MuiInputLabel-shrink': {
				top: '15px',
			},
		},
		'& .main-selector': {
			backgroundColor: '#FFF',
			'& .MuiOutlinedInput-input': {
				textAlign: 'left',
				paddingLeft: '25px',
				paddingRight: '50px !important',
			},
			'& .MuiOutlinedInput-notchedOutline': {
				display: 'none',
			},
			'& .MuiSelect-icon': {
				fontSize: '2.5rem',
			},
		},
	},
	blocksList: {
		'& .instruction-text': { marginBottom: '20px', fontWeight: 600, color: 'primary.main' },
		'& .coming-soon-text': { marginTop: '10px', color: '#FFF' },
		'& .division': {
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'column',
			padding: { xs: '20px', sm: '50px' },
			minHeight: '300px',
			maxWidth: '100%',
			'& .division-text': { marginBottom: '10px', color: '#FFF', fontWeight: 600 },
			'& .division-title': { fontWeight: 600, fontFamily: 'Poppins', color: '#FFF', marginBottom: '10px' },
			'& .division-blurb': { color: '#FFF' },
			'& .division-options': {
				marginTop: '10px',
				'& .division-option': {
					margin: '10px',
					marginLeft: '0px',
					'&:hover': { backgroundColor: '#FFF', color: '#000' },
				},
			},
		},
	},
};

export default styles;
