/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box } from '@mui/material';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
GridItemElement.defaultProps = {
	sx: {},
};
export default function GridItemElement({ sx, title, children }) {
	return (
		<Box sx={{ ...styles.gridItemElement, ...sx }}>
			<Box className="grid-item-element-banner">
				<span className="title-text text-upper-spaced">{title}</span>
			</Box>
			{children}
		</Box>
	);
}
