/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Box, Paper, Typography } from '@mui/material';
import styles from './styles';
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function Copyright() {
    return (
        <Box>
            <Box sx={styles.helpBanner}>
                <Typography className='heading-font primary main-title' align="center" variant="h3">Copyright Statement</Typography>
                <Typography className='heading-font sub-heading' align="center" variant="h5">Last Updated: 22nd August 2022</Typography>
                <Typography className='blurb-text main-blurb' align="center" variant="h6">
                    This page contains our copyright statement. You may wish to print this page for reference.
                </Typography>
            </Box>
            <Box sx={styles.helpContent}>
                <Paper className="section">
                    <p>LEARNBLOCK{String.fromCharCode(8482)} has an extensive library of protected intellectual property including inventions, compilations of information, applications and works, artistic and literary works, trade marks, designs, knowhow, confidential information, codes, software, applications, layouts, formats, graphics and firmware.</p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} trade marks are protected by common law and the Australian Trade Marks Act 1995 as well as the Australian Consumer law and international treaties. LEARNBLOCK{String.fromCharCode(8482)} trade marks include LEARNBLOCK{String.fromCharCode(8482)}, the logo, and domains include learnblock.com, learnblock.com.au, learnblock.org, learnblock.io, learnblock.ai </p>
                    <p>LEARNBLOCK{String.fromCharCode(8482)} trade secrets, confidential information and knowhow are protected by common law and equivalent international laws and treaties. </p>
                    <p>The LEARNBLOCK{String.fromCharCode(8482)} codes, software, applications, layouts, formats, graphics, firmware, management systems, websites, compilations, videos and artistic and literary works are protected by international copyright law and one or more are registered at the U.S. and Canadian Copyright Offices. </p>
                </Paper>
            </Box>
        </Box>
    )
}