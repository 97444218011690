/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES / CONTEXTS
 ****************************************************************************************************/
import { AuthProvider } from './auth';
import { ErrorProvider, DialogProvider } from './contexts';
import { ScrollToTop } from './utils';
/****************************************************************************************************
 * VIEWS
 ****************************************************************************************************/
import Auth from './auth/pages/AuthPage';
import Account from './auth/pages/AccountPage';
import Home from './views/Home';
import Block from './views/Block';
import HelpCenter from './views/HelpCenter';
import ErrorPage from './views/ErrorPage';
/****************************************************************************************************
 * STYLES / THEME
 ****************************************************************************************************/
import { MuiThemeProvider } from './@styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
function App() {
	return (
		<MuiThemeProvider>
			<ErrorProvider>
				<Router>
					<AuthProvider>
						<ScrollToTop />
						<DialogProvider>
							<Switch>
								<Route exact path="/" component={Home} />
								<Route exact path="/auth/:view" component={Auth} />
								<Route exact path="/account/:view?" component={Account} />
								<Route exact path="/block/:code/:view?" component={Block} />
								<Route exact path="/support/:view?" component={HelpCenter} />
								<Route component={ErrorPage} />
							</Switch>
						</DialogProvider>
					</AuthProvider>
				</Router>
			</ErrorProvider>
		</MuiThemeProvider>
	);
}

export default App;
