import { v4 as uuidv4 } from 'uuid';
import { supabase } from '../supabaseClient';

const UploadData = {
	uploadImage: async (bucket, file) => {
		try {
			const ext = file.name.split('.').pop();
			const fileName = `${uuidv4()}.${ext}`;
			if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
				let { data, error } = await supabase.storage.from(bucket).upload(fileName, file);
				if (error) throw error;
				return { success: true, path: data.path };
			} else {
				throw new Error('Invalid file type. We only accept PNG, JPG, JPEG.');
			}
		} catch (err) {
			return { success: false, code: 'Upload Image', message: err.message };
		}
	},
};

export default UploadData;
