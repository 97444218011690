import { supabase } from '../supabaseClient';

const NodeData = {
	getNodes: async (blockId, module) => {
		try {
			const { data, error } = await supabase.rpc('get_nodes_for_user', { p_block_id: blockId, p_module: 'questions' });
			if (error) throw error;
			return { success: true, data: data.map((el) => ({ ...el, original_count: el.questions_count })) };
		} catch (err) {
			return {
				success: false,
				code: 'Get Nodes',
				message: err.message,
			};
		}
	},
	addNode: async (formData) => {
		try {
			const { data, error } = await supabase.from('nodes').insert(formData).select();
			if (error) throw error;
			return { success: true, data: data[0] };
		} catch (err) {
			return {
				success: false,
				code: 'Add Node',
				message: err.message,
			};
		}
	},
	editNode: async (nodeId, formData) => {
		try {
			const { data, error } = await supabase
				.from('nodes')
				.update({ ...formData })
				.eq('id', nodeId)
				.select();
			if (error) throw error;
			return { success: true, data: data[0] };
		} catch (err) {
			return {
				success: false,
				code: 'Edit Node',
				message: err.message,
			};
		}
	},
	deleteNode: async (nodeId) => {
		try {
			const { error } = await supabase.from('nodes').delete().eq('id', nodeId);
			if (error) throw error;
			return { success: true, data: nodeId };
		} catch (err) {
			return {
				success: false,
				code: 'Delete Node',
				message: err.message,
			};
		}
	},
	handleDisabledNodes: async (nodeIds) => {
		try {
			const { error } = await supabase.rpc('update_disabled_nodes', { node_ids: nodeIds });
			if (error) throw error;
			return { success: true, data: true };
		} catch (err) {
			return {
				success: false,
				code: 'Disable Nodes',
				message: err.message,
			};
		}
	},
	moveNode: async (nodeId, newParentId, updateList) => {
		try {
			// Step 1: Update the the node in the database
			const { error: updateNodeError } = await supabase.from('nodes').update({ parent_id: newParentId }).eq('id', nodeId);
			if (updateNodeError) throw updateNodeError;
			// Step 2: Update the new list
			const { data: updatedList, error: updatedListError } = await supabase.from('nodes').upsert(updateList).select();
			if (updatedListError) throw updatedListError;
			return {
				success: true,
				data: updatedList,
			};
		} catch (err) {
			return {
				success: false,
				code: 'Move Nodes',
				message: err.message,
			};
		}
	},
};

export default NodeData;
