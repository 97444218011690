import { convertFromMilliseconds } from ".";

const taskCodeToString = (taskCode) => {
    if (taskCode === "A1") return "Assessment Task 1";
    if (taskCode === "A2") return "Assessment Task 2";
    if (taskCode === "A3") return "Assessment Task 3";
    if (taskCode === "A4") return "Assessment Task 4";
    if (taskCode === "A4") return "Assessment Task 4";
    if (taskCode === "A5") return "Assessment Task 5";
    if (taskCode === "A6") return "Assessment Task 6";
    if (taskCode === 'HY') return "Half Yearly Exam";
    if (taskCode === 'EY') return "Yearly Exam";
}

const nameWithHiddenLastName = (name) => {
    // Split the full name based on whitespaces and filter out any remaining empty strings
    name = name.split(/\s/g).filter(el => el !== "");
    let newName = "";
    // Take the first name and capitalise the first letter
    newName += name[0][0].toUpperCase() + name[0].slice(1).toLowerCase();
    // Take the last name and capitalise the first letter
    newName += " " + name[name.length - 1][0].toUpperCase() + '.';
    return newName;
}

const examFullName = (exam) => {
    return `${exam.year} ${exam.school.name} ${taskCodeToString(exam.task)}`;
}

const calculateTimeSpentOnSingleExam = (timeSpent) => {
    if (timeSpent > 3600000) {
        // If the total milliseconds is greater than 3,600,000 (i.e. 1 hour), convert the time into hours
        const timeInHours = convertFromMilliseconds(timeSpent, 'hours');
        return `${timeInHours} Hours`;
    } else if (timeSpent > 60000 && timeSpent <= 3600000) {
        // If the total milliseconds is greater than 60,000 (i.e. 1 minute), but less than or equal to 3,600,000 (i.e. 1 hour), convert to minutes
        const timeInMinutes = convertFromMilliseconds(timeSpent, 'minutes');
        return `${timeInMinutes} Minutes`;
    } else if (timeSpent === 60000) {
        return '1 Minute'
    } else {
        return '0 Minutes';
    }
}

const sortAnnotations = (_annotations) => {
    if (_annotations.length < 2) return _annotations;
    const sorted = _annotations.sort((a, b) => {
        // Create a proxy string that takes into account whether an object is a question or solution
        const aValue = `${a.data.questionNumber}${a.data.questionPart}${a.data.type === 'question' ? 'a' : 'b'}`;
        const bValue = `${b.data.questionNumber}${b.data.questionPart}${b.data.type === 'question' ? 'a' : 'b'}`;
        return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
    });
    return sorted;
}

const sortQuestions = (_questions) => {
    if (_questions.length < 2) return _questions;
    const sorted = _questions.sort((a, b) => {
        // Create a proxy string that takes into account whether an object is a question or solution
        const aValue = `${a.questionNumber}`;
        const bValue = `${b.questionNumber}`;
        return aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: 'base' });
    });
    return sorted;
}

const AppUtils = {
    nameWithHiddenLastName,
    taskCodeToString,
    examFullName,
    calculateTimeSpentOnSingleExam,
    sortAnnotations,
    sortQuestions,
}

export default AppUtils;