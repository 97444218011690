/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { LoginForm } from '../../../auth/forms';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
import styles from './styles';
import { LogoIconTextTitle } from '../../../@core/Logo';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function LoginRegisterNow() {
	return (
		<Box sx={styles.loginRegisterNow}>
			<LogoIconTextTitle size="small" />
			<Typography align="center" className="heading-font" variant="h6" sx={{ marginBottom: '20px' }}>
				Sorry, you need to be logged in to use this feature.
			</Typography>
			<LoginForm darkBackground={false} />
		</Box>
	);
}
