/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/****************************************************************************************************
 * STYLES
 ****************************************************************************************************/
import { styled } from '@mui/material/styles';
const TimelinePathWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })(({ theme, color }) => ({
	width: '100%',
	margin: 'auto',
	'& .circle': { fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0, width: '50px', height: '50px', borderRadius: '50%', border: '4px solid', backgroundColor: color ? color : theme.palette.primary.main, color: 'rgba(255,255,255,.7)', zIndex: 2, fontFamily: "'Poppins', sans-serif" },
	'& .row': { display: 'flex', flexWrap: 'wrap' },
	'& .align-items-center': { alignItems: 'center !important' },
	'& .justify-content-end': { justifyContent: 'flex-end !important' },
	'& .col': { position: 'relative', width: '100%', minHeight: 1, paddingRight: '15px', paddingLeft: '15px' },
	'& .col-2': { flex: '0 0 16.666667%', maxWidth: '16.666667%' },
	'& .col-6': {
		flex: '0 0 60%',
		maxWidth: '60%',
		[theme.breakpoints.down('md')]: { flex: '0 0 70%', maxWidth: '70%' },
		[theme.breakpoints.down('sm')]: { flex: '0 0 80%', maxWidth: '80%' },
	},
	'& .col-8': { flex: '0 0 66.666667%', maxWidth: '66.666667%' },
	hr: { border: 0, boxSizing: 'content-box', overflow: 'visible' },
	'& .timeline-section-aim': { background: '#FFF', padding: '20px 40px', borderRadius: 4, margin: '20px 0', fontWeight: 400 },
	'& .how-it-works': {
		'&.row': {
			display: 'flex',
			'& .col-2': {
				display: 'inline-flex',
				alignSelf: 'stretch',
				alignItems: 'center',
				justifyContent: 'center',
				'&::after': { content: "''", position: 'absolute', borderLeft: `4px dashed ${color ? color : theme.palette.primary.main}`, zIndex: 1 },
				'&.bottom::after': { height: '50%', left: '50%', top: '50%' },
				'&.full-right::after': { height: '100%', left: 'calc(50% - 4px)' },
				'&.full-left::after': { height: '100%', left: '50%' },
				'&.top::after': { height: '50%', left: '50%', top: 0 },
			},
		},
	},
	'& .timeline': {
		'& div': { padding: 0, height: 40 },
		'& hr': { borderTop: `4px dashed ${color ? color : theme.palette.primary.main}`, margin: 0, top: 16, position: 'relative' },
		'& .col-2': { display: 'flex', overflow: 'hidden' },
		'& .corner': { border: `4px dashed ${color ? color : theme.palette.primary.main}`, width: '100%', position: 'relative', borderRadius: 15 },
		'& .top-right': { left: '50%', top: '-50%' },
		'& .left-bottom': { left: '-50%', top: 'calc(50% - 4px)' },
		'& .top-left': { left: '-50%', top: '-50%' },
		'& .right-bottom': { left: '50%', top: 'calc(50% - 4px)' },
	},
}));
/****************************************************************************************************
 * SUPPORTING FUNCTIONS / COMPONENTS
 ****************************************************************************************************/
const StartTimeline = ({ number, component }) => {
	return (
		<>
			<div className="row align-items-center how-it-works">
				<div className="col col-2 text-center bottom">
					<div className="circle">{number}</div>
				</div>
				<div className="col col-6">{component}</div>
			</div>
			<div className="row timeline">
				<div className="col col-2">
					<div className="corner top-right"></div>
				</div>
				<div className="col col-8">
					<hr />
				</div>
				<div className="col col-2">
					<div className="corner left-bottom"></div>
				</div>
			</div>
		</>
	);
};
const EvenTimeline = ({ number, component }) => {
	return (
		<>
			<div className="row align-items-center justify-content-end how-it-works">
				<div className="col col-6 text-right">{component}</div>
				<div className="col col-2 text-center full-right">
					<div className="circle">{number}</div>
				</div>
			</div>
			<div className="row timeline">
				<div className="col col-2">
					<div className="corner right-bottom"></div>
				</div>
				<div className="col col-8">
					<hr />
				</div>
				<div className="col col-2">
					<div className="corner top-left"></div>
				</div>
			</div>
		</>
	);
};
const OddTimeline = ({ number, component }) => {
	return (
		<>
			<div className="row align-items-center how-it-works">
				<div className="col col-2 text-center full-left">
					<div className="circle">{number}</div>
				</div>
				<div className="col col-6">{component}</div>
			</div>
			<div className="row timeline">
				<div className="col col-2">
					<div className="corner top-right"></div>
				</div>
				<div className="col col-8">
					<hr />
				</div>
				<div className="col col-2">
					<div className="corner left-bottom"></div>
				</div>
			</div>
		</>
	);
};
const EndTimeline = ({ number, component }) => {
	return (
		<div className="row align-items-center justify-content-end how-it-works">
			<div className="col col-6 text-right" style={{ marginBottom: '80px' }}>
				{component}
			</div>
			<div className="col col-2 text-center full-right">
				<div className="circle">{number}</div>
			</div>
		</div>
	);
};
/****************************************************************************************************
 * PROPS
 ****************************************************************************************************/
PathTimeline.propTypes = {
	sections: PropTypes.array.isRequired,
	color: PropTypes.string,
};
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function PathTimeline({ sections, color }) {
	return (
		<TimelinePathWrapper color={color}>
			{sections.map((section, index) => {
				let currNum = index + 1;
				let component = null;
				if (currNum === 1) {
					component = <StartTimeline key={`section-timeline-${currNum}`} number={currNum} component={section} />;
				} else if (currNum === sections.length) {
					component = <EndTimeline key={`section-timeline-${currNum}`} number={currNum} component={section} />;
				} else {
					if (currNum % 2 === 0) component = <EvenTimeline key={`section-timeline-${currNum}`} number={currNum} component={section} />;
					if (currNum % 2 !== 0) component = <OddTimeline key={`section-timeline-${currNum}`} number={currNum} component={section} />;
				}
				return component;
			})}
		</TimelinePathWrapper>
	);
}
