import { supabase } from '../supabaseClient';
import { FunctionsHttpError, FunctionsRelayError, FunctionsFetchError } from '@supabase/supabase-js';

const handleEdgeFunctionError = async (error) => {
	if (error instanceof FunctionsHttpError) {
		const errorMessage = await error.context.json();
		return `Func error: ${errorMessage.message}`;
	} else if (error instanceof FunctionsRelayError) {
		return `Relay error: ${error.message}`;
	} else if (error instanceof FunctionsFetchError) {
		return `Fetch error: ${error.message}`;
	}
};

const SubscriptionData = {
	getStripePrices: async (blockId) => {
		try {
			const { data, error } = await supabase.functions.invoke('stripe-actions', { body: { action: 'get-stripe-prices', data: { block_id: blockId } } });
			if (error) throw error;
			return { success: true, data: data };
		} catch (err) {
			return {
				success: false,
				code: 'Get Block Prices',
				message: await handleEdgeFunctionError(err),
			};
		}
	},
	createStripeCheckout: async (blockId, planId) => {
		try {
			const { data, error } = await supabase.functions.invoke('stripe-actions', { body: { action: 'create-stripe-checkout', data: { block_id: blockId, plan_id: planId } } });
			if (error) throw error;
			return { success: true, data: data };
		} catch (err) {
			return {
				success: false,
				code: 'Checkout Payment',
				message: await handleEdgeFunctionError(err),
			};
		}
	},
	getStripeCustomerPortal: async (blockCode) => {
		try {
			const { data, error } = await supabase.functions.invoke('stripe-actions', { body: { action: 'get-stripe-customer-portal', data: { blockCode } } });
			if (error) throw error;
			return { success: true, data: data };
		} catch (err) {
			return {
				success: false,
				code: 'Stripe Customer Portal',
				message: await handleEdgeFunctionError(err),
			};
		}
	},
};

export default SubscriptionData;
