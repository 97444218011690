import React from 'react';
import { isEmpty } from '../../../utils';
import { Position } from 'react-flow-renderer';
import flextree from './d3FlexTree';
import { scaleLinear as d3ScaleLinear } from 'd3-scale';

export const calcNodeSize = (node) => {
	let numBtns = 4;
	let numLetters = node.data.name.length;
	if (node.data.module === 'questions') {
		numLetters = numLetters + 6;
	}
	let width = numBtns * 32 + numLetters * 10 + 50;
	return [30, width];
};

const colorScale = d3ScaleLinear().domain([0, 5]).range(['#d63384', '#0041d0']);
// const colorScale = d3ScaleLinear().domain([0, 5]).range(['#0053d6', '#d63384']);
// const colorScale = d3ScaleLinear().domain([0, 5]).range(['#0053d6', '#07003c']);

const layout = flextree().nodeSize(calcNodeSize);

export const d3Hierarchy = (data) => layout.hierarchy(data);

export const generateTree = (rootName, dataset, module) => {
	const hashTable = Object.create(null);
	dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
	const dataTree = [];
	dataset.forEach((aData) => {
		if (aData.parent_id && aData.parent_id in hashTable) {
			hashTable[aData.parent_id].children.push(hashTable[aData.id]);
		} else {
			dataTree.push(hashTable[aData.id]);
		}
	});
	return {
		id: 'b6c54489-38a0-5f50-a60a-fd8d76219cae',
		parent_id: '0',
		name: 'All',
		module: module,
		original_count: 0,
		questions_count: 0,
		children: dataTree,
	};
};

export const updateQuestionsCount = (rootNode) => {
	for (let child of rootNode.children) {
		rootNode.questions_count += updateQuestionsCount(child);
	}
	return rootNode.questions_count;
};

export const handleNodeColor = (nodes) => {
	return nodes.map((n) => {
		return {
			...n,
			style: { ...n.style, backgroundColor: colorScale(n.data.depth) },
		};
	});
};

export const searchTree = (nodeId, hierarchy) => {
	if (isEmpty(nodeId)) return hierarchy;
	let stack = [],
		node,
		ii;
	stack.push(hierarchy);
	while (stack.length > 0) {
		node = stack.pop();
		if (node.data.id === nodeId) {
			// Found the node
			return node;
		} else if (node.data.children && node.data.children.length) {
			for (ii = 0; ii < node.data.children.length; ii += 1) {
				stack.push(node.data.children[ii]);
			}
		}
	}
	// Did not find it. Return null.
	return null;
};

export const getElements = (currNode, expand = true) => {
	if (!currNode) return;
	if (expand === true) {
		currNode.descendants().forEach((d, i) => {
			d.children = d.data.expanded ? d.data.children : null;
		});
	}
	const root = layout(currNode);
	const nodes = root.descendants().map((d) => {
		return {
			id: d.data.id,
			data: { ...d.data, label: d.data.name, depth: d.depth },
			position: { x: d.y, y: d.x },
			style: { backgroundColor: colorScale(d.depth) },
			sourcePosition: Position.Right,
			targetPosition: Position.Left,
			type: 'customNode',
		};
	});
	const edges = root.links().map((d, i) => ({
		id: `${i}`,
		source: d.source.data.id,
		target: d.target.data.id,
	}));
	return { nodes, edges };
};

export const NodeTreeContext = React.createContext({});
