/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
import { InitialData, InstitutionsData } from '../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, TextField, Typography, Chip, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SelectCountry, SelectState } from '../../@core/Select';
import styles from './styles';
import { ApiErrorMessage } from '../Messages';
import { IOSSwitch } from '../../@core/Switch';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function AddInstitutionForm({ name, onSubmit }) {
	const [state, setState] = React.useState({
		category: '', // primary || secondary || combined || special || tertiary
		acronym: '',
		sector: '', // private || public
		name: '',
		verified: true,
		country: '',
		state: '',
	});
	const [formState, setFormState] = React.useState(InitialData.completed);

	React.useEffect(() => {
		setState((prev) => ({ ...prev, name }));
	}, []);

	const isDisabledSubmit = () => {
		if (isEmpty(state.category) || isEmpty(state.name) || (state.category === 'tertiary' && isEmpty(state.acronym)) || isEmpty(state.country) || isEmpty(state.sector)) return true;
		return false;
	};

	const handleSubmitInstitution = async () => {
		setFormState(InitialData.loading);
		const res = await InstitutionsData.addInstitution(state);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		if (onSubmit) onSubmit(res.data);
		setFormState(InitialData.completed);
	};

	return (
		<Box sx={styles.institutionForm}>
			<Typography className="heading-font" variant="h5">
				Add Institution
			</Typography>
			<Box className="institution__type chip-options">
				<Box className="title-text text-upper-spaced" sx={{ color: isEmpty(state.category) ? 'error.main' : 'primary.main' }}>
					* Category - Please select an institute category
				</Box>
				<Box>
					<Chip className="text-upper-spaced" color="primary" variant={state.category === 'primary' ? 'filled' : 'outlined'} label="Primary" onClick={() => setState((prev) => ({ ...prev, category: 'primary' }))} />
					<Chip className="text-upper-spaced" color="primary" variant={state.category === 'secondary' ? 'filled' : 'outlined'} label="Secondary" onClick={() => setState((prev) => ({ ...prev, category: 'secondary' }))} />
					<Chip className="text-upper-spaced" color="primary" variant={state.category === 'combined' ? 'filled' : 'outlined'} label="Combined" onClick={() => setState((prev) => ({ ...prev, category: 'combined' }))} />
					<Chip className="text-upper-spaced" color="primary" variant={state.category === 'special' ? 'filled' : 'outlined'} label="Special" onClick={() => setState((prev) => ({ ...prev, category: 'special' }))} />
					<Chip className="text-upper-spaced" color="primary" variant={state.category === 'tertiary' ? 'filled' : 'outlined'} label="Tertiary" onClick={() => setState((prev) => ({ ...prev, category: 'tertiary' }))} />
				</Box>
			</Box>
			<Box className="institution__type chip-options">
				<Box className="title-text text-upper-spaced" sx={{ color: isEmpty(state.sector) ? 'error.main' : 'primary.main' }}>
					* What sector is this institution from?
				</Box>
				<Box>
					<Chip className="text-upper-spaced" color="primary" variant={state.sector === 'public' ? 'filled' : 'outlined'} label="Public" onClick={() => setState((prev) => ({ ...prev, sector: 'public' }))} />
					<Chip className="text-upper-spaced" color="primary" variant={state.sector === 'private' ? 'filled' : 'outlined'} label="Private" onClick={() => setState((prev) => ({ ...prev, sector: 'private' }))} />
				</Box>
			</Box>
			<TextField color="primary" variant="outlined" fullWidth label="Institution Name" error={isEmpty(state.name)} helperText={isEmpty(state.name) && 'The name cannot be empty.'} value={state.name} onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))} />
			{state.category === 'tertiary' && <TextField color="primary" variant="outlined" fullWidth label="Institute Acronym" error={isEmpty(state.acronym)} helperText={isEmpty(state.acronym) && 'The acronym cannot be empty.'} value={state.acronym} onChange={(e) => setState((prev) => ({ ...prev, acronym: e.target.value }))} />}
			<SelectCountry country={state.country} onChange={(country) => setState((prev) => ({ ...prev, country }))} />
			{!isEmpty(state.country) && <SelectState country={state.country} state={state.state} onChange={(stateCode) => setState((prev) => ({ ...prev, state: stateCode }))} />}
			{!formState.loading && formState.error && <ApiErrorMessage sx={{ padding: '0px !important', '& .MuiPaper-root': { marginBottom: '0px !important' } }} errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
			<FormControlLabel sx={{ marginLeft: '0px', marginTop: '10px' }} control={<IOSSwitch />} checked={state.verified} label="Mark as Verified?" onChange={(e) => setState((prev) => ({ ...prev, verified: e.target.checked }))} />
			<LoadingButton sx={{ marginTop: '30px', marginBottom: 0 }} fullWidth variant="contained" loading={formState.loading} disabled={isDisabledSubmit()} onClick={() => handleSubmitInstitution()}>
				Submit Institution
			</LoadingButton>
		</Box>
	);
}
