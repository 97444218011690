/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Typography } from '@mui/material';
import { LogoIconTextTitle } from '../../@core/Logo';
import { ForgotPassForm, LoginForm, RegisterForm } from '../forms';
import styles from './styles';
import { useAuth } from '../AuthProvider';
import { AnimationSlidingSquares } from '../../@core/Animation';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function AuthPage() {
	const { view } = useParams();
	const { appUser } = useAuth();

	if (!isEmpty(appUser)) return <Redirect to="/" />;

	const getSubText = () => {
		if (view === 'login') {
			return {
				mainTitle: `We've missed you`,
				subTitle: 'Sign in to continue your exam prep.',
			};
		} else if (view === 'register') {
			return {
				mainTitle: `A World of Learning Awaits`,
				subTitle: "Sign up to access the world's most advanced question bank.",
			};
		} else if (view === 'forgotpass') {
			return {
				mainTitle: 'Forgot Pass',
				subTitle: "Don't worry, we all forget. Submit your email to reset your password.",
			};
		} else {
			return { mainTitle: '', subTitle: '' };
		}
	};

	return (
		<Box sx={styles.authPageContainer}>
			<AnimationSlidingSquares />
			<Box className="banner">
				<LogoIconTextTitle size="small" />
				<Typography className="main-title heading-font" variant="h3">
					{getSubText().mainTitle}
				</Typography>
				<Typography color="white.main" className="heading-font banner-blurb" variant="h5">
					{getSubText().subTitle}
				</Typography>
				<Box sx={{ maxWidth: '600px', width: '100%' }}>
					{view === 'register' && <RegisterForm />}
					{view === 'login' && <LoginForm />}
					{view === 'forgotpass' && <ForgotPassForm />}
				</Box>
			</Box>
		</Box>
	);
}
