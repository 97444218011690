import React from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import RemarkMathPlugin from 'remark-math';
import RemarkGfmPlugin from 'remark-gfm';
// import rehypeKatex from 'rehype-katex';
import rehypeMath from './rehypeMath';
import rehypeRaw from 'rehype-raw';
// import { InlineMath, BlockMath } from 'react-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';
import './markdown.css';
import { isEmpty } from '../../utils';
import { LoaderImage } from '../../@core/Loader';
import { Box, IconButton, Checkbox, Chip } from '@mui/material';
import { Close as CloseIcon, RadioButtonUncheckedOutlined as SelectIcon, Cancel as CrossIcon, Done as TickIcon } from '@mui/icons-material';
import { useAuth } from '../../auth';

const HandleBlock = (node, children, solutionProps = {}) => {
	const { properties } = node;
	if (!isEmpty(properties) && properties.dataType === 'solution-step') {
		const { dataStep, dataDesc, dataTags, dataRaw } = properties;
		const { correct, questionPerformanceSteps, handleStepSelection } = solutionProps;
		const isSelected = !isEmpty(solutionProps) && correct === false ? questionPerformanceSteps.findIndex((el) => el.step === dataStep) : false;
		return (
			<Box className="solution-step">
				<Box>
					<Chip icon={correct === true ? <TickIcon /> : correct === false ? isSelected >= 0 ? <CrossIcon /> : <SelectIcon /> : null} className="text-upper-spaced" color={correct === true ? 'success' : correct === false && isSelected >= 0 ? 'error' : 'secondary'} variant="contained" label={`Step ${dataStep}`} onClick={() => (correct === false ? handleStepSelection({ dataStep, dataDesc, dataTags, dataRaw }) : null)} />
					{!isEmpty(dataDesc) && <Box sx={{ marginTop: '5px', color: 'text.grey', fontStyle: 'italic' }}>{dataDesc}</Box>}
				</Box>
				{children}
			</Box>
		);
	}
	return <div>{children}</div>;
};

function MarkdownRender(props) {
	const { bucket, correct, questionPerformanceSteps, handleStepSelection } = props;
	let newBaseUrl = 'https://nikijnwlnwspvujsofcd.supabase.co/storage/v1/object/public/questions';
	if (bucket === 'questions') newBaseUrl = 'https://nikijnwlnwspvujsofcd.supabase.co/storage/v1/object/public/questions';

	const solutionProps = {
		correct: correct,
	};

	if (correct === false) {
		solutionProps.questionPerformanceSteps = questionPerformanceSteps;
		solutionProps.handleStepSelection = handleStepSelection;
	}

	const newProps = {
		...props,
		remarkPlugins: [RemarkGfmPlugin, RemarkMathPlugin],
		rehypePlugins: [rehypeRaw, rehypeMath],
		components: {
			p: ({ node, children }) => HandleBlock(node, children, solutionProps),
			img: ({ src }) => {
				return <LoaderImage src={src} />;
			},
			code: ({ node, inline, className, children, ...props }) => {
				const match = /language-(\w+)/.exec(className || '');
				return !inline && match ? (
					<SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={vscDarkPlus} language={match[1]} PreTag="div" showLineNumbers customStyle={{ borderRadius: '10px' }} {...props} />
				) : (
					<code className={className} {...props}>
						{children}
					</code>
				);
			},
		},
		children: !isEmpty(props.children) ? props.children.replace(/LB_IMG_BASE_URL/g, newBaseUrl) : props.children,
	};

	return <ReactMarkdown className={clsx('markdown-body', { [props.className]: !isEmpty(props.className) })} {...newProps} />;
}

export default MarkdownRender;
