import { styled } from '@mui/material/styles';

const FormFilled = styled('div', { shouldForwardProp: (prop) => !['sx'].includes(prop) })(({ theme, sx }) => ({
    width: '100%', maxWidth: '600px', padding: '40px', borderRadius: '4px', margin: '0px auto', display: 'flex', flexDirection: 'column',
    '& .MuiFormControl-root': {
        marginBottom: 20,
        // OUTLINED
        '& .MuiOutlinedInput-root': {
            borderRadius: '56px', color: '#000',
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                '& legend > span': { paddingRight: '15px', textTransform: 'uppercase', letterSpacing: '1px' },
            },
            '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': { border: '2px solid #ff7474' },
            '& .MuiOutlinedInput-input': {
                '&.Mui-disabled': {
                    borderRadius: '56px', WebkitTextFillColor: '#a6a6a6', color: '#a6a6a6 !important', backgroundColor: '#e0e0e0 !important', borderColor: 'transparent !important',
                    '& + .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent !important' }
                },
                '&.MuiInputBase-input:-webkit-autofill': { borderRadius: 'inherit', WebkitTextFillColor: 'unset', WebkitBoxShadow: 'none' },
            },
        },
        // FILLED
        '& .MuiFilledInput-root': {
            backgroundColor: '#ecf4ff', borderRadius: '56px', color: '#000',
            '& .MuiFilledInput-input': {
                paddingLeft: '22px',
                '&.Mui-disabled': { borderRadius: '56px', WebkitTextFillColor: '#a6a6a6', color: '#a6a6a6 !important', backgroundColor: '#e0e0e0 !important', borderColor: 'transparent !important', },
                '&.Mui-error': { border: '2px solid #ff7474', },
                '&.MuiInputBase-input:-webkit-autofill': { borderRadius: 'inherit', WebkitTextFillColor: 'unset', WebkitBoxShadow: 'none' },
            },
            '&.MuiInputBase-root:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
            '&.MuiInputBase-root:before': { borderBottom: 'none' },
            '&.MuiInputBase-root:after': { borderBottom: 'none' },
        },
        // MULTI-LINE
        '& .MuiInputBase-multiline': {
            borderRadius: '10px', paddingLeft: 0,
        },
        // LABELS
        '& .MuiInputLabel-root': {
            color: "#777", left: '10px',
            '&.MuiInputLabel-shrink': { color: theme.palette.primary.main, fontWeight: '600', textTransform: 'uppercase', letterSpacing: '1px' },
        },
        // HELPER TEXT
        '& .MuiFormHelperText-root': {
            margin: 10, marginBottom: 0,
            '&.Mui-error': { color: '#ff7474' },
        },
        // ICONS
        '& .MuiSvgIcon-root.MuiSelect-icon': { color: '#999', width: '1.4em', height: '1.4em', top: 'calc(50% - 0.7em)', right: '12px' },
    },
    // BUTTONS
    '& .MuiButton-root': {
        borderRadius: '56px', height: '56px',
        '&:hover': { filter: 'brightness(110%)' },
    },
    // LINKS
    '& .links': {
        color: 'rgba(0,0,0,.67)', margin: '20px 0', textAlign: 'center',
        '& .separator': { margin: '0 10px', },
        '& .link': {
            color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 500,
            '&:hover': { filter: 'brightness(150%)', },
            '&.blue': { color: theme.palette.blue.main }
        },
    },
    ...sx,
}));

export default FormFilled;