/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
/**************************************************************************************************** 
 * STYLES
****************************************************************************************************/
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '@mui/styled-engine';
const DualButtonWrapper = styled("div", { shouldForwardProp: (prop) => prop !== 'white' })(({ theme, white }) => ({
    textAlign: 'center', margin: '20px auto',
    '& .MuiButton-root': { margin: '10px 0' },
    '& .or-text': { display: 'inline', margin: '20px', color: white ? '#FFF' : theme.palette.primary.main, fontWeight: 500, [theme.breakpoints.down('md')]: { display: 'block' }, },
    '& .bounce': { color: white ? '#FFF' : theme.palette.primary.main, animation: 'bounce 2s infinite ease-in-out', fontSize: 60, display: 'block', margin: '0 auto 20px', },
    '@keyframes bounce': {
        "0%": { transform: 'translateY(-5px)' },
        "50%": { transform: 'translateY(10px)' },
        "100%": { transform: 'translateY(-5px)' },
    }
}));
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function DualButton({ white, buttons }) {
    return (
        <DualButtonWrapper white={white ? true : false}>
            <ExpandMoreIcon className="bounce" />
            {buttons[0]}
            <div className="or-text">OR</div>
            {buttons[1]}
            {buttons.length === 3 && (
                <React.Fragment>
                    <div className="or-text">OR</div>
                    {buttons[2]}
                </React.Fragment>
            )}
        </DualButtonWrapper>
    )
}