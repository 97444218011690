const styles = {
	header: {
		'& .block-toolbar': {
			minHeight: '56px !important',
			paddingLeft: '0px !important',
			paddingRight: '0px !important',
		},
		'& .block-icon-container': {
			flexShrink: 0,
			height: '56px',
			width: '56px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'secondary.main',
			marginRight: '10px',
		},
	},
	revisionTitle: { paddingBottom: '20px', marginBottom: '30px', textAlign: 'right' },
	helpBanner: {
		textAlign: 'center',
		maxWidth: '600px',
		margin: '40px auto 0',
		'& .main-title': {
			margin: '70px auto 30px',
		},
		'& .sub-heading': { marginBottom: '20px' },
		'& .main-blurb': { padding: '20px' },
		'& .blurb-text': { fontFamily: '"Poppins", sans-serif !important', color: 'text.grey', lineHeight: '1.6 !important', fontWeight: '400 !important' },
	},
	helpContent: {
		maxWidth: '800px',
		margin: '0px auto 40px',
		padding: '20px',
		'& .section': {
			padding: '30px 60px',
			boxShadow: '0 0 35px rgb(0 0 0 / 10%)',
			margin: '40px 0',
			'& p': { marginBottom: '20px', textAlign: 'justify' },
		},
	},
};

export default styles;
