/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { Edit as PenIcon, EditOff as EraseIcon, Undo as UndoIcon, Redo as RedoIcon, LayersClear as ClearIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
Whiteboard.defaultProps = {
	sx: {},
};
export default function Whiteboard({ sx, handleClose }) {
	const canvasRef = React.useRef(null);
	const [canvasValue, setCanvasValue] = React.useState('draw');

	// Implement this to load up the paths from the DB
	// React.useEffect(() => {
	//     const handleLoadPaths = async () => {
	//         const loadPaths = canvasRef.current.loadPaths;
	//         if (loadPaths) {
	//             // await loadPaths(res.data)
	//         }
	//     }
	// }, []);

	React.useEffect(() => {
		if (canvasRef.current) {
			if (canvasValue === 'draw') {
				const eraseMode = canvasRef.current.eraseMode;
				if (eraseMode) eraseMode(false);
			} else if (canvasValue === 'erase') {
				const eraseMode = canvasRef.current.eraseMode;
				if (eraseMode) eraseMode(true);
			} else if (canvasValue === 'undo') {
				const undo = canvasRef.current.undo;
				if (undo) undo();
			} else if (canvasValue === 'redo') {
				const redo = canvasRef.current.redo;
				if (redo) redo();
			} else if (canvasValue === 'clear') {
				const clear = canvasRef.current.clearCanvas;
				if (clear) clear();
			} else if (canvasValue === 'close') {
				if (handleClose) handleClose();
			}

			if (!isEmpty(canvasValue) && (canvasValue === 'clear' || canvasValue === 'undo' || canvasValue === 'redo')) {
				setCanvasValue('');
			}
			// else if (canvasValue === 'save') {
			//     const handleExportPath = async () => {
			//         const exportPaths = canvasRef.current.exportPaths;
			//         if (exportPaths) {
			//             const exportedPaths = await exportPaths();
			//             console.log(exportedPaths);
			//         }
			//     }
			//     handleExportPath();
			// }
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canvasValue]);

	return (
		<Box sx={{ position: 'relative', height: '100%', ...sx }}>
			<ToggleButtonGroup sx={{ marginTop: '10px', backgroundColor: '#FFF', position: 'fixed', right: '10px' }} size="small" value={canvasValue} exclusive onChange={(event, newValue) => setCanvasValue(newValue)}>
				<ToggleButton value="draw">
					<Tooltip title="Draw">
						<PenIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="erase">
					<Tooltip title="Eraser">
						<EraseIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="clear">
					<Tooltip title="Clear Canvas">
						<ClearIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="undo">
					<Tooltip title="Undo">
						<UndoIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="redo">
					<Tooltip title="Redo">
						<RedoIcon />
					</Tooltip>
				</ToggleButton>
				<ToggleButton value="close">
					<Tooltip title="Close Canvas">
						<CancelIcon />
					</Tooltip>
				</ToggleButton>
				{/* <ToggleButton value="save"><SaveIcon /></ToggleButton> */}
			</ToggleButtonGroup>
			<ReactSketchCanvas ref={canvasRef} style={{ border: 'none' }} canvasColor="rgba(255,255,255,.5)" width="100%" height="100%" strokeWidth={4} eraserWidth={20} strokeColor="black" />
		</Box>
	);
}
