/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
import { loadStripe } from '@stripe/stripe-js';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData, SubscriptionData } from '../../data';
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, Chip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage, LoadingPageMessage, NoDataMessage } from '../Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/

export default function SubscribeNow({ block_id }) {
	const [plans, setPlans] = React.useState(InitialData.loading);
	const [activePriceId, setActivePriceId] = React.useState('');
	const [checkingOut, setCheckingOut] = React.useState(InitialData.completed);

	React.useEffect(() => {
		getBlockPrices(block_id);
	}, [block_id]);

	const getBlockPrices = async (block_id) => {
		setPlans(InitialData.loading);
		const res = await SubscriptionData.getStripePrices(block_id);
		if (!res.success) return setPlans({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setPlans({
			...InitialData.completed,
			data: res.data
				.map((el) => ({
					price_id: el.id,
					product_id: el.product,
					dollar_amount: el.unit_amount / 100,
					currency: el.currency,
					numMonths: el.recurring.interval === 'year' ? 12 : el.recurring.interval_count,
				}))
				.sort((a, b) => a.numMonths - b.numMonths),
		});
	};

	// const handlePlan = async () => {
	// 	if (block.data.is_subscribed === true) {
	// 		setManageSubscription(InitialData.loading);
	// 		const res = await SubscriptionData.getStripeCustomerPortal(block.data.code);
	// 		if (!res.success) return setManageSubscription({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
	// 		setManageSubscription(InitialData.completed);
	// 		window.location.href = res.data.url;
	// 	} else {
	// 		handleTriggerSubscribePage(block.data.id);
	// 	}
	// };

	const handleCheckout = async () => {
		setCheckingOut(InitialData.loading);
		const res = await SubscriptionData.createStripeCheckout(block_id, activePriceId);
		if (!res.success) return setCheckingOut({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
		await stripe.redirectToCheckout({ sessionId: res.data.id });
		setCheckingOut({ ...InitialData.completed, data: res.data });
	};

	return (
		<Box sx={styles.subscribeNow}>
			<Typography align="center" className="heading-font" variant="h5" sx={{ marginBottom: plans.loading ? '0px' : '30px' }}>
				{plans.loading && 'To access all questions, you need to subscribe. Give us a moment to find you the the best price 💜'}
				{!plans.loading && 'Select a plan that works for you.'}
			</Typography>
			{plans.loading && <LoadingPageMessage />}
			{!plans.loading && plans.error && <ApiErrorMessage errorCode={plans.errorCode} errorMessage={plans.errorMessage} />}
			{!plans.loading && !plans.error && isEmpty(plans.data) && <NoDataMessage customMessage={'Sorry, there was an error returning the plans for this block. Please try again later or contact support@learnblock.com'} />}
			{!plans.loading && !plans.error && !isEmpty(plans.data) && (
				<Box className="plans">
					{plans.data.map((plan, planIndex) => {
						return (
							<Box key={`plan-${plan.price_id}`} className={clsx('box-shadow2', 'plan', { active: activePriceId === plan.price_id })} onClick={() => setActivePriceId(plan.price_id)}>
								{planIndex > 0 && <Chip className="save-chip text-upper-spaced" color="secondary" variant="filled" label={`Save $${plans.data[0].dollar_amount * plan.numMonths - plan.dollar_amount}!`} />}
								<Box className="plan-duration">
									<Typography className="heading-font" variant="h5">
										{plan.numMonths} Month Pass
									</Typography>
								</Box>
								<Box className="plan-price">
									<Typography className="heading-font" variant="h5">
										${plan.dollar_amount}
									</Typography>
								</Box>
							</Box>
						);
					})}
					<LoadingButton loading={checkingOut.loading} disabled={isEmpty(activePriceId)} fullWidth color="secondary" variant="contained" onClick={handleCheckout}>
						Checkout
					</LoadingButton>
				</Box>
			)}
		</Box>
	);
}
