/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, IconButton, Checkbox, Button, TextField } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import MarkdownRender from '../../MarkdownRender';
import CodeEditorBasic from '../../CodeEditorBasic';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
const MULTI_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const PART_NUMS = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xi', 'xii', 'xiii', 'xiv', 'xv'];
export default function QuestionOptionsAdmin({ mode, rerender, setRerender, question, setQuestion }) {
	const { question_type, question_options } = question;

	const handleDeleteMcOption = (multiIndex) => {
		setQuestion((prev) => ({ ...prev, question_options: prev.question_options.filter((el, index) => index !== multiIndex) }));
	};

	const handleSelectCorrectMcOption = (multiIndex) => {
		setQuestion((prev) => ({
			...prev,
			question_options: prev.question_options.map((el, index) => {
				if (index === multiIndex) {
					return { ...el, is_correct: true };
				} else {
					return { ...el, is_correct: null };
				}
			}),
		}));
	};

	const handleUpdateMcOption = (multiIndex, field, value) => {
		setQuestion((prev) => ({
			...prev,
			question_options: prev.question_options.map((el, elIndex) => {
				if (elIndex === multiIndex) {
					return { ...el, [field]: value };
				} else {
					return el;
				}
			}),
		}));
	};

	const moveOption = (direction, multiIndex) => {
		if (direction === 'down') {
			[question_options[multiIndex], question_options[multiIndex + 1]] = [question_options[multiIndex + 1], question_options[multiIndex]];
		} else if (direction === 'up') {
			[question_options[multiIndex - 1], question_options[multiIndex]] = [question_options[multiIndex], question_options[multiIndex - 1]];
		}
		setQuestion((prev) => ({
			...prev,
			question_options: question_options,
		}));
		setRerender(uuidv4());
	};

	const handleAddNewMultiChoiceOption = () => {
		const newMcOption = { question_text: '', solution_text: '', marks: null, is_correct: null };
		setQuestion((prev) => ({
			...prev,
			question_options: [...prev.question_options, newMcOption],
		}));
	};

	return (
		<Box sx={styles.mcOptionsAdmin}>
			<Box className="section-title text-upper-spaced">{question_type === 'mc' ? 'Multi Choice Options' : 'Parts'}</Box>
			{question_options.map((option, multiIndex) => {
				return (
					<Box className={clsx('mc-option-admin', { correctAnswer: option.is_correct === true })} key={`multi-choice-el-admin-${multiIndex}`}>
						<Box className="mc-option-admin__controls">
							<Box className="mc-option-admin__option-num">
								<Box className="mc-option-admin__option-num__number">{question_type === 'parts' ? PART_NUMS[multiIndex] : MULTI_LETTERS[multiIndex]}</Box>
								{question_type === 'mc' && <Checkbox className="mc-option-admin__option-num__checkbox" sx={{ marginRight: '10px' }} checked={option.is_correct === true} onChange={() => handleSelectCorrectMcOption(multiIndex)} />}
								{question_type === 'parts' && <TextField variant="outlined" type="number" className="mc-option-admin__option-marks" label="Marks" value={option.marks} onChange={(e) => handleUpdateMcOption(multiIndex, 'marks', !isEmpty(e.target.value) ? parseInt(e.target.value) : null)} />}
							</Box>
							<Box className="mc-option-admin__option-controls">
								{mode === 'add' && (
									<IconButton onClick={() => handleDeleteMcOption(multiIndex)}>
										<DeleteIcon />
									</IconButton>
								)}
								<IconButton disabled={multiIndex === 0} onClick={() => moveOption('up', multiIndex)}>
									<ArrowUpward />
								</IconButton>
								<IconButton disabled={multiIndex === question_options.length - 1} onClick={() => moveOption('down', multiIndex)}>
									<ArrowDownward />
								</IconButton>
							</Box>
						</Box>
						<Box className="mc-option-admin__question">
							<Box sx={styles.miniTitle}>
								{' '}
								{question_type === 'mc' ? 'Option' : 'Part'} {multiIndex + 1}
							</Box>
							<MarkdownRender children={option.question_text} />
							<CodeEditorBasic rerender={rerender} language="markdown" bucket="questions" initialCode={option.question_text} onChange={(value) => handleUpdateMcOption(multiIndex, 'question_text', value)} style={{ margin: '10px 0' }} sx={{ marginBottom: '10px' }} />
						</Box>
						<Box className="mc-option-admin__solution">
							<Box sx={styles.miniTitle}>
								{' '}
								{question_type === 'mc' ? 'Option' : 'Part'} {multiIndex + 1} - Solution
							</Box>
							<MarkdownRender children={option.solution_text} />
							<CodeEditorBasic rerender={rerender} language="markdown" bucket="questions" initialCode={option.solution_text} onChange={(value) => handleUpdateMcOption(multiIndex, 'solution_text', value)} style={{ margin: '10px 0' }} sx={{ marginBottom: '10px' }} />
						</Box>
					</Box>
				);
			})}
			{mode === 'add' && (
				<Button sx={{ margin: '30px 0px' }} variant="contained" startIcon={<AddIcon />} onClick={handleAddNewMultiChoiceOption}>
					Add {question_type === 'mc' ? 'MC Option' : 'Part'}
				</Button>
			)}
		</Box>
	);
}
