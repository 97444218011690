/**************************************************************************************************** 
 * CORE LIBRARIES
****************************************************************************************************/
import React from 'react';
import PropTypes from 'prop-types';
/**************************************************************************************************** 
 * COMPONENTS
****************************************************************************************************/
import { Snackbar, Alert as MuiAlert } from '@mui/material';
/**************************************************************************************************** 
 * STYLES
****************************************************************************************************/
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
/**************************************************************************************************** 
 * PROPS
****************************************************************************************************/
SnackbarAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
}
SnackbarAlert.defaultProps = {
    severity: 'error',
    vertical: 'top',
    horizontal: 'center',
}
/**************************************************************************************************** 
 * MODULE
****************************************************************************************************/
export default function SnackbarAlert({ open, message, handleClose, severity, vertical, horizontal }) {
    return (
        <Snackbar autoHideDuration={6000} anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}