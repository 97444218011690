const styles = {
	questionsList: {
		zIndex: 999,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0px',
		maxWidth: '100%',
		width: '100%',
		backgroundColor: 'primary.main',
		'& .navigation-container': {
			flexShrink: 0,
			height: '56px',
			display: 'flex',
			alignItems: 'center',
			marginX: '10px',
			marginLeft: '15px',
			'& .navigate-btn': {
				height: '36px',
				paddingX: '18px',
			},
			'& .navigate-btn.Mui-disabled': {
				backgroundColor: 'rgba(255,255,255,.15)',
				color: 'rgba(255,255,255,.3)',
			},
			'& .navigate-btn:not(.Mui-disabled)': {
				backgroundColor: 'secondary.main',
				color: '#FFF',
				flexShrink: 0,
			},
		},
		'& .questions-list': {
			display: 'flex',
			alignItems: 'center',
			overflow: 'auto',
			padding: '15px 20px',
			position: 'sticky',
			top: '0px',
			zIndex: 999,
			width: '100%',
			height: '56px',
			'& .question-btn': {
				flexShrink: 0,
				backgroundColor: 'rgba(255,255,255,.3)',
				width: '36px',
				height: '36px',
				fontSize: '0.8rem',
				marginRight: '5px',
				fontWeight: '600',
				color: '#FFF',
				fontFamily: "'Poppins',sans-serif",
				'&.checkedAnswer': { backgroundColor: 'rgba(255,255,255,.7)' },
				'&.correct': { backgroundColor: 'success.main' },
				'&.incorrect': { backgroundColor: 'error.main' },
				'&.flagged': { backgroundColor: 'warning.main' },
				'&.active': {
					border: `4px solid rgba(255,255,255,1)`,
					color: '#FFF',
				},
				'&:hover': { backgroundColor: '#FFF', color: '#000' },
				'&.hidden': { display: 'none' },
			},
		},
	},
};

export default styles;
