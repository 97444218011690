import { supabase } from '../supabaseClient';

const InstitutionsData = {
	getInstitutions: async (blockId) => {
		try {
			if (blockId === 1) {
				// NSW Math Advanced
				const { data, error } = await supabase.from('institutions').select('*').eq('state', 'NSW').in('category', ['secondary', 'combined']);
				if (error) throw error;
				return { success: true, data };
			} else if (blockId === 2) {
				const { data, error } = await supabase.from('institutions').select('*').eq('category', 'tertiary');
				if (error) throw error;
				return { success: true, data };
			}
		} catch (err) {
			return {
				success: false,
				code: 'Get Institutions',
				message: err.message,
			};
		}
	},
	addInstitution: async (formData) => {
		try {
			const { data, error } = await supabase.from('institutions').insert(formData).select().single();
			if (error) throw error;
			return { success: true, data };
		} catch (err) {
			return {
				success: false,
				code: 'Add Institution',
				message: err.message,
			};
		}
	},
};

export default InstitutionsData;
