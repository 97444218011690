/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { useAuth } from '../../auth';
import { isEmpty } from '../../utils';
import { BlockData, InitialData } from '../../data';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { Box, AppBar, Toolbar, Button } from '@mui/material';
import { CreditCard as SubscribeIcon, ChevronLeft as BackIcon } from '@mui/icons-material';
import { LogoIcon } from '../../@core/Logo';
import { LoaderBackdrop } from '../../@core/Loader';
import { LoginRegisterBtns } from '../../auth/elements';
import { BlockSelector } from '../../components/Blocks';
import styles from './styles';
/****************************************************************************************************
 * PAGES
 ****************************************************************************************************/
import ErrorPage from '../ErrorPage';
import MapRenderer from '../../components/MapRenderer';
import { SubscriptionOptions } from '../../components/Subscriptions';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export const BlockContext = React.createContext();
export default function Block() {
	const history = useHistory();
	const { appUser } = useAuth();
	const { code, view } = useParams();
	const [block, setBlock] = React.useState(InitialData.loading);

	React.useEffect(() => {
		const getBlock = async () => {
			setBlock(InitialData.loading);
			const res = await BlockData.getBlock(code);
			if (!res.success)
				return setBlock({
					...InitialData.error,
					errorCode: res.code,
					errorMessage: res.message,
				});
			setBlock({ ...InitialData.completed, data: res.data });
		};
		getBlock(code);
		return () => {
			setBlock(InitialData.completed);
		};
	}, [code]);

	const navigateToSubscriptionPage = () => {
		if (view === 'subscription') {
			history.push(`/block/${code}/questions`);
		} else {
			history.push(`/block/${code}/subscription`);
		}
	};

	if (block.loading)
		return (
			<Box sx={styles.blockPage}>
				<LoaderBackdrop />
			</Box>
		);

	if (!block.loading && block.error)
		return (
			<Box sx={styles.blockPage}>
				<ErrorPage title={'Sorry, this block is not available.'} subheading="The block is either inactive or does not exist." />
			</Box>
		);

	return (
		<BlockContext.Provider value={{ block: block.data }}>
			<Box sx={styles.blockPage}>
				<AppBar className="block-header" position="static">
					<Toolbar className="block-toolbar">
						<Box className="block-icon-container">
							<LogoIcon size={40} white={true} />
						</Box>
						{!isEmpty(appUser) && (
							<Button startIcon={view === 'subscription' ? <BackIcon /> : block.data.is_subscribed === false ? <SubscribeIcon /> : null} className="plan-btn" color="secondary" variant="contained" onClick={() => navigateToSubscriptionPage()}>
								{view === 'subscription' ? 'Course' : block.data.is_subscribed === true ? 'Manage Plan' : 'Subscribe'}
							</Button>
						)}
						<Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', overflow: 'auto' }}>
							<BlockSelector className="block-selector" defaultValue={block.data.code} />
						</Box>
						<Box sx={{ marginLeft: '5px', flexGrow: 0 }}>
							<LoginRegisterBtns sx={{ marginRight: !isEmpty(appUser) ? '8px' : '0px', '& .toggle-btn': { borderRadius: '0px !important' } }} />
						</Box>
					</Toolbar>
				</AppBar>
				{(view === 'questions' || view === 'tutors') && <MapRenderer block={block.data} module="questions" />}
				{view === 'subscription' && <SubscriptionOptions block={block.data} />}
			</Box>
		</BlockContext.Provider>
	);
}
