/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import validator from 'validator';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { InitialData } from '../../data';
import { isEmpty, useKey } from '../../utils';
import { updateUserPassword } from '../AuthData';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { TextField, Box, Typography, Button } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { ApiErrorMessage } from '../../components/Messages';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
export default function UpdatePassForm() {
	const history = useHistory();
	const [state, setState] = React.useState({
		password: { value: '', error: false, message: '' },
		confirmPass: { value: '', error: false, message: '' },
	});
	const [formState, setFormState] = React.useState(InitialData.completed);
	useKey('Enter', () => handleSubmit());

	const handlePassword = (e) => {
		e.persist();
		const password = e.target.value;
		const data = { value: password, error: false, message: '' };
		if (isEmpty(password)) {
			data.error = true;
			data.message = 'Password field cannot be empty.';
		}
		if (!validator.isStrongPassword(password)) {
			data.error = true;
			data.message = 'Your password should contain at least 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 symbol.';
		}
		setState((prev) => ({ ...prev, password: data }));
	};

	const handleConfirmPassword = (e) => {
		e.persist();
		const password = e.target.value;
		const data = { value: password, error: false, message: '' };
		if (isEmpty(password)) {
			data.error = true;
			data.message = 'Confirm Password field cannot be empty.';
		}
		if (password !== state.password.value) {
			data.error = true;
			data.message = 'The password you have entered does not match.';
		}
		setState((prev) => ({ ...prev, confirmPass: data }));
	};

	const handleKeyDown = (event) => {
		if (event.keyCode === 13) handleSubmit();
	};

	const handleSubmit = async () => {
		if (checkErrors()) return;
		setFormState(InitialData.loading);
		const res = await updateUserPassword(state.password.value);
		if (!res.success) return setFormState({ ...InitialData.error, errorCode: res.code, errorMessage: res.message });
		setFormState({ ...InitialData.completed, data: res.data });
	};

	const checkErrors = () => {
		if (isEmpty(state.password.value) || isEmpty(state.confirmPass.value) || state.password.error === true || state.confirmPass.error === true) {
			return true;
		}
		return false;
	};

	return (
		<Box id="login-form" sx={styles.authForm}>
			{isEmpty(formState.data) && (
				<React.Fragment>
					<TextField fullWidth label="New Password" type="password" className="text-field-light" variant="filled" color="primary" error={state.password.error} helperText={state.password.message} value={state.password.value} onChange={handlePassword} />
					<TextField fullWidth label="Confirm New Password" type="password" className="text-field-light" variant="filled" color="primary" error={state.confirmPass.error} helperText={state.confirmPass.message} value={state.confirmPass.value} onChange={handleConfirmPassword} />
					{formState.error && <ApiErrorMessage className="error-message" errorCode={formState.errorCode} errorMessage={formState.errorMessage} />}
					<LoadingButton fullWidth className="btn-light" color="secondary" disabled={checkErrors()} loading={formState.loading} variant="contained" onClick={handleSubmit} onKeyDown={handleKeyDown}>
						{formState.loading === true ? 'Updating Password ...' : 'Update Password'}
					</LoadingButton>
				</React.Fragment>
			)}
			{formState.data === true && (
				<React.Fragment>
					<Typography color="secondary" className="heading-font instruction-title" variant="h6" sx={{ marginBottom: '30px' }}>
						Yay! Your new password has been set 💜
					</Typography>
					<Button fullWidth variant="contained" color="secondary" startIcon={<HomeIcon />} onClick={() => history.push('/')}>
						Go Home
					</Button>
				</React.Fragment>
			)}
		</Box>
	);
}
