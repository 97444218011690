/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import clsx from 'clsx';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../../utils';
import { useDialog } from '../../../contexts';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { AppBar, Toolbar, Chip, Box, IconButton, ButtonGroup, Button } from '@mui/material';
import { Tune as FilterIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, UnfoldLess as CloseFilterIcon, ArrowUpward, ArrowDownward, HorizontalRule, Done as TickIcon, Close as CrossIcon, Flag as FlagIcon, CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { SubscribeNow } from '../../Subscriptions';
import styles from './styles';
/****************************************************************************************************
 * MODULE <FilterIcon color="secondary" sx={{ marginRight: '10px', fontSize: '20px' }} />
 ****************************************************************************************************/
QuestionsList.defaultProps = {
	sx: {},
};
export default function QuestionsList({ blockId, filters, handleChangeFilter, questions, totalQuestionCount, activeQuestionIndex, setActiveQuestionIndex, handlePrevQuestion, handleNextQuestion, sx }) {
	const [showFilters, setShowFilters] = React.useState(false);
	const { openDialog } = useDialog();

	const handleTriggerSubscribePage = () => {
		openDialog(<SubscribeNow block_id={blockId} />);
	};

	const returnFakeQuestions = (returnCount, totalCount) => {
		const fakeBtns = [];
		for (let i = returnCount; i < totalCount; i++) {
			fakeBtns.push(
				<IconButton
					key={`question-fake-${i}`}
					sx={{
						backgroundColor: 'rgba(255,255,255,.15) !important',
						color: 'rgba(255,255,255,.3) !important',
					}}
					className={clsx('question-btn', 'box-shadow2', {
						active: i === activeQuestionIndex,
						hidden: showFilters === true,
					})}
					onClick={handleTriggerSubscribePage}
				>
					{i}
				</IconButton>
			);
		}
		return fakeBtns;
	};

	return (
		<Box>
			<Box className="box-shadow2" sx={{ ...styles.questionsList, ...sx }}>
				{handleChangeFilter && (
					<Box sx={{ backgroundColor: 'secondary.main', height: '56px', width: '56px', borderRadius: '0px 56px 56px 0px', paddingRight: '5px', flexShrink: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', '&:hover': { filter: 'brightness(130%)' } }}>
						<IconButton sx={{ width: '100%', height: '100%', borderRadius: '0px 56px 56px 0px' }} color="white" onClick={() => setShowFilters((prev) => !prev)}>
							{showFilters ? <CloseFilterIcon /> : <FilterIcon />}
						</IconButton>
					</Box>
				)}
				<Box className="questions-list">
					{questions.map((question, questionIndex) => {
						return (
							<IconButton
								key={`question-${question.id}`}
								className={clsx('question-btn', 'box-shadow2', {
									active: questionIndex === activeQuestionIndex,
									correct: !isEmpty(question.question_performance) && question.question_performance.correct === true,
									incorrect: !isEmpty(question.question_performance) && question.question_performance.correct === false,
									flagged: !isEmpty(question.question_performance) && question.question_performance.flagged === true,
									checkedAnswer: !isEmpty(question.question_performance) && question.question_performance.checked === true,
									hidden: question.hidden === true,
								})}
								onClick={() => setActiveQuestionIndex(questionIndex)}
							>
								{questionIndex + 1}
							</IconButton>
						);
					})}
					{totalQuestionCount && questions.length < totalQuestionCount && returnFakeQuestions(questions.length + 1, totalQuestionCount + 1)}
				</Box>
				<Box className="navigation-container">
					<ButtonGroup variant="contained" color="secondary">
						<Button className="navigate-btn" disabled={isEmpty(questions) || isEmpty(activeQuestionIndex) || activeQuestionIndex === 0} onClick={handlePrevQuestion}>
							<ChevronLeftIcon />
						</Button>
						<Button className="navigate-btn" disabled={isEmpty(questions) || isEmpty(activeQuestionIndex) || activeQuestionIndex === questions.length - 1} onClick={handleNextQuestion}>
							<ChevronRightIcon />
						</Button>
					</ButtonGroup>
				</Box>
			</Box>
			{showFilters && handleChangeFilter && (
				<AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0, paddingBottom: '10px' }}>
					<Toolbar
						sx={{
							minHeight: '56px !important',
							paddingLeft: '15px !important',
							paddingRight: '0px !important',
						}}
					>
						<Box id="browse-filters" sx={{ overflow: 'auto', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
							<span className="text-upper-spaced" style={{ marginRight: '10px' }}>
								Likelihood
							</span>
							<Chip className="text-upper-spaced" label={<ArrowUpward />} color="secondary" icon={filters.includes('very-likely') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('very-likely') ? 'filled' : 'outlined'} sx={{ borderRadius: '16px 0px 0px 16px', margin: 0 }} onClick={() => handleChangeFilter('very-likely')} />
							<Chip className="text-upper-spaced" label={<HorizontalRule />} color="secondary" icon={filters.includes('fairly-likely') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('fairly-likely') ? 'filled' : 'outlined'} sx={{ borderRadius: '0px', margin: 0, borderLeft: 'none', borderRight: 'none' }} onClick={() => handleChangeFilter('fairly-likely')} />
							<Chip className="text-upper-spaced" label={<ArrowDownward />} color="secondary" icon={filters.includes('unlikely') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('unlikely') ? 'filled' : 'outlined'} sx={{ borderRadius: '0px 16px 16px 0px', margin: 0 }} onClick={() => handleChangeFilter('unlikely')} />
							<span className="text-upper-spaced" style={{ margin: '0px 10px' }}>
								Difficulty
							</span>
							<Chip className="text-upper-spaced" label="Easy" color="secondary" icon={filters.includes('easy') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('easy') ? 'filled' : 'outlined'} sx={{ borderRadius: '16px 0px 0px 16px', margin: 0 }} onClick={() => handleChangeFilter('easy')} />
							<Chip className="text-upper-spaced" label="Med" color="secondary" icon={filters.includes('medium') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('medium') ? 'filled' : 'outlined'} sx={{ borderRadius: '0px', margin: 0, borderLeft: 'none', borderRight: 'none' }} onClick={() => handleChangeFilter('medium')} />
							<Chip className="text-upper-spaced" label="Hard" color="secondary" icon={filters.includes('hard') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('hard') ? 'filled' : 'outlined'} sx={{ borderRadius: '0px 16px 16px 0px', margin: 0 }} onClick={() => handleChangeFilter('hard')} />
							<span className="text-upper-spaced" style={{ margin: '0px 10px' }}>
								Status
							</span>
							<Chip className="text-upper-spaced" label={<TickIcon />} color="secondary" icon={filters.includes('correct') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('correct') ? 'filled' : 'outlined'} sx={{ '& .MuiChip-label': { lineHeight: 1 }, borderRadius: '16px 0px 0px 16px', margin: 0 }} onClick={() => handleChangeFilter('correct')} />
							<Chip className="text-upper-spaced" label={<CrossIcon />} color="secondary" icon={filters.includes('incorrect') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('incorrect') ? 'filled' : 'outlined'} sx={{ '& .MuiChip-label': { lineHeight: 1 }, borderRadius: '0px', margin: 0, borderLeft: 'none', borderRight: 'none' }} onClick={() => handleChangeFilter('incorrect')} />
							<Chip className="text-upper-spaced" label={<FlagIcon />} color="secondary" icon={filters.includes('flagged') ? <CheckBox /> : <CheckBoxOutlineBlank />} variant={filters.includes('flagged') ? 'filled' : 'outlined'} sx={{ '& .MuiChip-label': { lineHeight: 1 }, borderRadius: '0px 16px 16px 0px', margin: 0 }} onClick={() => handleChangeFilter('flagged')} />
						</Box>
					</Toolbar>
				</AppBar>
			)}
		</Box>
	);
}
