/****************************************************************************************************
 * CORE LIBRARIES
 ****************************************************************************************************/
import React from 'react';
import { useHistory } from 'react-router-dom';
/****************************************************************************************************
 * UTILITIES
 ****************************************************************************************************/
import { isEmpty } from '../../utils';
/****************************************************************************************************
 * COMPONENTS
 ****************************************************************************************************/
import { FormControl, Select, MenuItem, ListSubheader } from '@mui/material';
import styles from './styles';
/****************************************************************************************************
 * MODULE
 ****************************************************************************************************/
BlockSelector.defaultProps = {
	sx: {},
	defaultValue: '',
};
export default function BlockSelector({ defaultValue, sx, className }) {
	const history = useHistory();

	const handleChange = (event) => {
		const value = event.target.value;
		history.push(isEmpty(value) ? '/' : `/block/${value}/questions`);
	};
	return (
		<FormControl className={className} sx={{ ...styles.blockSelector, ...sx }}>
			<Select displayEmpty className="main-selector" id="grouped-select" defaultValue={defaultValue} onChange={handleChange}>
				<MenuItem value="">
					<em>Select Your Exam</em>
				</MenuItem>
				<ListSubheader sx={{ textTransform: 'uppercase', letterSpacing: '1px', color: 'primary.main' }}>High School Exams</ListSubheader>
				<MenuItem value="au-nsw-12-math-adv">NSW HSC Y12 Mathematics Advanced</MenuItem>
				<MenuItem disabled={true} value="au-nsw-12-math-ext1">
					* NSW HSC Y12 Mathematics Extension 1
				</MenuItem>
				<MenuItem disabled={true} value="au-nsw-12-math-ext2">
					* NSW HSC Y12 Mathematics Extension 2
				</MenuItem>
				<ListSubheader sx={{ textTransform: 'uppercase', letterSpacing: '1px', color: 'primary.main' }}>University Exams</ListSubheader>
				<MenuItem value="au-medicine">Australian Medical School Question Bank</MenuItem>
				<ListSubheader sx={{ textTransform: 'uppercase', letterSpacing: '1px', color: 'primary.main' }}>* Entrance Exams (Coming Soon)</ListSubheader>
				<MenuItem disabled={true} value="ucat">
					* Undergraduate Medicine - UCAT
				</MenuItem>
				<MenuItem disabled={true} value="lsat">
					* Undergraduate Law - LSAT
				</MenuItem>
				<MenuItem disabled={true} value="gamsat">
					* Graduate Medicine - GAMSAT
				</MenuItem>
			</Select>
		</FormControl>
	);
}
