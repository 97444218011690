import { styled } from '@mui/material/styles';

const MainBanner = styled("div", { shouldForwardProp: (prop) => !['image', 'overlay', 'noBackground'].includes(prop) })(({ theme, image, overlay, noBackground }) => ({
    minHeight: '100vh', padding: '0 40px', position: 'relative', backgroundImage: image ? `url(${image})` : 'none', backgroundAttachment: 'fixed', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
    [theme.breakpoints.down('sm')]: { padding: '0 20px', },
    '&:before': { position: 'absolute', top: 0, left: 0, content: "''", width: '100%', height: '100%', background: overlay ? overlay : noBackground ? 'none' : theme.palette.gradient.light, opacity: noBackground ? "1" : "0.85", },
    '& .content': {
        zIndex: 99, textAlign: "center", color: "#FFF", width: '100%',
        [theme.breakpoints.down('sm')]: { paddingTop: 50 },
        '& .logo-text': { fontFamily: "'Fredoka One', sans-serif", fontSize: '80px', },
        '& .main-title': { fontFamily: "'Poppins', sans-serif", },
        '& .sub-title': { maxWidth: 600, margin: '20px auto', },
    }
}));

export default MainBanner;